import React, { Component } from 'react';
import { Pivot, PivotItem, TextField, Dropdown, DefaultButton, PrimaryButton, Checkbox, DatePicker} from '@fluentui/react';
import validator from 'validator';

import Helper from '../../../core/Helper';
import styles from '../css/User.module.css';
import Config from '../../../core/Config';
import { AppState, UserType } from '../../../App';

type UserProps = Readonly <{
    config: typeof Config,
    items: AppState['users'],
    user: UserType,
    onSave: (user: UserType) => void,
    router: {
        location: any,
        navigate: any,
        params: any
    }
}>
interface UserState {
    
    passwordCheck: string,
    new: boolean,
    user: UserType
}
class User extends Component<UserProps, UserState> {

    state: UserState;
    props: UserProps;

    constructor(props: UserProps){
        super(props);
        this.props = props;

        const consents: any = {};
        this.props.config.consentBoxes.forEach((consent: any) => {
            consents[consent.key] = consent.defaultValue;
        });

        this.state = {
            new: true,
            user: {
                title: '',
                firstname: '',
                lastname: '',
                email: '',
                password: '',
                usergroup: 'users',
                regularCustomer: false,
                location: 0,
                phone: '',
                mobile: '',
                birthday: '',
                description: '',
                street: '',
                addition: '',
                zip: '',
                city: '',
                country: '',
                healthInsurance: '',
                healthInsuranceID: '',
                careLevel: '',
                consents: consents,
                consentsChanged: false,
                requirements: [
                    {
                        age: '',
                        productCategories: [],
                        bereich: [],
                        beruf: '',
                        berufSonstiges: '',
                        freizeit: [],
                        freizeitSonstiges: '',
                        schuhtypen: [],
                        schuhtypSonstiges: '',
                        bootCount: '',
                        compressionStocking: '',
                        compressionStockingDressUp: '',
                        compressionStockingSatisfaction: '',
                        compressionStockingProblems: [],
                        custom: {},
                        status: ''
                    },
                ],
            },
            passwordCheck: '',
        };
    }

    componentDidMount(){
        this._selectUser();
    }

    componentDidUpdate(prevProps: any) {
        let {id} = this.props.router.params;
        if(id !== prevProps.router.params.id !== prevProps.match.params.id || this.props.items !== prevProps.items){
            this._selectUser();
        }
    }

    render() {
        const config = this.props.config;
        const admin = ['system', 'admin', 'employee'].indexOf(this.props.user.usergroup) !== -1;
        const {title, firstname, lastname, usergroup, regularCustomer, location, email, password, phone, mobile, birthday, description, street, addition, zip, city, country, healthInsurance, healthInsuranceID, careLevel, consents, requirements} = this.state.user;
        const {created} = requirements[requirements.length-1];
        let invalidInput = title === '' || firstname === '' || lastname === '' || (email !== '' && (!validator.isEmail(email) || password !== this.state.passwordCheck)) || (phone === '' && mobile === '') || birthday === '';

        if(this.state.new === true){
            invalidInput = invalidInput || (email !== '' && password.length < 8);
        } else {
            invalidInput = invalidInput || (password !== '' && password.length < 8);
        }

        return(
            <form className={styles.root}>
                <h1>{admin ? 'Stammdaten' : 'Meine Daten bearbeiten'}</h1>
                <Pivot>
                    <PivotItem headerText="Kontaktdaten">
                        <Dropdown
                            label="Anrede"
                            options={config.titleOptions}
                            selectedKey={title}
                            onChange={(e, item) => this.handleDropdownChange(e, item, 'title')}
                            className={styles.field}
                        />
                        <TextField
                            name="firstname"
                            label="Vorname"
                            value={firstname}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            onGetErrorMessage={string => string !== '' ? '' : 'Diese Angabe ist verpflichtend.'}
                            validateOnFocusOut={true}
                            validateOnLoad={false}
                            className={styles.field}
                        />
                        <TextField
                            name="lastname"
                            label="Nachname"
                            value={lastname}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            onGetErrorMessage={string => string !== '' ? '' : 'Diese Angabe ist verpflichtend.'}
                            validateOnFocusOut={true}
                            validateOnLoad={false}
                            className={styles.field}
                        />
                        <Dropdown
                            placeholder="Benutzergruppe wählen"
                            label="Benutzergruppe"
                            options={config.usergroupOptions}
                            selectedKey={usergroup}
                            onChange={(e, item) => this.handleDropdownChange(e, item, 'usergroup')}
                            className={styles.field}
                            disabled={!admin}
                        />
                        <Checkbox
                            label="Stammkunde" 
                            checked={regularCustomer} 
                            onChange={(e) => this.handleCheckboxChange('regularCustomer')} 
                            className={styles.field}
                            disabled={!admin}
                        />
                        <Dropdown
                            placeholder="Filiale wählen"
                            label="Filiale"
                            options={config.locationOptions}
                            selectedKey={location}
                            onChange={(e, item) => this.handleDropdownChange(e, item, 'location')}
                            className={styles.field}
                            disabled={!admin}
                        />
                        <TextField
                            name="email"
                            label="E-Mail (optional)"
                            value={email}
                            type="email"
                            onChange={(e) => this.handleTextFieldChange(e)}
                            onGetErrorMessage={string => string === '' || validator.isEmail(string) ? '' : 'E-Mail-Adresse ist nicht gültig.'}
                            validateOnFocusOut={true}
                            validateOnLoad={false}
                            className={styles.field}
                        />
                        <TextField
                            name="mobile" 
                            label="Handy" 
                            value={mobile} 
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <TextField
                            name="phone" 
                            label="Telefon" 
                            value={phone} 
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <DatePicker
                            className={styles.field}
                            label="Geburtsdatum"
                            allowTextInput={true}
                            firstDayOfWeek={1}
                            strings={Helper.datePickerStrings}
                            onSelectDate={(date) => this.handleDateChange('birthday', date)}
                            formatDate={(date) => date!.toLocaleDateString()}
                            parseDateFromString={(string) => Helper.parseDateFromString(string)}
                            value={birthday !== '' ? new Date(birthday) : undefined}
                        />
                        <TextField
                            name="description"
                            label="Anmerkungen"
                            value={description}
                            multiline={true}
                            placeholder="z. B. bevorzugte Kontaktzeiten"
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />

                        {config.fields.address !== 'hidden' &&
                        <>
                            <h2>Anschrift</h2>
                            <TextField
                                name="street"
                                label="Straße"
                                value={street}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                            <TextField
                                name="addition"
                                label="Adresszusatz"
                                value={addition}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                            <div className={styles.fieldGroup}>
                                <TextField
                                    name="zip"
                                    label="PLZ"
                                    value={zip}
                                    onChange={(e) => this.handleTextFieldChange(e)}
                                    className={styles.field}
                                />
                                <TextField
                                    name="city"
                                    label="Ort"
                                    value={city}
                                    onChange={(e) => this.handleTextFieldChange(e)}
                                    className={styles.field}
                                />
                            </div>
                            <TextField
                                name="country"
                                label="Land"
                                value={country}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                        </>
                        }

                        {config.fields.health !== 'hidden' &&
                        <>
                            <h2>Krankenkasse</h2>
                            <TextField
                                name="healthInsurance"
                                label="Name der Krankenkasse"
                                value={healthInsurance}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                            <TextField
                                name="healthInsuranceID"
                                label="Versichertennummer"
                                value={healthInsuranceID}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                            <TextField
                                name="careLevel"
                                label="Pflegegrad"
                                value={careLevel}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                        </>
                        }

                        {email !== '' &&
                        <>
                            <h2>Passwort {this.state.new === true ? 'vergeben' : 'ändern'}</h2>
                            <TextField
                                name="password"
                                label="Passwort"
                                value={password}
                                type="password"
                                onChange={(e) => this.handleTextFieldChange(e)}
                                errorMessage={(this.state.new === false && (password === '' || password.length >= 8)) || (this.state.new === true && password.length >= 8) ? '' : 'Passwort muss mindestens 8 Zeichen lang sein.'}
                                className={styles.field}
                                autoComplete="off"
                            />
                            {password !== '' &&
                            <TextField
                                name="passwordCheck"
                                label="Passwort (wiederholen)"
                                value={this.state.passwordCheck}
                                type="password"
                                onChange={(e) => this.setState({passwordCheck: (e.target as HTMLInputElement).value})}
                                errorMessage={this.state.passwordCheck === password ? '' : 'Passwörter stimmen nicht überein.'}
                                className={styles.field}
                                autoComplete="off"
                            />
                            }
                        </>
                        }

                        {admin && config.consentBoxes.length > 0 &&
                        <div className={styles.group}>
                            <h2>Einverständniserklärung</h2>
                            {config.consentDescription !== '' && <p dangerouslySetInnerHTML={{__html: config.consentDescription}}></p> }

                            {config.consentBoxes.map((consent: any) => <Checkbox key={consent.key} label={consent.text} checked={consents[consent.key]} onChange={() => this.handleConsentChange(consent.key)} disabled={true} /> )}
                        </div>
                        }

                        <div className={styles.footer}>
                            <PrimaryButton text={this.state.new === true ? 'Registrierung abschließen' : 'Speichern'} onClick={() => this.handleSaveClick()} disabled={invalidInput} />
                            <DefaultButton text="Vorgang abbrechen" onClick={() => this.props.router.navigate(-1)} />
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Anforderungsprofil">

                        <div className={styles.footer}>
                            <PrimaryButton text={this.state.new === true ? 'Registrierung abschließen' : 'Speichern'} onClick={() => this.handleSaveClick()} disabled={password !== this.state.passwordCheck || invalidInput} />
                            <DefaultButton text="Vorgang abbrechen" onClick={() => this.props.router.navigate(-1)} />
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Zusammenfassung">
                        <h3>Ihre Daten</h3>
                        <table className={styles.zfg}>
                            <tbody>
                                <tr><td>Name</td><td>{title} {firstname} {lastname}</td></tr>
                                <tr><td>Geburtsdatum</td><td>{birthday}</td></tr>
                                <tr><td>E-Mail</td><td>{email}</td></tr>
                                <tr><td>Handy</td><td>{mobile}</td></tr>
                                <tr><td>Telefon</td><td>{phone}</td></tr>

                                {config.fields.address !== 'hidden' &&
                                <tr><td>Adresse</td><td>{street}, {addition}, {zip} {city}</td></tr>
                                }

                                {config.fields.health !== 'hidden' &&
                                <>
                                    <tr><td>Krankenkasse</td><td>{healthInsurance}</td></tr>
                                    <tr><td>Versichertennummer</td><td>{healthInsuranceID}</td></tr>
                                    <tr><td>Pflegegrad</td><td>{careLevel}</td></tr>
                                </>
                                }
                            </tbody>
                        </table>

                        <h3>Anmerkungen</h3>
                        <p>{description !== '' ? description : '---'}</p>

                        <p className={styles.notice}><em>Anforderungsprofil vom {new Date(created!).toLocaleDateString()}</em></p>

                        <div className={styles.footer}>
                            <PrimaryButton text="Seite drucken" onClick={() => window.print()} />
                            <DefaultButton text="Vorgang abbrechen" onClick={() => this.props.router.navigate(-1)} />
                        </div>
                    </PivotItem>
                </Pivot>
            </form>
        )
    }

    _selectUser(){
        const {id} = this.props.router.params;
        const users = this.props.items;

        if(typeof id !== 'undefined'){
            let selectedUser = users.filter(user => user.id === parseInt(id));
            if(selectedUser.length > 0){
                selectedUser[0].password = '';
                if(selectedUser[0].requirements.length === 0){
                    selectedUser[0].requirements = this.state.user.requirements;
                }

                this.setState({
                    new: false,
                    user: selectedUser[0]
                });
            }
        }
    }

    handleTextFieldChange(e: any){
        const copy: any = {...this.state.user};
        copy[e.target.name] = e.target.value;
        this.setState({ user: copy });
    }

    handleDropdownChange(e: any, item: any, name: string){
        const copy: any = {...this.state.user};
        copy[name] = item.key;
        this.setState({ user: copy });
    }

    handleCheckboxChange(name: string){
        const copy: any = {...this.state.user};
        copy[name] = !copy[name];
        this.setState({ user: copy });
    }

    handleDateChange(target: string, value: any){
        const copy: any = {...this.state.user};
        copy[target] = value !== null ? Helper.parseSQLStringFromDate(value) : '';
        this.setState({ user: copy });
    }

    handleConsentChange(name: string){
        const copy = {...this.state.user};
        if(copy.consents[name]){
            copy.consents[name] = !copy.consents[name];
        } else {
            copy.consents[name] = true;
        }
        copy.consentsChanged = true;
        this.setState({ user: copy });
    }

    handleRequirementChange(index: number, target: string, value: any){
        const copy: any = {...this.state.user};

        if(Array.isArray(copy.requirements[index][target])){
            const item = copy.requirements[index][target].indexOf(value);
            if( item >= 0){
                copy.requirements[index][target].splice(item, 1);
            } else {
                copy.requirements[index][target].push(value);
            }
        } else {
            copy.requirements[index][target] = value;
        }

        copy.requirements[index].status = 'changed';
        this.setState({ user: copy });
    }

    handleSaveClick(){
        this.props.onSave(this.state.user);
        this.props.router.navigate(-1);
    }
}

export default User;