const Config = {
    apiURL: '/api',
    refreshInterval:30000,
    tinyMCE: {
        plugins: 'autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount',
        toolbar: 'undo redo | formatselect | bold italic | bullist numlist outdent indent | removeformat | help code',
        path: process.env.PUBLIC_URL + '/tinymce/tinymce.min.js',
    },
    company: '',
    fluentTheme: {},
    navLinks: [
        {
            links: [
                {
                    name: 'Startseite',
                    url: '/',
                    icon: 'Home',
                    key: '/'
                },
                {
                    name: 'Benutzer',
                    url: '/users',
                    icon: 'People',
                    key: '/users'
                },
                {
                    name: 'Rezepte',
                    url: '/recipes',
                    icon: 'Work',
                    key: '/recipes'
                },
                {
                    name: 'Befragungen',
                    url: '/surveys',
                    icon: 'AccountActivity',
                    key: '/surveys'
                },
                {
                    name: 'Tickets',
                    url: '/tickets',
                    icon: 'Ticket',
                    key: '/tickets'
                },
                {
                    name: 'Statistik',
                    url: '/statistics',
                    icon: 'StackedLineChart',
                    key: '/statistics'
                },
                {
                    name: 'Einstellungen',
                    url: '/settings',
                    icon: 'Settings',
                    key: '/settings'
                }
            ]
        }
    ],
    showFAQ: false,
    titleOptions: [
        {
            key: '',
            text: ' '
        },
        {
            key: 'Frau',
            text: 'Frau'
        },
        {
            key: 'Herr',
            text: 'Herr'
        },
    ],
    usergroupOptions: [
        {
            key: 'admin',
            text: 'Administrator'
        },
        {
            key: 'employee',
            text: 'Mitarbeiter'
        },
        {
            key: 'users',
            text: 'Kunden'
        }
    ],
    fields: {
        address: 'hidden',
        health: 'hidden',
    },
    consentDescription: "Die Verarbeitung Ihrer Daten erfolgt auf Grundlage unserer <a href='/datenschutz-intern' target='_blank'>Datenschutzerklärung</a>.",
    consentBoxes: [
        {
            key: "dse",
            text: "Ich habe die Datenschutzerklärung gelesen.",
            defaultValue: false,
            required: true
        }
    ],
    productOptions: [
        {
            key: 1,
            text: 'Beispielprodukt'
        }
    ],
    locationOptions: [
        {
            key: 1,
            text: 'Hauptniederlassung'
        }
    ],
    recipes: {
        operationID: {
            label: "Vorgangsnummer",
            required: true
        },
        statusOptions: [
            {
                key: 'new',
                text: 'neu'
            },{
                key: 'delivered',
                text: 'ausgeliefert'
            },{
                key: 'closed',
                text: 'beendet'
            }
        ]
    },
    reworkTypeOptions: [
        {
            key: 1,
            text: 'Passform ungenügend'
        },{
            key: 2,
            text: 'Gangbildveränderung'
        },{
            key: 3,
            text: 'Materialfehler'
        },{
            key: 4,
            text: 'Verklebung gelöst'
        },{
            key: 5,
            text: 'Fußbettung verschlissen'
        },{
            key: 0,
            text: 'Sonstiges'
        }
    ],
    ticketStatusOptions: [
        {
            key: 'open',
            text: 'offen'
        },
        {
            key: 'closed',
            text: 'geschlossen'
        },
    ],
    ageOptions: [
        {
            key: '1-5-jahre',
            text: '1–5 Jahre'
        },{
            key: '6-14-jahre',
            text: '6–14 Jahre'
        },{
            key: '15-21-jahre',
            text: '15–21 Jahre'
        },{
            key: '22-30-jahre',
            text: '22–30 Jahre'
        },{
            key: '31-40-jahre',
            text: '31–40 Jahre'
        },{
            key: '41-50-jahre',
            text: '41–50 Jahre'
        },{
            key: '51-60-jahre',
            text: '51–60 Jahre'
        },{
            key: '61-70-jahre',
            text: '61–70 Jahre'
        },{
            key: '71-80-jahre',
            text: '71–80 Jahre'
        },{
            key: '81-90-jahre',
            text: '81–90 Jahre'
        },{
            key: '>90-jahre',
            text: '>90 Jahre'
        }
    ],
    bereichOptions: [
        {
            key: 'Kindergarten',
            text: 'Kinderkrippe/Kindergarten'
        },{
            key: 'Bildung',
            text: 'Schule/Weiterbildung'
        },{
            key: 'Arbeit',
            text: 'Arbeit'
        },{
            key: 'Freizeit',
            text: 'Freizeit/Sport'
        }
    ],
    berufOptions: [
        {
            key: 'ueberwiegend sitzend',
            text: 'überwiegend sitzend (z. B. Büro)'
        },{
            key: 'ueberwiegend stehend',
            text: 'überwiegend stehend (z. B. Lehrer)'
        },{
            key: 'leichte Bewegung',
            text: 'überwiegend leichte Bewegung (z. B. Industrie)'
        },{
            key: 'schwere koerperliche Arbeit',
            text: 'überwiegend schwere körperliche Arbeit (z. B. Bau)'
        },{
            key: 'Sonstiges',
            text: 'Sonstiges'
        }
    ],
    freizeitOptions: [
        {
            key: 'Wohnbereich',
            text: 'Wohnbereich'
        },{
            key: 'Haus und Hof',
            text: 'Haus und Hof'
        },{
            key: 'Garten',
            text: 'Garten'
        },{
            key: 'Hunderunde',
            text: 'Hunderunde'
        },{
            key: 'Ballsport',
            text: 'Ballsport'
        },{
            key: 'Joggen',
            text: 'Joggen'
        },{
            key: 'Wandern (aktiv)',
            text: 'Wandern (aktiv)'
        },{
            key: 'Spazieren gehen',
            text: 'Spazieren gehen'
        },{
            key: 'Sonstiges',
            text: 'Sonstiges'
        }
    ],
    schuhtypOptions: [
        {
            key: 'Slipper',
            text: 'Slipper'
        },{
            key: 'Halbschuhe',
            text: 'Halbschuhe'
        },{
            key: 'Pumps',
            text: 'Pumps'
        },{
            key: 'Knoechelschuhe',
            text: 'Knöchelschuhe'
        },{
            key: 'Stiefel',
            text: 'Stiefel'
        },{
            key: 'Sandalen',
            text: 'Sandalen'
        },{
            key: 'Pantoffeln',
            text: 'Pantoffeln'
        },{
            key: 'Arbeitsschuhe',
            text: 'Arbeitsschuhe'
        },{
            key: 'Sicherheitsschuhe',
            text: 'Sicherheitsschuhe'
        },{
            key: 'Sonstige',
            text: 'Sonstige'
        }
    ],
    compressionStockingOptions: [
        {
            key: 'Ja',
            text: 'Ja'
        },{
            key: 'Nein',
            text: 'Nein'
        }
    ],
    compressionStockingDressUpOptions: [
        {
            key: 'ohne fremde Hilfe',
            text: 'ohne fremde Hilfe'
        },{
            key: 'mit einer Anziehhilfe',
            text: 'mit einer Anziehhilfe'
        },{
            key: 'mithilfe einer zweiten Person',
            text: 'mithilfe einer zweiten Person'
        }
    ],
    compressionStockingSatisfactionOptions: [
        {
            key: 'Ja',
            text: 'Ja'
        },{
            key: 'Nein',
            text: 'Nein'
        }
    ],
    compressionStockingProblemsOptions: [
        {
            key: 'Optik',
            text: 'Optik'
        },{
            key: 'Passform',
            text: 'Passform'
        },{
            key: 'Qualitaet/Haltbarkeit',
            text: 'Qualität/Haltbarkeit'
        },{
            key: 'Wirksamkeit',
            text: 'Wirksamkeit'
        }
    ],
}

export default Config;