import React, { Component } from 'react';
import {
    TextField,
    Text,
    Label,
    PrimaryButton,
    ChoiceGroup,
    Checkbox,
    Pivot,
    PivotItem,
    DefaultButton
} from '@fluentui/react';

import { AppState, UserType } from '../../../App';
import Config from '../../../core/Config';
import styles from '../../Application/css/Application.module.css';

import { KundenDialog } from '../../User/Pages/KundenDialog';

export interface Doctor {
    nameAndLocation:string,
    specialization:string,
    illness:string
};
export interface Arbeitsunfaehigkeit {
    range:string,
    illness:string
};

export type DataFormProps = Readonly <{
    customer?: any[],
    data?: {
        addressCity: string,
        addressLine1: string,
        addressLine2: string,
        addressPLZ: string,
        berufsschule: boolean,
        berufsschuleTime: string,
        birthcountry: string,
        birthday: string,
        birthname: string,
        birthplace: string,
        bloodCirculation: string,
        disease: string,
        doctorAddressCity: string,
        doctorAddressLine1: string,
        doctorAddressLine2: string,
        doctorAddressPLZ: string,
        doctorEmail: string,
        doctorForename: string,
        doctorName: string,
        doctorPhoneNumber: string,
        doctorTitle: string,
        education: string,
        email: string,
        employees: string,
        employementStart: string,
        employementType: string,
        employer: string,
        employerType: string,
        fachhochschule: boolean,
        fachhochschuleTime: string,
        fachoberschule: boolean,
        fachoberschuleTime: string,
        fachschule: boolean,
        fachschuleTime: string,
        feeling: string,
        footOperation: string,
        footOperationReason: string,
        GesundheitProbleme: string,
        healthProblemsDesc: string,
        healthProblems: string,
        handicapType: string,
        handicapGrad: string,
        handicapMZ: string,
        handicapSince: string,
        Schwerb: string,
        MDK_AA_Accredited: string,
        MDK_AA_AccreditedInstitution: string,
        arbMedVU: string,
        arbMedVUGrund: string,
        BetriebsarztVorh: string,
        Betriebsarzt: string,
        BetriebsarztWeitergabe: string,
        Doctors?:Doctor[],
        Arbeitsunfaeähigkeiten?:Arbeitsunfaehigkeit[],
        foreignCountry: string,
        forename: string,
        gesamtschule: boolean,
        gesamtschuleTime: string,
        graduation: string,
        gymnasium: boolean,
        gymnasiumTime: string,
        handicap: string,
        hauptschule: boolean,
        hauptschuleTime: string,
        healthInsuranceAddressCity: string,
        healthInsuranceAddressLine1: string,
        healthInsuranceAddressLine2: string,
        healthInsuranceAddressPLZ: string,
        healthInsuranceName: string,
        healthInsurancePhoneNumber: string,
        healthInsuranceType: string,
        hochschule: boolean,
        hochschuleTime: string,
        illnessAccredited: string,
        illnessApplication: string,
        illnessApplicationInstitution: string,
        illnessInstitution: string,
        illnessReference: string,
        illnessType: string,
        investigation: string,
        jobDescription: string,
        // Add Field 2024-09-01
        jobTime: string,
        jobEndReason: string,
        jobRunning: string,
        jobRunningWish: string,
        maritalStatus: string,
        maxWeights: string,
        mdk: string,
        name: string,
        nationality: string,
        parentServices: string,
        parentServicesDate: string,
        parentServicesInsurance: string,
        parentServicesReference: string,
        pensionHolder: string,
        pensionNumber: string,
        phoneNumber: string,
        position: string,
        profession: string,
        realschule: boolean,
        realschuleTime: string,
        rehab: string,
        rehabEnd: string,
        rehabInstitution: string,
        rehabReference: string,
        rehabStart: string,
        retraining: string,
        retrainingInstitution: string,
        retrainingLocation: string,
        retrainingReason: string,
        retrainingReference: string,
        retrainingSuccess: string,
        retrainingTime: string,
        retrainingType: string,
        courseParticipation: string,
        courseParticipationSuccess: string,
        courseParticipationTypeAndPeriod: string,
        knowledgeOfForeignLanguages: string,
        knowledgeOfForeignLanguagesLevel: string,
        jobSeeking:string,
        jobSeekingAt:string,
        jobSeekingAA:string,
        jobless:string,
        joblessAt:string,
        joblessAA:string,
        sex: string,
        sitting: string,
        standing: string,
        bentover: string,
        armsAboveChest: string,
        kneeling: string,
        onLadders: string,
        liftingWeights: string,
        offtenWeights: string,
        technicalLiftingAids: string,
        technicalLiftingAidsText: string,
        LiftComment: string,
        Ganztagsarbeit: string,
        Teilzeitarbeit: string,
        "andere Arbeitszeitmodelle": string,
        "regelmäßige Frühschicht / Spätschicht": string,
        "regelmäßige Dreischicht": string,
        "andere-Arbeitszeitmodelle": string,
        "andere-ArbeitszeitmodelleText": string,
        study: string,
        sugar: string,
        sugarUlcer: string,
        temporaryEmployement: string,
        temporaryEmployementEnd: string,
        terminatedEmployement: string,
        terminatedEmployementEnd: string,
        title: string,
        vocationalTraining: string,
        vocationalTrainingExam: string,
        vocationalTrainingTime: string,
        walking: string,
        workingTime: string,
        "Kälte, Zugluft, Nässe":string,
        "Hitze":string,
        "starke-Staubentwicklung":string,
        "Rauch":string,
        "starker-Laerm":string,
        "Lärmschutz-muss-getragen-werden":string,
        "hautreizende-Stoffe":string,
        "Gase-Gerüche-Dämpfe":string,
        "atemwegsreizende-Stoffe":string,
        "überwiegend-witterungsgeschützt":string

        'atemwegsreizende-Stoffe-Text': string,
        'hautreizende-Stoffe-Text': string,
        'Gase-Gerüche-Dämpfe-Text': string,
        furtherRequest: string,
        furtherRequestText: string,
        furtherRequest2: string,
        furtherRequest2Text: string,
    },
    items: AppState['applications'],
    config: typeof Config,
    user: UserType,
    users: AppState['users'],
    onSave: (item: any) => void,
    onChange?: (key: keyof Required<DataFormProps>['data'], value: any) => void,
    router: {
        location: any,
        navigate: any,
        params: any
    }
}>

interface DataFormState{
    new: boolean,
    customer?: any,
    item?: any,
    showKundendialog:boolean
}


class DataForm extends Component<DataFormProps, DataFormState> {

    props: DataFormProps;
    customerOptions: [{key: number | string, text: string}];

    constructor(props: DataFormProps) {
        super(props);
        this.props = props;
        this.customerOptions = [{key: 0, text: 'Alle Kunden'}];
    }
    state: DataFormState = {
        new: true,
        showKundendialog:false
    };


    componentDidMount(){
        this.checkactive();
        this.customerOptions = [{key: 0, text: 'Alle Kunden'}];
        if (this.props.customer !== undefined){
            this.props.customer.forEach(item => {
                if(item.usergroup === 'users'){
                    this.customerOptions.push({key: item.id, text: item.firstname + ' ' + item.lastname});
                }
            });
    }

    }

    componentDidUpdate() {
        this.customerOptions = [{key: '', text: 'Alle Kunden'}];
        if (this.props.customer !== undefined){
            this.props.customer.forEach(item => {
                if(item.usergroup === 'users'){
                    this.customerOptions.push({key: item.id, text: item.firstname + ' ' + item.lastname});
                }
            });
    }

    }


    /**
     * Setzt Education Wert in Localstoreage
     * @param {string} label
     * @returns {boolean}
     */
    initEducation(label: string): boolean {
        let value = (localStorage.getItem('education') !== undefined && localStorage.getItem('education') !== null ?
        localStorage.getItem('education') + ' | ' + label : label)
        localStorage.setItem('education',value)
        let education = document.getElementById('education') as HTMLInputElement;
        if(education !== null && education !== undefined) {
            // @ts-ignore
            education.value = localStorage.getItem('education') + ' | ' + label;
            this.props.onChange!('education', education.value)
        }

        return true;
    }

    /**
     * Entfernt Education Wert aus Localstoreage
     * @param {string} label
     * @returns {boolean}
     */
    clearEducation(label: string): boolean {

        // Abrufen der Localstoreage
        let value = (localStorage.getItem('education') !== undefined && localStorage.getItem('education') !== null ?
            localStorage.getItem('education') : '')

        // Bilden des neuen Education Array
        let newEducation: any[] = [];

        // Altes Education wird durchlaufen und bereinigt
        for(let x = 0;x< value!.split('|').length;x++) {
            if(value!.split('|')[x] !== label) {
                newEducation.push(value!.split('|')[x])
            }
        }

        // Bilden der neuen Ruckgabe Value
        let newValue = '';

        // Das neue Education wird durchlaufen
        for(let x=0;x<newEducation.length;x++) {
            // Leere Elemente werden bereinigt
            if (newEducation[x] !== '') {
                // Verbliebene Education werden wieder hinzugefügt
                newValue += newEducation[x] + '|';
            }
        }

        // Neues Education wird in der Localstoreage gespeichert
        localStorage.setItem('education',newValue)
        return false;
    }

    fillFields(item: any) {
        this.setState({customer: item.key})

        if(item.key !== 0) {
            let currCustomer: Partial<{
                lastname: string,
                firstname: string,
                title: string,
                birthday: string,
                street: string,
                zip: string,
                city: string,
                email: string
            }> = {};
            this.props.customer!.forEach(entry => {
                if (entry.usergroup === 'users') {
                    if (item.key === entry.id) {
                        currCustomer = entry;
                    }
                }
            });

            if (this.props.data === undefined){
                throw new TypeError("data undefined");
            }

            this.props.data!.name = currCustomer.lastname!;
            this.props.data!.forename = currCustomer.firstname!;
            this.props.data!.title = currCustomer.title!;
            this.props.data!.birthday = currCustomer.birthday!;
            this.props.data!.addressLine1 = currCustomer.street!;
            this.props.data!.addressPLZ = currCustomer.zip!;
            this.props.data!.addressCity = currCustomer.city!;
            this.props.data!.email = currCustomer.email!;
            
            
            
        }
    }

    render() {
        return(
            <form className={styles.root} >
                <h1>{this.state.new === true ? 'Neuer Arbeitsschutz-Datensatz' : 'Arbeitsschutz-Datensatz'}</h1>
                <DefaultButton text='Kunde auswählen' onClick={()=>this.setState({showKundendialog:true})} />
                <KundenDialog
                    items={this.props.customer!.filter((item:any)=>item.usergroup==='users')}
                    hidden={!this.state.showKundendialog}
                    title={'Kunde auswählen'}
                    onClose={()=>{this.setState({showKundendialog:false})}}
                    onSelect={(item)=>{
                        item.key = item.id 
                        this.fillFields(item)
                        this.setState({showKundendialog:false})
                    }}
                />
                <Pivot id={'tablist'}>
                    <PivotItem
                        headerText="Antragsteller"
                    >
                        <TextField
                            label="Name"
                            value={this.props.data!.name}
                            onChange={(e) => this.props.onChange!('name', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Vorname"
                            value={this.props.data!.forename}
                            onChange={(e) => this.props.onChange!('forename', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Titel"
                            value={this.props.data!.title}
                            onChange={(e) => this.props.onChange!('title', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Geburtsname"
                            value={this.props.data!.birthname}
                            onChange={(e) => this.props.onChange!('birthname', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Staatsangehörigkeit"
                            value={this.props.data!.nationality}
                            onChange={(e) => this.props.onChange!('nationality', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Geschlecht"
                            selectedKey={this.props.data!.sex}
                            options={[
                                {
                                    key: "divers",
                                    text: "divers"
                                },
                                {
                                    key: "ohne Eintrag",
                                    text: "ohne Eintrag"
                                },
                                {
                                    key: "weiblich",
                                    text: "weiblich"
                                },
                                {
                                    key: "männlich",
                                    text: "männlich"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('sex', value!.key)}
                            className={styles.field}
                        />
                        <label htmlFor={"birthday"} className={'labelbirthdayAplication'}>Geburtstag</label>
                        <input type={'date'} id={'birthday'} defaultValue={this.props.data!.birthday} className={'birthdayAplication'} onChange={(e) => this.props.onChange!('birthday', (e.target as HTMLInputElement).value)}/>
                        <TextField
                            label="Geburtsort"
                            value={this.props.data!.birthplace}
                            onChange={(e) => this.props.onChange!('birthplace', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Geburtsland"
                            value={this.props.data!.birthcountry}
                            onChange={(e) => this.props.onChange!('birthcountry', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <Label>Adresse</Label>
                        <TextField
                            value={this.props.data!.addressLine1}
                            onChange={(e) => this.props.onChange!('addressLine1', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="Straße + Hausnummer"
                        />
                        <TextField
                            value={this.props.data!.addressLine2}
                            onChange={(e) => this.props.onChange!('addressLine2', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="Adresszusatz"
                        />
                        <TextField
                            value={this.props.data!.addressPLZ}
                            onChange={(e) => this.props.onChange!('addressPLZ', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="PLZ"
                        />
                        <TextField
                            value={this.props.data!.addressCity}
                            onChange={(e) => this.props.onChange!('addressCity', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="Stadt"
                        />
                        <TextField
                            label="Telefon"
                            value={this.props.data!.phoneNumber}
                            onChange={(e) => this.props.onChange!('phoneNumber', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="E-Mail-Adresse"
                            value={this.props.data!.email}
                            onChange={(e) => this.props.onChange!('email', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Familienstand"
                            selectedKey={this.props.data!.maritalStatus}
                            options={[
                                {
                                    key: "ledig",
                                    text: "ledig"
                                },
                                {
                                    key: "verheiratet",
                                    text: "verheiratet"
                                },
                                {
                                    key: "geschieden",
                                    text: "geschieden"
                                },
                                {
                                    key: "verwitwet",
                                    text: "verwitwet"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('maritalStatus', value!.key)}
                            className={styles.field}
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="Gesundheit"
                    >
                        <ChoiceGroup
                            label="Sind bei Ihnen Gesundheitsstörungen anerkannt worden"
                            selectedKey={this.props.data!.illnessAccredited}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('illnessAccredited', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.illnessAccredited === 'Ja' &&
                        <>
                        <TextField
                            label="Von welcher Stelle?"
                            value={this.props.data!.illnessInstitution}
                            onChange={(e) => this.props.onChange!('illnessInstitution', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aktenzeichen"
                            value={this.props.data!.illnessReference}
                            onChange={(e) => this.props.onChange!('illnessReference', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Welche Störung?"
                            value={this.props.data!.illnessType}
                            onChange={(e) => this.props.onChange!('illnessType', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        </>
                        }
                        <ChoiceGroup
                            label="Haben Sie aktuell einen entsprechenden Antrag gestellt?"
                            selectedKey={this.props.data!.illnessApplication}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('illnessApplication', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.illnessApplication === 'Ja' &&
                        <TextField
                            label="Bei welcher Stelle?"
                            value={this.props.data!.illnessApplicationInstitution}
                            onChange={(e) => this.props.onChange!('illnessApplicationInstitution', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        <ChoiceGroup
                            label="Haben Sie in den letzten 4 Jahren Leistungen zur medizinischen Rehabilitation (auch anderer Rehabilitationsträger, zum Beispiel Krankenkasse, Versorgungsamt, Unfallversicherungsträger) erhalten?"
                            selectedKey={this.props.data!.rehab}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('rehab', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.rehab ==='Ja' &&
                        <>
                        <TextField
                            label="Von welcher Stelle?"
                            value={this.props.data!.rehabInstitution}
                            onChange={(e) => this.props.onChange!('rehabInstitution', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aktenzeichen"
                            value={this.props.data!.rehabReference}
                            onChange={(e) => this.props.onChange!('rehabReference', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Beginn"
                            value={this.props.data!.rehabStart}
                            onChange={(e) => this.props.onChange!('rehabStart', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Ende"
                            value={this.props.data!.rehabEnd}
                            onChange={(e) => this.props.onChange!('rehabEnd', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        </>
                        }
                        <ChoiceGroup
                            label="Haben Sie unmittelbar vor diesem Rehabilitationsantrag bereits bei Ihrer Krankenkasse einen Antrag auf Mutter-Kind-Leistungen / Vater-Kind-Leistungen (Vorsorge oder Rehabilitation) gestellt beziehungsweise sind solche Leistungen zuvor verordnet worden?"
                            selectedKey={this.props.data!.parentServices}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('parentServices', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.parentServices === 'Ja' &&
                        <>
                        <TextField
                            label="Datum"
                            value={this.props.data!.parentServicesDate}
                            onChange={(e) => this.props.onChange!('parentServicesDate', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Name der Krankenkasse"
                            value={this.props.data!.parentServicesInsurance}
                            onChange={(e) => this.props.onChange!('parentServicesInsurance', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aktenzeichen"
                            value={this.props.data!.parentServicesReference}
                            onChange={(e) => this.props.onChange!('parentServicesReference', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        </>
                        }
                        <ChoiceGroup
                            label="Ist bei Ihnen eine Zuckerkrankheit bekannt?"
                            selectedKey={this.props.data!.sugar}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('sugar', value!.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Hatten Sie schon einmal Geschwüre oder offene Stellen an den Füßen?"
                            selectedKey={this.props.data!.sugarUlcer}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('sugarUlcer', value!.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Haben Sie Gefühlstörungen an den Füßen?"
                            selectedKey={this.props.data!.feeling}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('feeling', value!.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Sind bei Ihnen Durchblutungsstörungen der Beine bekannt?"
                            selectedKey={this.props.data!.bloodCirculation}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('bloodCirculation', value!.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Sind Sie schon einmal an den Füßen operiert worden?"
                            selectedKey={this.props.data!.footOperation}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('footOperation', value!.key)}
                            className={styles.field}
                        />
                        <TextField
                            label="Wennja, weshalb?"
                            value={this.props.data!.footOperationReason}
                            onChange={(e) => this.props.onChange!('footOperationReason', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />                       
                        <TextField
                             label="Welche gesundheitlichen Probleme stehen bei Ihnen derzeit im Vordergrund?"
                             value={this.props.data!.GesundheitProbleme}
                             onChange={(e) => this.props.onChange!('GesundheitProbleme',  (e.target as HTMLInputElement).value)}
                             className={styles.field}
                             multiline
                             autoAdjustHeight
                         />
                    
                    <ChoiceGroup
                            label="Haben oder hatten Sie noch andere Gesundheitsstörungen (zum Beispiel Bluthochdruck, Herzerkrankung,
                                Zuckerkrankheit, Bronchitis, Gelbsucht, Unfallfolgen)??"
                            selectedKey={this.props.data!.healthProblems}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('healthProblems', value!.key)}
                            className={styles.field}
                        />
                    {this.props.data!.healthProblems === 'Ja' &&
                        <TextField
                            label="Beschreibung der Störung"
                            value={this.props.data!.healthProblemsDesc}
                            onChange={(e) => this.props.onChange!('healthProblemsDesc',  (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            multiline
                            autoAdjustHeight
                        />
                    }
                    
                    <div><Text variant='large'>Arbeitsunfaeähigkeiten in den letzten 12 Monten</Text></div>
                        {this.props.data!.Arbeitsunfaeähigkeiten?.map((doctor, index) =><div key={index}  className={styles.doctor}>
                            <div><Text variant='mediumPlus'>Arbeitsunfaeähigkeit {index+1}</Text></div>
                            
                            <TextField
                                label="von - bis "
                                value={this.props.data!.Arbeitsunfaeähigkeiten![index].range}
                                onChange={(e) => {
                                    let Arbeitsunfaeähigkeiten = this.props.data!.Arbeitsunfaeähigkeiten || [];
                                    Arbeitsunfaeähigkeiten[index].range =  (e.target as HTMLInputElement).value;
                                    this.props.onChange!('Arbeitsunfaeähigkeiten', Arbeitsunfaeähigkeiten);
                                }}
                                className={styles.field}
                            />
                            <TextField
                                label="Wegen"
                                value={this.props.data!.Arbeitsunfaeähigkeiten![index].illness}
                                onChange={(e) => {
                                    let Arbeitsunfaeähigkeiten = this.props.data!.Arbeitsunfaeähigkeiten || [];
                                    Arbeitsunfaeähigkeiten[index].illness =  (e.target as HTMLInputElement).value;
                                    this.props.onChange!('Arbeitsunfaeähigkeiten', Arbeitsunfaeähigkeiten);
                                }}
                                className={styles.field}
                            />
                            <DefaultButton text="AU entfernen" iconProps={{iconName:"Delete"}} onClick={(e)=>{
                                let Arbeitsunfaeähigkeiten = this.props.data!.Arbeitsunfaeähigkeiten || [];
                                Arbeitsunfaeähigkeiten.splice(index,1);
                                this.props.onChange!('Arbeitsunfaeähigkeiten', Arbeitsunfaeähigkeiten);
    
                            }}/>
                        </div>)}
                        <DefaultButton text="Hinzufügen" onClick={(e)=>{
                            let doctors = this.props.data!.Arbeitsunfaeähigkeiten || [];
                            doctors.push( {
                                range:'',
                                illness:''
                            }) 
                            this.props.onChange!('Arbeitsunfaeähigkeiten', doctors);

                        }}/>
                    <ChoiceGroup
                        label="Wurde bei Ihnen eine Schwerbehinderung festgestellt oder sind Sie einem schwerbehinderten Menschen
                        gleichgestellt?"
                        selectedKey={this.props.data!.Schwerb}
                        options={[
                            {
                                key: "Ja",
                                text: "Ja"
                            },
                            {
                                key: "Nein",
                                text: "Nein"
                            }
                        ]}
                        onChange={(e, value) => this.props.onChange!('Schwerb', value!.key)}
                        className={styles.field}
                    />
                    {this.props.data!.Schwerb === 'Ja' &&
                    <>
                    <TextField
                        label="Art der Behinderung"
                        id={"handicapType"}
                        value={this.props.data!.handicapType}
                        onChange={(e) => this.props.onChange!('handicapType',  (e.target as HTMLInputElement).value)}
                        className={styles.field}
                    />
                    <TextField
                        label="Grad der Behinderung"
                        value={this.props.data!.handicapGrad}
                        onChange={(e) => this.props.onChange!('handicapGrad',  (e.target as HTMLInputElement).value)}
                        className={styles.field}
                    />
                    <TextField
                        label="Merkzeichen"
                        value={this.props.data!.handicapMZ}
                        onChange={(e) => this.props.onChange!('handicapMZ',  (e.target as HTMLInputElement).value)}
                        className={styles.field}
                    />
                    <TextField
                        label="seit"
                        value={this.props.data!.handicapSince}
                        onChange={(e) => this.props.onChange!('handicapSince',  (e.target as HTMLInputElement).value)}
                        className={styles.field}
                    />
                    </>
                    }
                    
                    <ChoiceGroup
                        label="Sind Sie in den letzten 12 Monaten vom Medizinischen Dienst der Krankenkassen, von der Agentur für Arbeit oder
                        von einer anderen Stelle begutachtet worden?"
                        selectedKey={this.props.data!.MDK_AA_Accredited}
                        options={[
                            {
                                key: "Ja",
                                text: "Ja"
                            },
                            {
                                key: "Nein",
                                text: "Nein"
                            }
                        ]}
                        onChange={(e, value) => this.props.onChange!('MDK_AA_Accredited', value!.key)}
                        className={styles.field}
                    />
                    {this.props.data!.MDK_AA_Accredited === 'Ja' &&
                    <>
                    <TextField
                        label="Wann und on welcher Stelle?"
                        value={this.props.data!.MDK_AA_AccreditedInstitution}
                        onChange={(e) => this.props.onChange!('MDK_AA_AccreditedInstitution',  (e.target as HTMLInputElement).value)}
                        className={styles.field}
                    />
                    </>
                    }
                    <ChoiceGroup
                        label="Nehmen Sie an arbeitsmedizinischen Vorsorgeuntersuchungen teil?"
                        selectedKey={this.props.data!.arbMedVU}
                        options={[
                            {
                                key: "Ja",
                                text: "Ja"
                            },
                            {
                                key: "Nein",
                                text: "Nein"
                            }
                        ]}
                        onChange={(e, value) => {
                            this.props.onChange!('arbMedVU', value!.key);
                            //Chane Field uder "Sonstiges too
                            this.props.onChange!('investigation', value!.key);
                        }}
                        className={styles.field}
                    />
                    {this.props.data!.MDK_AA_Accredited === 'Ja' &&
                    <>
                    <TextField
                        label="wegen?"
                        value={this.props.data!.arbMedVUGrund}
                        onChange={(e) => this.props.onChange!('arbMedVUGrund',  (e.target as HTMLInputElement).value)}
                        className={styles.field}
                    />
                    </>
                    }
                    <ChoiceGroup
                        label="Ist ein Betriebsarzt / Werksarzt vorhanden?"
                        selectedKey={this.props.data!.BetriebsarztVorh}
                        options={[
                            {
                                key: "Ja",
                                text: "Ja"
                            },
                            {
                                key: "Nein",
                                text: "Nein"
                            }
                        ]}
                        onChange={(e, value) => {
                            this.props.onChange!('BetriebsarztVorh', value!.key)
                            this.props.onChange!('BetriebsarztWeitergabe', value!.key)
                            
                        }}
                        className={styles.field}
                    />
                    {this.props.data!.BetriebsarztVorh === 'Ja' &&
                    <>
                    <TextField
                        label="Name, Anschrift und Telefon des Arztes"
                        value={this.props.data!.Betriebsarzt}
                        onChange={(e) => this.props.onChange!('Betriebsarzt',  (e.target as HTMLInputElement).value)}
                        className={styles.field}
                    />
                    
                    <ChoiceGroup
                        label="Ich bin damit einverstanden, dass sich der Rentenversicherungsträger mit dem Betriebsarzt / Werksarzt oder dem
                        Betrieb hinsichtlich einer Prüfung des Arbeitsplatzes und gegebenenfalls weiterer für erforderlich gehaltener
                        Leistungen in Verbindung setzt (diese Erklärung kann jederzeit widerrufen werden)."
                        selectedKey={this.props.data!.BetriebsarztWeitergabe}
                        options={[
                            {
                                key: "Ja",
                                text: "Ja"
                            },
                            {
                                key: "Nein",
                                text: "Nein"
                            }
                        ]}
                        onChange={(e, value) => this.props.onChange!('BetriebsarztWeitergabe', value!.key)}
                        className={styles.field}
                    />
                    </>
                    }
                    </PivotItem>
                    <PivotItem
                        headerText="Ausbildung"
                    >
                        <div className={styles.field} id={'education'}>
                            <label className={styles.multiselectLabel}>Welche Schulen haben Sie besucht?</label>
                           <Checkbox className={styles.multiselectOption} label="Hauptschule / Polytechnische Oberschule (8 Klassen)" defaultChecked={StringtoBool(this.props.data!.hauptschule) === true}
                           onChange={() => this.props.onChange!('hauptschule', StringtoBool(this.props.data!.hauptschule) === true ?
                              this.clearEducation('Hauptschule / Polytechnische Oberschule (8 Klassen)') : this.initEducation('Hauptschule / Polytechnische Oberschule (8 Klassen)') )} />
                            <Checkbox className={styles.multiselectOption} label="Realschule / Polytechnische Oberschule (10 Klassen)" defaultChecked={StringtoBool(this.props.data!.realschule) === true}
                             onChange={() => this.props.onChange!('realschule', StringtoBool(this.props.data!.realschule) === true ?
                              this.clearEducation('Realschule / Polytechnische Oberschule (10 Klassen)') : this.initEducation('Realschule / Polytechnische Oberschule (10 Klassen)') )} />
                            <Checkbox className={styles.multiselectOption} label="Gymnasium / Erweiterte Oberschule" defaultChecked={StringtoBool(this.props.data!.gymnasium) === true}
                            onChange={() => this.props.onChange!('gymnasium', StringtoBool(this.props.data!.gymnasium) === true ?
                              this.clearEducation('Gymnasium / Erweiterte Oberschule') : this.initEducation('Gymnasium / Erweiterte Oberschule') )}/>
                            <Checkbox className={styles.multiselectOption} label="Gesamtschule" defaultChecked={StringtoBool(this.props.data!.gesamtschule) === true}
                             onChange={() => this.props.onChange!('gesamtschule', StringtoBool(this.props.data!.gesamtschule) === true ?
                              this.clearEducation('Gesamtschule') : this.initEducation('Gesamtschule') )} />
                            <Checkbox className={styles.multiselectOption} label="Berufsschule / Berufsfachschule / Berufsaufbauschule" defaultChecked={StringtoBool(this.props.data!.berufsschule) === true}
                            onChange={() => this.props.onChange!('berufsschule', StringtoBool(this.props.data!.berufsschule) === true ?
                              this.clearEducation('Berufsschule / Berufsfachschule / Berufsaufbauschule') : this.initEducation('Berufsschule / Berufsfachschule / Berufsaufbauschule') )} />
                            <Checkbox className={styles.multiselectOption} label="Fachschule" defaultChecked={StringtoBool(this.props.data!.fachschule) === true}
                            onChange={() => this.props.onChange!('fachschule', StringtoBool(this.props.data!.fachschule) === true ?
                              this.clearEducation('Fachschule') : this.initEducation('Fachschule') )}/>
                            <Checkbox className={styles.multiselectOption} label="Fachoberschule" defaultChecked={StringtoBool(this.props.data?.fachoberschule) === true}
                            onChange={() => this.props.onChange!('fachoberschule', StringtoBool(this.props.data?.fachoberschule) === true ?
                              this.clearEducation('Fachoberschule') : this.initEducation('Fachoberschule') )}  />
                            <Checkbox className={styles.multiselectOption} label="Fachhochschule" defaultChecked={StringtoBool(this.props.data!.fachhochschule) === true}
                            onChange={() => this.props.onChange!('fachhochschule', StringtoBool(this.props.data!.fachhochschule) === true ?
                              this.clearEducation('Fachhochschule') : this.initEducation('Fachhochschule') )} />
                            <Checkbox className={styles.multiselectOption} label="Hochschule" defaultChecked={StringtoBool(this.props.data!.hochschule) === true}
                            onChange={() => this.props.onChange!('hochschule', StringtoBool(this.props.data!.hochschule) === true ?
                              this.clearEducation('Hochschule') : this.initEducation('Hochschule') )} />

                            {/*{this.buildCheckboxEducation()}    */}
                        </div>
                        {this.props.data!.hauptschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Hauptschule?"
                            value={this.props.data!.hauptschuleTime}
                            onChange={(e) => this.props.onChange!('hauptschuleTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data!.realschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Realschule?"
                            value={this.props.data!.realschuleTime}
                            onChange={(e) => this.props.onChange!('realschuleTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data!.gymnasium === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf dem Gymnasium?"
                            value={this.props.data!.gymnasiumTime}
                            onChange={(e) => this.props.onChange!('gymnasiumTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data!.gesamtschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Gesamtschule?"
                            value={this.props.data!.gesamtschuleTime}
                            onChange={(e) => this.props.onChange!('gesamtschuleTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data!.berufsschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Berufsschule?"
                            value={this.props.data!.berufsschuleTime}
                            onChange={(e) => this.props.onChange!('berufsschuleTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data!.fachschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Fachschule?"
                            value={this.props.data!.fachschuleTime}
                            onChange={(e) => this.props.onChange!('fachschuleTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data!.fachoberschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Fachoberschule?"
                            value={this.props.data!.fachoberschuleTime}
                            onChange={(e) => this.props.onChange!('fachoberschuleTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data!.fachhochschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Fachhochschule?"
                            value={this.props.data!.fachhochschuleTime}
                            onChange={(e) => this.props.onChange!('fachhochschuleTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data!.hochschule === true &&
                        <TextField
                            label="Von wann bis wann waren Sie auf der Hochschule?"
                            value={this.props.data!.hochschuleTime}
                            onChange={(e) => this.props.onChange!('hochschuleTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        <TextField
                            label="Erreichte Schulabschlüsse oder Art der abgelegten Prüfungen"
                            value={this.props.data!.graduation}
                            onChange={(e) => this.props.onChange!('graduation', (e.target as HTMLInputElement).value)}
                            className={styles.field} 
                            multiline={true}
                        />
                        <TextField
                            label="Welche Berufsausbildung haben Sie?"
                            id={"vocationalTraining"}
                            value={this.props.data!.vocationalTraining}
                            onChange={(e) => this.props.onChange!('vocationalTraining', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Von wann bis wann war Ihre Berufsausbildung?"
                            value={this.props.data!.vocationalTrainingTime}
                            onChange={(e) => this.props.onChange!('vocationalTrainingTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Mit welcher Prüfungsart wurde Ihre Berufsausbildung abgeschlossen?"
                            value={this.props.data!.vocationalTrainingExam}
                            onChange={(e) => this.props.onChange!('vocationalTrainingExam', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Hatten Sie schon mal eine Umschulung?"
                            selectedKey={this.props.data!.retraining}
                            options={[
                                {
                                    key: "ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('retraining', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.retraining === 'ja' &&
                        <>
                        <TextField
                            label="Umschulungsberuf"
                            id={"retraining"}
                            value={this.props.data!.retrainingType}
                            onChange={(e) => this.props.onChange!('retrainingType', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Umschulungszeitraum"
                            value={this.props.data!.retrainingTime}
                            onChange={(e) => this.props.onChange!('retrainingTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aus welchem Grund erfolgte die Umschulung?"
                            value={this.props.data!.retrainingReason}
                            onChange={(e) => this.props.onChange!('retrainingReason', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            multiline={true}
                        />
                        <ChoiceGroup
                            label="Wurde die Umschulung erfolgreich beendet?"
                            selectedKey={this.props.data!.retrainingSuccess}
                            options={[
                                {
                                    key: "ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('retrainingSuccess', value!.key)}
                            className={styles.field}
                        />
                        <TextField
                            label="Wer war der Kostenträger der Umschulung?"
                            value={this.props.data!.retrainingInstitution}
                            onChange={(e) => this.props.onChange!('retrainingInstitution', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aktenzeichen des Kostenträgers"
                            value={this.props.data!.retrainingReference}
                            onChange={(e) => this.props.onChange!('retrainingReference', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Wo erfolgte die Umschulung?"
                            value={this.props.data!.retrainingLocation}
                            onChange={(e) => this.props.onChange!('retrainingLocation', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        </>
                        }

<ChoiceGroup
                            label="Haben Sie an Lehrgängen, Fernlehrgängen oder Kursen teilgenommen?"
                            selectedKey={this.props.data!.courseParticipation}
                            options={[
                                {
                                    key: "ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('courseParticipation', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.courseParticipation === 'ja' &&
                        <>
                        <TextField
                            label="Umschulungsberuf"
                            id={"retraining"}
                            value={this.props.data!.retrainingType}
                            onChange={(e) => this.props.onChange!('retrainingType', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Umschulungszeitraum"
                            value={this.props.data!.retrainingTime}
                            onChange={(e) => this.props.onChange!('retrainingTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Aus welchem Grund erfolgte die Umschulung?"
                            value={this.props.data!.retrainingReason}
                            onChange={(e) => this.props.onChange!('retrainingReason', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            multiline={true}
                        />
                        <ChoiceGroup
                            label="Wurde die Umschulung erfolgreich beendet?"
                            selectedKey={this.props.data!.courseParticipationSuccess}
                            options={[
                                {
                                    key: "ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('courseParticipationSuccess', value!.key)}
                            className={styles.field}
                        />
                        <TextField
                            label="Art und Zeitraumn"
                            value={this.props.data!.courseParticipationTypeAndPeriod}
                            onChange={(e) => this.props.onChange!('courseParticipationTypeAndPeriod', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        </>
                        }


                        <ChoiceGroup
                            label="Haben Sie Fremdsprachenkenntnisse?"
                            selectedKey={this.props.data!.knowledgeOfForeignLanguages}
                            options={[
                                {
                                    key: "ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('knowledgeOfForeignLanguages', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.knowledgeOfForeignLanguages === 'ja' &&
                        <>
                        <TextField
                            label="Sprache und Beherrschungsgrad"
                            value={this.props.data!.knowledgeOfForeignLanguagesLevel}
                            onChange={(e) => this.props.onChange!('knowledgeOfForeignLanguagesLevel', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        </>
                        }

                    </PivotItem>
                    <PivotItem
                        headerText="Beruf"
                    >
                        <TextField
                            label="Zuletzt ausgeübte Erwerbstätigkeit / Beruf"
                            value={this.props.data!.profession}
                            onChange={(e) => this.props.onChange!('profession', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Derzeitige Stellung im Beruf / Erwerbsleben"
                            selectedKey={this.props.data!.position}
                            options={[
                                {
                                    key: "nicht erwerbstätig",
                                    text: "nicht erwerbstätig"
                                },
                                {
                                    key: "Auszubildender",
                                    text: "Auszubildender"
                                },
                                {
                                    key: "ungelernter Arbeiter",
                                    text: "ungelernter Arbeiter"
                                },
                                {
                                    key: "angelernter Arbeiter in anerkanntem Anlernberuf",
                                    text: "angelernter Arbeiter in anerkanntem Anlernberuf"
                                },
                                {
                                    key: "Facharbeiter",
                                    text: "Facharbeiter"
                                },
                                {
                                    key: "Meister, Polier",
                                    text: "Meister, Polier"
                                },
                                {
                                    key: "Angestellter",
                                    text: "Angestellter"
                                },
                                {
                                    key: "Beamter oder DO-Angestellter, Versorgungsempfänger im Sinne des Beamtenrechts",
                                    text: "Beamter oder DO-Angestellter, Versorgungsempfänger im Sinne des Beamtenrechts"
                                },
                                {
                                    key: "Selbständiger",
                                    text: "Selbständiger"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('position', value!.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Arbeit vor Antragstellung oder vor aktueller Arbeitsunfähigkeit"
                            selectedKey={this.props.data!.employementType}
                            options={[
                                {
                                    key: "nicht erwerbstätig",
                                    text: "nicht erwerbstätig"
                                },
                                {
                                    key: "Ganztagsarbeit ohne Wechselschicht oder Akkord oder Nachtschicht",
                                    text: "Ganztagsarbeit ohne Wechselschicht oder Akkord oder Nachtschicht"
                                },
                                {
                                    key: "Ganztagsarbeit mit Wechselschicht oder Akkord",
                                    text: "Ganztagsarbeit mit Wechselschicht oder Akkord"
                                },
                                {
                                    key: "Ganztagsarbeit mit Nachtschicht",
                                    text: "Ganztagsarbeit mit Nachtschicht"
                                },
                                {
                                    key: "Teilzeitarbeit, weniger als die Hälfte der üblichen Arbeitszeit",
                                    text: "Teilzeitarbeit, weniger als die Hälfte der üblichen Arbeitszeit"
                                },
                                {
                                    key: "Teilzeitarbeit, mindestens die Hälfte der üblichen Arbeitszeit",
                                    text: "Teilzeitarbeit, mindestens die Hälfte der üblichen Arbeitszeit"
                                },
                                {
                                    key: "ausschließlich Tätigkeit als Hausfrau oder Hausmann",
                                    text: "ausschließlich Tätigkeit als Hausfrau oder Hausmann"
                                },
                                {
                                    key: "arbeitslos gemeldet",
                                    text: "arbeitslos gemeldet"
                                },
                                {
                                    key: "Heimarbeit",
                                    text: "Heimarbeit"
                                },
                                {
                                    key: "Beschäftigung in einer Werkstatt für behinderte Menschen",
                                    text: "Beschäftigung in einer Werkstatt für behinderte Menschen"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('employementType', value!.key)}
                            className={styles.field}
                        />
                        <TextField
                            label="Name und Anschrift des Arbeitgebers"
                            value={this.props.data!.employer}
                            onChange={(e) => this.props.onChange!('employer', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Beschäftigungsbegin"
                            value={this.props.data!.employementStart}
                            onChange={(e) => {
                                this.props.onChange!('employementStart', (e.target as HTMLInputElement).value);
                                this.props.onChange!('jobTime', (e.target as HTMLInputElement).value + ' bis heute');
                            }}
                            className={styles.field}
                        />
                        <TextField
                            label="Anzahl der Mitarbeiter im Betrieb"
                            value={this.props.data!.employees}
                            onChange={(e) => this.props.onChange!('employees', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Art des Betriebes"
                            value={this.props.data!.employerType}
                            onChange={(e) => this.props.onChange!('employerType', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="genaue Tätigkeitsbezeichnung"
                            value={this.props.data!.jobDescription}
                            onChange={(e) => this.props.onChange!('jobDescription', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        
                        {/* Add Field 2024-09-01 */}
                        <TextField
                            label="ausgeübt von - bis"
                            value={this.props.data!.jobTime}
                            onChange={(e) => this.props.onChange!('jobTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="beendet wegen"
                            value={this.props.data!.jobEndReason}
                            onChange={(e) => this.props.onChange!('jobEndReason', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />



                        <ChoiceGroup
                            label="Ist das Beschäftigungsverhältnis befristet?"
                            selectedKey={this.props.data!.temporaryEmployement}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('temporaryEmployement', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.temporaryEmployement === 'Ja' &&
                        <TextField
                            label="Befristung bis"
                            value={this.props.data!.temporaryEmployementEnd}
                            onChange={(e) => this.props.onChange!('temporaryEmployementEnd', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        <ChoiceGroup
                            label="Ist das Beschäftigungsverhältnis gekündigt?"
                            selectedKey={this.props.data!.terminatedEmployement}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('terminatedEmployement', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.terminatedEmployement === 'Ja' &&
                        <TextField
                            label="Befristung bis"
                            value={this.props.data!.terminatedEmployementEnd}
                            onChange={(e) => this.props.onChange!('terminatedEmployementEnd', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        <ChoiceGroup
                            label="Arbeitssuchend gemeldet"
                            selectedKey={this.props.data!.jobSeeking}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('jobSeeking', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.jobSeeking === 'Ja' &&
                        <>
                        <TextField
                            label="am"
                            value={this.props.data!.jobSeekingAt}
                            onChange={(e) => this.props.onChange!('jobSeekingAt', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="bei welcher Agentur für Arbeit?"
                            value={this.props.data!.jobSeekingAA}
                            onChange={(e) => this.props.onChange!('jobSeekingAA', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        </>
                        }

                        {/* Add Field 2024-09-01 */}
                        <ChoiceGroup
                            label="Wird eine Weiterbeschäftigung oder Wiederbeschäftigung beim derzeitigen oder letzten Arbeitgeber gewünscht?"
                            selectedKey={this.props.data!.jobRunning}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('jobRunning', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.jobRunning === 'Ja' &&
                        <>
                        <TextField
                            label="gegebenenfalls Wünsche zur innerbetrieblichen Umsetzung:"
                            value={this.props.data!.jobRunningWish}
                            onChange={(e) => this.props.onChange!('jobRunningWish', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        </>
                        }
                        <ChoiceGroup
                            label="Arbeitslos gemeldet"
                            selectedKey={this.props.data!.jobless}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('jobless', value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!.jobless === 'Ja' &&
                        <>
                        <TextField
                            label="am"
                            value={this.props.data!.joblessAt}
                            onChange={(e) => this.props.onChange!('joblessAt', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="bei welcher Agentur für Arbeit?"
                            value={this.props.data!.joblessAA}
                            onChange={(e) => this.props.onChange!('joblessAA', (e.target as HTMLInputElement).value)}
                        />
                        </>
                        }
                        
                        <Text variant='large'>Arbeitshaltung</Text>
                        <ChoiceGroup
                            label="Arbeitshaltung stehend"
                            selectedKey={this.props.data!.standing}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange!('standing', value!.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Arbeitshaltung gehend"
                            selectedKey={this.props.data!.walking}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange!('walking', value!.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Arbeitshaltung sitzend"
                            selectedKey={this.props.data!.sitting}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange!('sitting', value!.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Arbeitshaltung gebückt"
                            selectedKey={this.props.data!.bentover}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange!('bentover', value!.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="Arme über Brusthöhe"
                            selectedKey={this.props.data!.armsAboveChest}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange!('armsAboveChest', value!.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="kniehend / hockend"
                            selectedKey={this.props.data!.kneeling}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange!('kneeling', value!.key)}
                            className={styles.field}
                        />
                        <ChoiceGroup
                            label="auf Gerüsten / Leitern"
                            selectedKey={this.props.data!.onLadders}
                            options={[
                                {
                                    key: "ständig",
                                    text: "ständig"
                                },
                                {
                                    key: "überwiegend",
                                    text: "überwiegend"
                                },
                                {
                                    key: "zeitweise",
                                    text: "zeitweise"
                                },
                            ]}
                            onChange={(e, value) => this.props.onChange!('onLadders', value!.key)}
                            className={styles.field}
                        />
                        <Text variant='large'>Heben/ Tragen</Text>
                        <TextField
                            label="Art der zu hebenden Lasten"
                            value={this.props.data!.liftingWeights}
                            onChange={(e) => this.props.onChange!('liftingWeights', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="häuzfig zu hebende Lasten"
                            value={this.props.data!.offtenWeights}
                            onChange={(e) => this.props.onChange!('offtenWeights', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            suffix="kg"
                            type="number"
                        />
                        
                        <TextField
                            label="max. zu hebende Lasten"
                            value={this.props.data!.maxWeights}
                            onChange={(e) => this.props.onChange!('maxWeights', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            suffix="kg"
                            type="number"
                        />                        <ChoiceGroup
                        label="Sind technische Hebehilfsmittel vorhanden?"
                        selectedKey={this.props.data!.technicalLiftingAids}
                        options={[
                            {
                                key: "Ja",
                                text: "Ja"
                            },
                            {
                                key: "Nein",
                                text: "Nein"
                            }
                        ]}
                        onChange={(e, value) => this.props.onChange!('technicalLiftingAids', value!.key)}
                        className={styles.field}
                    />
                    {this.props.data!.technicalLiftingAids === 'Ja' &&
                    <TextField
                        label="Folgende:"
                        value={this.props.data!.technicalLiftingAidsText}
                        onChange={(e) => this.props.onChange!('technicalLiftingAidsText', (e.target as HTMLInputElement).value)}
                        className={styles.field}
                    />
                    }
                    <TextField
                        label="weitere Bemerkung"
                        value={this.props.data!.LiftComment}
                        onChange={(e) => this.props.onChange!('LiftComment', (e.target as HTMLInputElement).value)}
                        className={styles.field}
                    />
                    
                    <Text variant='large'>Arbeitsorganisation</Text>
                        <TextField
                            label="wöchentliche Arbeitszeit"
                            value={this.props.data!.workingTime}
                            onChange={(e) => this.props.onChange!('workingTime', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            suffix="h"
                            type="number"
                        />
                    {["Ganztagsarbeit","Teilzeitarbeit", "andere-Arbeitszeitmodelle","regelmäßige Frühschicht / Spätschicht","regelmäßige-Dreischicht"].map(key=>{

                            return <div key={key}>
                            <ChoiceGroup                         
                               label={key.replace('-',' ')}
                                selectedKey={this.props.data![key]}
                                options={[
                                    { 
                                        key: "Ja",
                                        text: "Ja"
                                    },
                                    {
                                        key: "Nein",
                                        text: "Nein"
                                    }
                                ]}
                                onChange={(e, value) => this.props.onChange!(key as  keyof Required<DataFormProps>['data'] , value!.key)}
                                className={styles.field}
                            />
                            {this.props.data!["andere-Arbeitszeitmodelle"] === 'Ja' && key==="andere-Arbeitszeitmodelle" &&
                            <TextField
                                label="Folgende:"
                                value={this.props.data!['other_working_time_models_text']}
                                onChange={(e) => this.props.onChange!('andere-ArbeitszeitmodelleText', (e.target as HTMLInputElement).value)}
                                className={styles.field}
                            />
                            }
                        </div>
                    })/** */}
                    <Text variant='large'>Äußere Einflüsse</Text>
                     {["Kälte, Zugluft, Nässe","Hitze", "starke-Staubentwicklung", "Rauch","starker-Laerm","Lärmschutz-muss-getragen-werden","hautreizende-Stoffe","Gase-Gerüche-Dämpfe","atemwegsreizende-Stoffe","überwiegend-witterungsgeschützt"].map(key=>
                        <div key={key}>
                        <ChoiceGroup
                            label={key.replace('-',' ').replace('-',' ').replace('ä','ae').replace('ü','ue')}
                            selectedKey={this.props.data![key]}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!(key as  keyof Required<DataFormProps>['data'] , value!.key)}
                            className={styles.field}
                        />
                        {this.props.data!["hautreizende-Stoffe"] === 'Ja' && key==="hautreizende-Stoffe" &&
                        <TextField
                            label="Folgende:"
                            value={this.props.data!['hautreizende-Stoffe-Text']}
                            onChange={(e) => this.props.onChange!('hautreizende-Stoffe-Text', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data!["Gase-Gerüche-Dämpfe"] === 'Ja' && key==="Gase-Gerüche-Dämpfe" &&
                        <TextField
                            label="Folgende:"
                            value={this.props.data!['Gase-Gerüche-Dämpfe-Text']}
                            onChange={(e) => this.props.onChange!('Gase-Gerüche-Dämpfe-Text', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }
                        {this.props.data!["atemwegsreizende-Stoffe"] === 'Ja' && key==="atemwegsreizende-Stoffe" &&
                        <TextField
                            label="Folgende:"
                            value={this.props.data!['atemwegsreizende-Stoffe-Text']}
                            onChange={(e) => this.props.onChange!('atemwegsreizende-Stoffe-Text', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        }                        
                        </div>)}

                    </PivotItem>
                    <PivotItem
                        headerText="Krankenkasse"
                    >
                        <TextField
                            label="Name"
                            value={this.props.data!.healthInsuranceName}
                            onChange={(e) => this.props.onChange!('healthInsuranceName', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <Label>Adresse</Label>
                        <TextField
                            value={this.props.data!.healthInsuranceAddressLine1}
                            onChange={(e) => this.props.onChange!('healthInsuranceAddressLine1', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="Straße + Hausnummer"
                        />
                        <TextField
                            value={this.props.data!.healthInsuranceAddressLine2}
                            onChange={(e) => this.props.onChange!('healthInsuranceAddressLine2', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="Adresszusatz"
                        />
                        <TextField
                            value={this.props.data!.healthInsuranceAddressPLZ}
                            onChange={(e) => this.props.onChange!('healthInsuranceAddressPLZ', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="PLZ"
                        />
                        <TextField
                            value={this.props.data!.healthInsuranceAddressCity}
                            onChange={(e) => this.props.onChange!('healthInsuranceAddressCity', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="Stadt"
                        />
                        <TextField
                            label="Telefon"
                            value={this.props.data!.healthInsurancePhoneNumber}
                            onChange={(e) => this.props.onChange!('healthInsurancePhoneNumber', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <ChoiceGroup 
                            label="Art der Krankenkasse" 
                            selectedKey={this.props.data!.healthInsuranceType} 
                            options={[
                                {
                                    key: 'Gesetzliche Krankenkasse',
                                    text: 'Gesetzliche Krankenkasse',
                                },
                                {
                                    key: 'Private Krankenversicherung',
                                    text: 'Private Krankenversicherung',
                                }
                            ]} 
                            onChange={(e, value) => this.props.onChange!('healthInsuranceType', value!.key)} 
                        />
                    </PivotItem>
                    <PivotItem
                        headerText="Ärztin/Arzt"
                    >
                        <TextField
                            label="Name"
                            value={this.props.data!.doctorName}
                            onChange={(e) => this.props.onChange!('doctorName', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Vorname"
                            value={this.props.data!.doctorForename}
                            onChange={(e) => this.props.onChange!('doctorForename', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="Titel"
                            value={this.props.data!.doctorTitle}
                            onChange={(e) => this.props.onChange!('doctorTitle', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <Label>Adresse</Label>
                        <TextField
                            value={this.props.data!.doctorAddressLine1}
                            onChange={(e) => this.props.onChange!('doctorAddressLine1', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="Straße + Hausnummer"
                        />
                        <TextField
                            value={this.props.data!.doctorAddressLine2}
                            onChange={(e) => this.props.onChange!('doctorAddressLine2', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="Adresszusatz"
                        />
                        <TextField
                            value={this.props.data!.doctorAddressPLZ}
                            onChange={(e) => this.props.onChange!('doctorAddressPLZ', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="PLZ"
                        />
                        <TextField
                            value={this.props.data!.doctorAddressCity}
                            onChange={(e) => this.props.onChange!('doctorAddressCity', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                            placeholder="Stadt"
                        />
                        <TextField
                            label="Telefon"
                            value={this.props.data!.doctorPhoneNumber}
                            onChange={(e) => this.props.onChange!('doctorPhoneNumber', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <TextField
                            label="E-Mail-Adresse"
                            value={this.props.data!.doctorEmail}
                            onChange={(e) => this.props.onChange!('doctorEmail', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                        <div><Text variant='large'>Arztbehanlungen in den letzten 12 Monten</Text></div>
                        {this.props.data!.Doctors?.map((doctor, index) =><div key={index}  className={styles.doctor}>
                            <div><Text variant='mediumPlus'>Arzt {index+1}</Text></div>
                            
                            <TextField
                                label="Name, Vorname, Anschrift"
                                value={this.props.data!.Doctors![index].nameAndLocation}
                                onChange={(e) => {
                                    let doctors = this.props.data!.Doctors || [];
                                    doctors[index].nameAndLocation =  (e.target as HTMLInputElement).value;
                                    this.props.onChange!('Doctors', doctors);
                                }}
                                className={styles.field}
                            />
                            <TextField
                                label="Fachrichtung"
                                value={this.props.data!.Doctors![index].specialization}
                                onChange={(e) => {
                                    let doctors = this.props.data!.Doctors || [];
                                    doctors[index].specialization =  (e.target as HTMLInputElement).value;
                                    this.props.onChange!('Doctors', doctors);
                                }}
                                className={styles.field}
                            />
                            <TextField
                                label="Erkrankung"
                                value={this.props.data!.Doctors![index].illness}
                                onChange={(e) => {
                                    let doctors = this.props.data!.Doctors || [];
                                    doctors[index].illness =  (e.target as HTMLInputElement).value;
                                    this.props.onChange!('Doctors', doctors);
                                }}
                                className={styles.field}
                            />
                            <DefaultButton text="Arzt entfernen" iconProps={{iconName:"Delete"}} onClick={(e)=>{
                                let doctors = this.props.data!.Doctors || [];
                                doctors.splice(index,1);
                                this.props.onChange!('Doctors', doctors);
    
                            }}/>
                        </div>)}
                        <DefaultButton text="Hinzufügen" onClick={(e)=>{
                            let doctors = this.props.data!.Doctors || [];
                            doctors.push( {
                                nameAndLocation:'',
                                specialization:'',
                                illness:''
                            }) 
                            this.props.onChange!('Doctors', doctors);

                        }}/>
                    </PivotItem>
                    <PivotItem
                        headerText="Sonstiges"
                    >
                                                <TextField
                            label="Rententräger"
                            value={this.props.data!.pensionHolder}
                            onChange={(e) => this.props.onChange!('pensionHolder', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Rentenversicherungsnummer"
                            value={this.props.data!.pensionNumber}
                            onChange={(e) => this.props.onChange!('pensionNumber', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Haben sie schon mal im Ausland gearbeitet und dort Steuern gezahlt?"
                            value={this.props.data!.foreignCountry}
                            onChange={(e) => this.props.onChange!('foreignCountry', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />

                        <h2>Angaben zu Antragen </h2>

                        <ChoiceGroup
                            label="Haben Sie aktuell einen Antrag auf Leistungen zur Teilhabe bei einem anderen Leistungsträger gestellt
                            (zum Beispiel Agentur für Arbeit, Berufsgenossenschaft)?"
                            selectedKey={this.props.data!.furtherRequest}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('furtherRequest', value!.key)}
                            className={styles.field}
                        />
                            {this.props.data!.furtherRequest === 'Ja' &&
                            <TextField
                                label="Name und Anschrift des Leistungsträgers:"
                                value={this.props.data!.furtherRequestText}
                                onChange={(e) => this.props.onChange!('furtherRequestText', (e.target as HTMLInputElement).value)}
                                className={styles.field}
                            />
                            }
                        <ChoiceGroup
                            label="Beziehen Sie aktuell Leistungen zur Teilhabe (zum Beispiel Leistungen zur sozialen Teilhabe, Leistungen
                                an Bildung) von einem Träger der Eingliederungshilfe (bis 31.12.2019 Träger der Sozialhilfe / Sozialamt)?"
                            selectedKey={this.props.data!.furtherRequest2}
                            options={[
                                {
                                    key: "Ja",
                                    text: "Ja"
                                },
                                {
                                    key: "Nein",
                                    text: "Nein"
                                }
                            ]}
                            onChange={(e, value) => this.props.onChange!('furtherRequest2', value!.key)}
                            className={styles.field}
                        />
                            {this.props.data!.furtherRequest2 === 'Ja' &&
                            <TextField
                                label="Name und Anschrift des Leistungsträgers:"
                                value={this.props.data!.furtherRequest2Text}
                                onChange={(e) => this.props.onChange!('furtherRequest2Text', (e.target as HTMLInputElement).value)}
                                className={styles.field}
                            />}
                        <h2>Angaben zur Gesundheit</h2>

                        <TextField
                            label="Hat in letzter Zeit die Krankenkasse den Medizinischen Dienst für Sie beauftragt?"
                            value={this.props.data!.mdk}
                            onChange={(e) => this.props.onChange!('mdk', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Nehmen Sie an betriebsärztlichen Untersuchungen teil?"
                            value={this.props.data!.investigation}
                            onChange={(e) => this.props.onChange!('investigation', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Waren Sie in den letzten 6 Monaten ernsthaft erkrankt?"
                            value={this.props.data!.disease}
                            onChange={(e) => this.props.onChange!('disease', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Haben Sie einen anerkannten Schwerbehinderungsgrad?"
                            value={this.props.data!.handicap}
                            onChange={(e) => this.props.onChange!('handicap', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />

                        <h2>Angaben zur schul. Bildung</h2>
                        <TextField
                            label="Schulbildung"
                            id={'education'}
                            defaultValue={Prefillinput(this.props.data!,this.props.data!.education,'education')!}
                            onChange={(e) => this.props.onChange!('education', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Berufsausbildung"
                            value={Prefillinput(this.props.data!,this.props.data!.vocationalTraining,'vocationalTraining')!}
                            onChange={(e) => this.props.onChange!('vocationalTraining', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Studium"
                            value={this.props.data!.study}
                            onChange={(e) => this.props.onChange!('study', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />

                        <TextField
                            label="Umschulung"
                            value={Prefillinput(this.props.data!,this.props.data!.retraining,'retraining')!}
                            onChange={(e) => this.props.onChange!('retraining', (e.target as HTMLInputElement).value)}
                            className={styles.field}
                        />
                    </PivotItem>
                </Pivot>

                <div className={styles.footer}>
                    <PrimaryButton id={'forward'} text={'Weiter'} onClick={() => this.active()}  />
                </div>
            </form>
        )
    }

    _selectItem(){
        const {id} = this.props.router.params;
        const items = this.props.items;

        if(typeof id !== 'undefined'){
            let selectedItem = items.filter(item => item.id === parseInt(id));
            if(selectedItem.length > 0){
                this.setState({
                    new: false,
                    item: selectedItem[0]
                });
            }
        }
    }

    /**
     * Init Button effect for Privot Tab Button
     */
    active() {
        let id;
        let next;
        let last;
        let AllButton = (document.getElementById('tablist')!.childNodes[0] as HTMLElement).querySelectorAll('button')
        for(let x=0;x<AllButton.length;x++) {
            if (AllButton[x].classList.contains('is-selected')) {
                id = AllButton[x].getAttribute('id')!.split('-')[0]
                next = x+1;
                if (AllButton[x].getAttribute('name') === 'Sonstiges') {
                    last = true;
                } else {
                    last = false;
                }

            } else {

            }
        }
        if(last !== true) {
            document.getElementById(id + "-Tab" + next)!.click()
        } else {
            //this.props.onSave()
            this.props.onSave(this.state.item)
        }
    }

    /**
     * Build Button text for Privot Tabs
     */
    checkactive() {

        localStorage.setItem('education','')

        let AllButton = (document.getElementById('tablist')!.childNodes[0] as HTMLElement).querySelectorAll('button')

        for(let x=0;x<AllButton.length;x++){
            if(AllButton[x].getAttribute('name') === 'Sonstiges') {
                AllButton[x].addEventListener('click',  function() {
                    document.getElementById('forward')!.innerHTML = 'übernehmen';
                });
            }

            else if(AllButton[x].getAttribute('name') === 'Ausbildung') {
                AllButton[x].addEventListener('click',  function() {
                    Prefillinput('','','');
                    document.getElementById('forward')!.innerHTML = 'Weiter'});
            }
            else {
                AllButton[x].addEventListener('click',  function() {
                    document.getElementById('forward')!.innerHTML = 'Weiter'});

            }
        }
    }
}

export default DataForm;

/**
 * Gleich die Eingabe aus Reiter Ausbildung ab und befüllt damit Reiter sonstiges vor
 * @param {object} input
 * @param {string} target
 * @param {string} value
 * @returns {string}
 * @constructor
 */
const Prefillinput = (input: any, target: string, value: string) => {


    if(target !== '') {
        switch (value) {
            case 'education':
                if(target !== '' && localStorage.getItem('education') === '') {
                    localStorage.setItem('education',target);
                } else {
                    target = localStorage.getItem('education')!;
                }

                break;
            case 'vocationalTraining':
                if (document.getElementById('vocationalTraining') !== undefined &&
                    document.getElementById('vocationalTraining') !== null) {
                    target = document.getElementById('vocationalTraining')!.getAttribute('value')!;
                }
                break;
            case 'retraining':
                if (document.getElementById('retraining') !== undefined &&
                    document.getElementById('retraining') !== null) {
                    target = document.getElementById('retraining')!.getAttribute('value')!;
                }
                break;
            default:
        }


        return target;
    }
    return '';
}


const StringtoBool = (string:any) => {
    if(string === '1') {
        return true;
    } else {
        return false;
    }
}
