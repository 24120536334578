import React, { Component } from 'react';
import { TextField, Label, TagPicker, TagItem, Text, DefaultButton, PrimaryButton, Dropdown, Checkbox, DatePicker, Dialog, DialogFooter } from '@fluentui/react';
import Config from '../../../core/Config';
import Helper from '../../../core/Helper';
import styles from '../css/Recipe.module.css';
import { AppState, UserType } from '../../../App';


type RecipeProps = Readonly <{
    items: AppState['recipes'],
    config: typeof Config,
    user: UserType,
    users: AppState['users'],
    settings: AppState['settings'],
    onSave: (item: any) => void,
    router: {
        location: any,
        navigate: any,
        params: any
    }
}>
interface RecipeState {
    item: {
        user: string,
        length: number,
        key: string,
        location: string | number,
        operationID: string,
        product: string,
        amount: number,
        orderDate: string,
        deliveryDate: string,
        rework: Array<{
            date: string,
            type: 'create-recipe' | 'set-status-delivered' | 'set-status-closed' | 'notice' | 'rework',
            id?: boolean,
            status?: string,
            content: {
                text?: string,
                reworkType?: string,
                date?: string
            }
        }>,
        contactAllowed: boolean,
        status: 'new' | 'changed' | 'delivered' | 'closed',
    },
    reference: any,
    new: boolean,
    inputHelper: false | number,
    referenceValue: string,
}


class Recipe extends Component<RecipeProps, RecipeState> {

    props: RecipeProps;
    state: RecipeState = {
        new: true,
        item: {
            user: '',
            location: '',
            length: 0,
            key: '',
            operationID: '',
            product: '',
            amount: 1,
            orderDate: '',
            deliveryDate: '',
            rework: [{
                date: new Date().toISOString(),
                type: 'create-recipe',
                content: {
                    text: 'Das Rezept wurde angelegt.'
                }
            }],
            contactAllowed: true,
            status: 'new',
        },
        inputHelper: false,
        reference: null,
        referenceValue: ''
    };

    componentDidMount(){
        this._selectItem();
    }

    componentDidUpdate(prevProps: any) {
        let {id} = this.props.router.params;
        if(id !== prevProps.router.params.id || this.props.items !== prevProps.items){
            this._selectItem();
        }
    }

    render() {
        const config = this.props.config;
        const admin = ['system', 'admin', 'employee'].indexOf(this.props.user.usergroup) !== -1;
        const { user, location, operationID, product, amount, orderDate, deliveryDate, rework, contactAllowed, status } = this.state.item;
        const invalidInput = (config.recipes.operationID.required === true && operationID === '') || user === '' || location === '' || product === '' || orderDate === '';

        let userOptions: any[] = [];
        this.props.users.forEach( item => {
            userOptions.push({
                text: item.lastname + ', ' + item.firstname,
                key: item.id,
                fullItem: item
            });
        });

        let locationOptions = [
            {
                key: 0,
                text: 'keine Filiale'
            }
        ];
        config.locationOptions.forEach( (item: any) => {
            locationOptions.push(item);
        });

        const selectedUsers = userOptions.filter( (option: {key: string}) => option.key === user);

        const references = this.props.settings.references;
        const selectedReference = references?.filter((item: any) => item.key === this.state.reference)[0];


        return(
            <form className={styles.root}>
                <h1>{this.state.new === true ? 'Neues Rezept' : 'Rezept bearbeiten'}</h1>
                <TextField
                    name="operationID"
                    label={config.recipes.operationID.label}
                    value={operationID}
                    onChange={(e) => this.handleTextFieldChange(e)}
                    className={styles.field}
                    disabled={!admin}
                />

                <div className={styles.field}>
                    <Label>Kunde</Label>
                    <TagPicker
                        onResolveSuggestions={(filterText, tagList)=> {
                            let rt = filterText.length 
                            ? userOptions.filter( (tag: {text: string, id: number}) => tag.text.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 || tag.id === parseInt(filterText,10) )
                            : userOptions;
                            return rt;
                        }}
                        // @ts-ignore
                        onRenderItem={ tag => <TagItem {...tag} ><Text key={tag.key}>{tag.item.text}</Text></TagItem>}
                        // @ts-ignore
                        onRenderSuggestionsItem={ item => <span style={{display:'block', padding:'5px', textAlign:'left'}}>{item.text}<br /><span style={{color: '#aeaeae'}}>{locationOptions.filter(location => location.key === item.fullItem.location)[0].text + (item.fullItem.birthday !== '' ? ', ' + new Date(item.fullItem.birthday).toLocaleDateString() : '')}</span></span>}
                        pickerSuggestionsProps={{
                            suggestionsHeaderText: 'Vorgeschlagene Kunden',
                            noResultsFoundText: 'Keine Kunden gefunden'
                        }}
                        inputProps={{placeholder:'Kunde auswählen'}}
                        //items={userOptions}
                        defaultSelectedItems={userOptions}
                        selectedItems={selectedUsers}
                        itemLimit={1}
                        onChange={(items) => this.handleTagPickerChange(items)}
                        disabled={!admin}
                    />
                </div>

                <Dropdown
                    placeholder="Filiale wählen"
                    label="Filiale"
                    options={config.locationOptions}
                    selectedKey={location}
                    onChange={(e, item) => this.handleDropdownChange(e, item, 'location')}
                    className={styles.field}
                    disabled={!(this.props.settings.flexibleLocation === 'true') && this.props.user.location !== 0}
                />

                <div className={styles.fieldGroup}>
                    <TextField
                        name="amount"
                        label="Menge"
                        value={amount?.toString()}
                        type="number"
                        onChange={(e) => this.handleTextFieldChange(e)}
                        className={styles.field}
                        disabled={!admin}
                    />
                    <Dropdown
                        placeholder="Produkt wählen"
                        label="Produkt"
                        options={config.productOptions}
                        selectedKey={product}
                        onChange={(e, item) => this.handleDropdownChange(e, item, 'product')}
                        className={styles.field}
                        disabled={!admin}
                    />
                </div>

                <DatePicker
                    className={styles.field}
                    label="Auftragsdatum"
                    allowTextInput={true}
                    firstDayOfWeek={1}
                    strings={Helper.datePickerStrings}
                    onSelectDate={(date) => this.handleDateChange('orderDate', date)}
                    formatDate={(date) => date!.toLocaleDateString()}
                    parseDateFromString={(string) => Helper.parseDateFromString(string)}
                    value={orderDate !== '' ? new Date(orderDate) : undefined}
                    disabled={!admin}
                />

                {deliveryDate !== '' &&
                <DatePicker
                    className={styles.field}
                    label="Abholdatum"
                    allowTextInput={true}
                    firstDayOfWeek={1}
                    strings={Helper.datePickerStrings}
                    onSelectDate={(date) => this.handleDateChange('deliveryDate', date)}
                    formatDate={(date) => date!.toLocaleDateString()}
                    parseDateFromString={(string) => Helper.parseDateFromString(string)}
                    value={deliveryDate !== '' ? new Date(deliveryDate) : undefined}
                    disabled={!admin}
                />
                }

                <TextField
                    name="status"
                    label="Status"
                    value={config.recipes.statusOptions.filter((option: any) => option.key === status)[0]?.text}
                    className={styles.field}
                    disabled={true}
                />


                <div className={styles.group}>
                    <h2>Verlauf</h2>
                    {rework?.map((reworkItem, index) => {
                        const result = config.reworkTypeOptions.filter((reworkType: any) => reworkType.key === parseInt(reworkItem.content?.reworkType!));
                        const reworkText = result.length > 0 ? result[0].text : reworkItem.content?.reworkType;

                        return(
                        <div key={index}>
                            <p>Peseo System: {new Date(reworkItem.date).toLocaleString()}</p>
                            {reworkItem.type === 'notice' &&
                                (!(reworkItem.id) ?
                                    <>
                                        <TextField
                                            name="notice"
                                            label="Notiz"
                                            value={reworkItem.content?.text}
                                            onChange={(e) => this.handleReworkChange(index, 'text', (e.target as HTMLInputElement).value)}
                                            className={styles.field}
                                            multiline={true}
                                            disabled={!admin}
                                        />
                                        {Array.isArray(references) && <DefaultButton onClick={() => this.setState({inputHelper: index})}>Referenznummer einfügen</DefaultButton>}
                                    </> :
                                    <p><strong>Notiz:</strong> <span className={styles.multilineText} dangerouslySetInnerHTML={{__html: reworkItem.content?.text!}}></span></p>
                            )}

                            {['set-status-closed','set-status-delivered','create-recipe'].indexOf(reworkItem.type) !== -1 &&
                                <p><strong className={styles.multilineText} dangerouslySetInnerHTML={{__html: reworkItem.content?.text!}}></strong></p>
                            }

                            {reworkItem.type === 'rework' &&
                            <>
                                {!(reworkItem.id) ?
                                <>
                                    <Dropdown
                                        placeholder="Kategorie wählen"
                                        label="Welches Problem ist aufgetreten?"
                                        options={config.reworkTypeOptions}
                                        selectedKey={reworkItem.content?.reworkType}
                                        onChange={(e, item) => this.handleReworkChange(index, 'reworkType', item!.key)}
                                        className={styles.field}
                                        disabled={!admin}
                                    />
                                    <DatePicker
                                        className={styles.field}
                                        label="Wann ist das Problem aufgetreten?"
                                        allowTextInput={true}
                                        firstDayOfWeek={1}
                                        strings={Helper.datePickerStrings}
                                        onSelectDate={(date) => this.handleReworkChange(index, 'date', date !== null && typeof date !== 'undefined'? Helper.parseSQLStringFromDate(date) : '')}
                                        formatDate={(date) => date!.toLocaleDateString()}
                                        parseDateFromString={(string) => Helper.parseDateFromString(string)}
                                        value={reworkItem.content?.date !== '' ? new Date(reworkItem.content?.date!) : undefined}
                                        disabled={!admin}
                                    />
                                    <TextField
                                        name="description"
                                        label="Anmerkungen"
                                        value={reworkItem.content?.text}
                                        onChange={(e) => this.handleReworkChange(index, 'text', (e.target as HTMLInputElement).value)}
                                        className={styles.field}
                                        multiline={true}
                                        disabled={!admin}
                                    />
                                </> :
                                <>
                                    <p><strong>Reklamation:</strong> {reworkText} ({new Date(reworkItem.content?.date!).toLocaleDateString()})</p>
                                    {reworkItem.content?.text !== '' && <p><em className={styles.multilineText} dangerouslySetInnerHTML={{__html: reworkItem.content?.text!}}></em></p>}
                                </>
                                }
                            </>
                            }

                            <hr />
                        </div>
                        )
                    })}

                    <div className={styles.historyFooter}>
                        <DefaultButton text="Notiz hinzufügen" onClick={e => this.addRework('notice')} />
                        {orderDate !== '' && deliveryDate === '' && <DefaultButton text="Ausliefern" onClick={e => this.addRework('set-status-delivered')} /> }
                        {deliveryDate !== '' && <DefaultButton text="Reklamation hinzufügen" onClick={e => this.addRework('rework')} /> }
                        {deliveryDate !== '' && status !== 'closed' && <DefaultButton text="Beenden" onClick={e => this.addRework('set-status-closed')} /> }
                    </div>
                </div>

                <div className={styles.group}>
                    <Checkbox label="Befragungen aktiv" checked={contactAllowed} onChange={(e) => this.handleCheckboxChange('contactAllowed')} />
                </div>

                <div className={styles.footer}>
                    <PrimaryButton text="Speichern" onClick={(e) => this.handleSaveClick()} disabled={invalidInput} />
                    <DefaultButton text="Abbrechen" onClick={(e) => this.props.router.navigate(-1)} />
                </div>

                <Dialog hidden={this.state.inputHelper === false} onDismiss={() => this.setState({inputHelper: false})} title="Referenznummer wählen">
                    <Dropdown
                        label="Nummerntyp"
                        options={references}
                        selectedKey={this.state.reference}
                        onChange={(e, item) => this.setState({reference: item!.key})}
                        className={styles.field}
                    />
                    <table>
                        <tbody>
                            <tr><td><TextField label="Prefix" value={selectedReference?.prefix} disabled={true} /></td><td><TextField label="Nummer" value={this.state.referenceValue} onChange={e => this.setState({referenceValue: (e.target as HTMLInputElement).value})} /></td><td><TextField label="Sufix" value={selectedReference?.sufix} disabled={true} /></td></tr>
                        </tbody>
                    </table>
                    
                    <DialogFooter>
                      <PrimaryButton onClick={() => {
                            if (this.state.inputHelper !== false){
                                const copy = {...this.state.item};
                                copy.rework[this.state.inputHelper].content['text'] = copy.rework[this.state.inputHelper].content['text'] + ' ' + selectedReference?.prefix + this.state.referenceValue + selectedReference?.sufix;
                                copy.rework[this.state.inputHelper].status = 'changed';
                                this.setState({inputHelper: false, reference: null, referenceValue: '', item: copy})
                      }}} text="Einfügen" disabled={this.state.reference === null} />
                    </DialogFooter>
                </Dialog>
            </form>
        )
    }

    _selectItem(){
        const {id} = this.props.router.params;
        const items = this.props.items;

        if(typeof id !== 'undefined'){
            let selectedItem = items.filter(item => item.id === parseInt(id));
            if(selectedItem.length > 0){
                this.setState({
                    new: false,
                    item: selectedItem[0]
                });
            } else {
                const copy = {...this.state.item};
                const now = new Date();
                copy.orderDate =  now.toISOString().substr(0,10);
                this.setState({ item: copy });
            }
        }
    }

    handleTextFieldChange(e: any){
        const copy: any = {...this.state.item};
        copy[e.target.name] = e.target.value;
        this.setState({ item: copy });
    }

    handleTagPickerChange(items: any){
        const copy = {...this.state.item};
        if (items.length === 0){
            copy.user = '';
            copy.location = '';
        } else {
            copy.user = items[0].key;
            if(this.props.user.location !== 0){
                copy.location = this.props.user.location;
            }
        }
        this.setState({ item: copy });
    }

    handleDropdownChange(e: any, item: any, name: string){
        const copy: any = {...this.state.item};
        copy[name] = item.key;
        this.setState({ item: copy });
    }

    handleDateChange(target: any, value: any){
        const copy: any = {...this.state.item};
        copy[target] = value !== null ? Helper.parseSQLStringFromDate(value) : '';
        this.setState({ item: copy });
    }

    handleReworkChange(index: number, target: string, value: any){
        const copy: any = {...this.state.item};
        copy.rework[index].content[target] = value;
        copy.rework[index].status = 'changed';
        this.setState({ item: copy });
    }

    addRework(type: 'set-status-delivered' | 'set-status-closed' | 'notice' | 'rework'){
        const copy = {...this.state.item};
        let newItem = {type: type, date: new Date().toISOString(), content: {text: '', reworkType: '', date: ''}};

        if(type === 'set-status-delivered'){
            newItem.content.text = 'Das Produkt wurde ausgeliefert.';
            copy.deliveryDate = new Date().toISOString().substr(0,10);
            copy.status = 'delivered';
        }

        if(type === 'set-status-closed'){
            newItem.content.text = 'Das Produkt wird nicht mehr genutzt.';
            copy.status = 'closed';
        }

        if(type === 'notice'){
            newItem.content.text = '';
        }

        if(type === 'rework'){
            newItem.content = {reworkType: '', date: new Date().toISOString().substr(0,10), text: ''};
        }

        copy.rework.push(newItem);

        this.setState({ item: copy });
    }

    handleCheckboxChange(name: string){
        const copy: any = {...this.state.item};
        copy[name] = !copy[name];
        this.setState({ item: copy });
    }

    handleSaveClick(){
        this.props.onSave(this.state.item);
        this.props.router.navigate(-1);
    }
}

export default Recipe;