import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Label, TextField, Dropdown, DefaultButton, PrimaryButton, Checkbox, ChoiceGroup, DatePicker } from '@fluentui/react';
import validator from 'validator';
import Helper from '../../../core/Helper';
import styles from '../css/Registration.module.css';
import Config from '../../../core/Config';
import { AppState } from '../../../App';

type RegistrationProps = Readonly <{
    config: typeof Config,
    activeLocation: AppState['filiale'],
    onSave: (item: any) => void,
    router: {
        location: any,
        navigate: any,
        params: any
    }
}>

interface RegistrationState {
    passwordCheck: string,
    page: number,
    postal: number | string,
    user: {
        title: string,
        firstname: string,
        lastname: string,
        email: string,
        password: string,
        usergroup: string,
        regularCustomer: string,
        location: number | string,
        phone: string,
        mobile: string,
        birthday: string,
        description: string,
        street: string,
        addition: string,
        zip: string,
        city: string,
        country: string,
        healthInsurance: string,
        healthInsuranceID: string,
        careLevel: string,
        consents: any,
        consentsChanged: boolean,
        requirements: Array<{
                regular: string,
                age: string,
                size: string,
                interest: any[],
                bereich: any[],
                profile: string,
                complaints: string,
                complaintsDetails: string,
                surgical: string,
                surgicalDetails: string,
                allergies: string,
                allergiesDetails: string,
                sweat: string,
                sweatDetails: string,
                insoles: string,
                insolesDetails: string,
                diabetes: string,
                diabetesDetails: string,
                change: string,
                feature: any[],
                move: string,
                flength: string,
                fwidth: string,
                fextent: string,
                innerSize: string,
                weight: string,
                satisfaction: string,
                extended: string,
                status?: any,
        }>
    }
}


class Registration extends Component<RegistrationProps, RegistrationState> {

    state: RegistrationState;
    props: RegistrationProps;

    constructor(props: RegistrationProps){
        super(props);
        this.props = props;

        const consents: any = {};
        this.props.config.consentBoxes.forEach((consent: any) => {
            consents[consent.key] = consent.defaultValue;
        });

        this.state = {
            user: {
                title: '',
                firstname: '',
                lastname: '',
                email: '',
                password: '',
                usergroup: 'users',
                regularCustomer: '',
                location: this.props.activeLocation ? typeof this.props.activeLocation.user.location === 'string'? parseInt(this.props.activeLocation.user.location, 10): this.props.activeLocation.user.location : '',
                phone: '',
                mobile: '',
                birthday: '',
                description: '',
                street: '',
                addition: '',
                zip: '',
                city: '',
                country: '',
                healthInsurance: '',
                healthInsuranceID: '',
                careLevel: '',
                consents: consents,
                consentsChanged: false,
                requirements: [
                    {
                        regular: '',
                        age: '',
                        size: '',
                        interest: [],
                        bereich: [],
                        profile: '',
                        complaints: '',
                        complaintsDetails: '',
                        surgical: '',
                        surgicalDetails: '',
                        allergies: '',
                        allergiesDetails: '',
                        sweat: '',
                        sweatDetails: '',
                        insoles: '',
                        insolesDetails: '',
                        diabetes: '',
                        diabetesDetails: '',
                        change: '',
                        feature: [],
                        move: '',
                        flength: '',
                        fwidth: '',
                        fextent: '',
                        innerSize: '',
                        weight: '',
                        satisfaction: '',
                        extended: '',
                    },
                ],
            },
            passwordCheck: '',
            postal: '',
            page: 1,
        };
    }

    render() {
        const config = this.props.config;
        const {title, firstname, lastname, email, password, regularCustomer, location, phone, mobile, birthday, description, street, addition, zip, city, country, healthInsurance, healthInsuranceID, careLevel, consents, requirements} = this.state.user;
        const {regular, age, size, interest, bereich, profile, complaints, complaintsDetails, surgical, surgicalDetails, allergies, allergiesDetails, sweat, sweatDetails, insoles, insolesDetails, diabetes, diabetesDetails, change, feature, move, flength, fwidth, fextent, innerSize, weight, satisfaction, extended} = requirements[requirements.length-1];
        const invalidInput = title === '' || firstname === '' || lastname === '' || (email !== '' && (!validator.isEmail(email) || password.length < 8 || password !== this.state.passwordCheck)) || birthday === '';

        let checkedConsents = true;
        config.consentBoxes.forEach((box: any) => {
            if(box.required === true ){
                checkedConsents = checkedConsents && consents[box.key];
            }
        });

        const interestOptions = [
            {
                key: '1',
                text: 'Gesundes Gehen / Geh-Seminare'
            },
            {
                key: '2',
                text: 'MBT-Schuhe'
            },
            {
                key: '3',
                text: 'Schuhreparaturen'
            },
            {
                key: '4',
                text: 'Schuheinlagen'
            },
            {
                key: '5',
                text: 'Orthopädische Schuhe und Schuhzurichtungen'
            },
            {
                key: '6',
                text: 'maßgefertigte Schuhe'
            },
            {
                key: '7',
                text: 'Diabetiker-Schutzschuhe'
            }
        ];

        const bereichOptions = [
            {
                key: '1',
                text: 'zu Hause'
            },{
                key: '2',
                text: 'Arbeit',
            },{
                key: '3',
                text: 'Freizeit',
            }
        ];

        const featureOptions = [
            {
                key: '1',
                text: 'Optik'
            },{
                key: '2',
                text: 'Bequemlichkeit'
            },{
                key: '3',
                text: 'Preis'
            },{
                key: '4',
                text: 'Gesundheit'
            },{
                key: '5',
                text: 'Einfaches an- und ausziehen'
            }
        ];

        const moveOptions = [
            {
                key: '1',
                text: 'stehe viel'
            },{
                key: '2',
                text: 'gehe viel'
            },{
                key: '3',
                text: 'sitze viel'
            }
        ];

        return(
            <>
            {location !== ''
            ? <div className={styles.root}>
                {this.state.page === 1 &&
                <form>
                    <h1>Anforderungsermittlung</h1>
                    <p>Bitte helfen Sie uns, Ihnen die besten Leistungen und Lösungen bieten zu können. Füllen Sie bitte dafür Ihr Fußprofil aus! „Wenn ich nicht weiß, wie ich stehe, woher will ich Wissen, wie ich in Zukunft gehe?</p>

                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: '1',
                                text: 'nein'
                            },{
                                key: '2',
                                text: 'ja im Bereich Schuheinlagen'
                            },{
                                key: '3',
                                text: 'ja im Bereich orthopädische Schuhe'
                            },{
                                key: '4',
                                text: 'ja im Bereich Podologie'
                            },{
                                key: '5',
                                text: 'ja im Bereich Schuhreparaturen'
                            },{
                                key: '6',
                                text: 'ja im Bereich Sonstiges'
                            }
                        ]} 
                        selectedKey={regular} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'regular', value!.key)} 
                        label="Sind Sie oder waren sie bereits Kunde/Patient von OSZL?" 
                    />

                    {regular !== '' &&
                    <TextField 
                        className={styles.group} 
                        value={age} 
                        onChange={(e) => this.handleRequirementChange(0, 'age', (e.target as HTMLInputElement).value)} 
                        label="Welches Alter haben Sie?"
                        suffix="Jahre"
                        type="number"
                    />
                    }

                    {age !== '' &&
                    <TextField 
                        className={styles.group} 
                        value={size} 
                        onChange={(e) => this.handleRequirementChange(0, 'size', (e.target as HTMLInputElement).value)} 
                        label="Welche Schuhgröße tragen Sie? (EU-Größen)"
                        type="number"
                    />
                    }

                    {size !== '' &&
                    <div className={styles.group}>
                        <Label>Wofür interessieren Sie sich? (mehrere Antworten möglich)</Label>
                        {interestOptions.map(item => 
                            <Checkbox 
                                key={item.key} 
                                label={item.text} 
                                checked={interest.indexOf(item.key) >= 0} 
                                onChange={e => this.handleRequirementChange(0, 'interest', item.key)} 
                            />
                        )}
                    </div>
                    }

                    {interest.length > 0 &&
                    <div className={styles.group}>
                        <Label>Für was benötigen Sie gesundes Schuhwerk bzw. Schuheinlagen? (mehrere Antworten möglich)</Label>
                        {bereichOptions.map(item => 
                            <Checkbox 
                                key={item.key} 
                                label={item.text} 
                                checked={bereich.indexOf(item.key) >= 0} 
                                onChange={e => this.handleRequirementChange(0, 'bereich', item.key)} 
                            />
                        )}
                    </div>
                    }

                    {bereich.length > 0 &&
                    <>
                        <p>Wir empfehlen ein individuelles Fußprofil zu erstellen. Folgende Gründe sprechen dafür:</p>
                        <ol>
                            <li>aktueller Status Ihrer Füße</li>
                            <li>langfristig gesundes Gehen</li>
                            <li>unabhängiges Gehen (frei von Schmerzen/Hilfsmitteln/Medikamenten</li>
                        </ol>
                        <ChoiceGroup 
                            className={styles.group} 
                            options={[
                                {
                                    key: 'Nein',
                                    text: 'Nein'
                                },{
                                    key: 'Ja',
                                    text: 'Ja'
                                }
                            ]} 
                            selectedKey={profile} 
                            onChange={(e, value) => this.handleRequirementChange(0, 'profile', value!.key)} 
                            label="Wünschen Sie ein individuelles Fußprofil? Planen Sie ca. 15 Minuten ein " 
                        />
                    </>
                    }

                    {profile === 'Ja' &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: 'Nein',
                                text: 'Nein'
                            },{
                                key: 'Ja',
                                text: 'Ja'
                            }
                        ]} 
                        selectedKey={complaints} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'complaints', value!.key)} 
                        label="Haben / hatten Sie jemals Beschwerden am Fuß?" 
                    />
                    }

                    {complaints === 'Ja' &&
                    <TextField 
                        className={styles.group} 
                        value={complaintsDetails} 
                        onChange={(e) => this.handleRequirementChange(0, 'complaintsDetails', (e.target as HTMLInputElement).value)} 
                        label="Welche Beschwerden haben / hatten Sie?"
                    />
                    }

                    {complaints !== '' &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: 'Nein',
                                text: 'Nein'
                            },{
                                key: 'Ja',
                                text: 'Ja'
                            }
                        ]} 
                        selectedKey={surgical} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'surgical', value!.key)} 
                        label="Hatten Sie eine OP am Fuß, Knie, Hüfte oder Rücken?" 
                    />
                    }

                    {surgical === 'Ja' &&
                    <TextField 
                        className={styles.group} 
                        value={surgicalDetails} 
                        onChange={(e) => this.handleRequirementChange(0, 'surgicalDetails', (e.target as HTMLInputElement).value)} 
                        label="Was für eine OP hatten Sie?"
                    />
                    }

                    {surgical !== '' &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: 'Nein',
                                text: 'Nein'
                            },{
                                key: 'Ja',
                                text: 'Ja'
                            }
                        ]} 
                        selectedKey={allergies} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'allergies', value!.key)} 
                        label="Haben Sie Allergien auf bestimmtes Material?" 
                    />
                    }

                    {allergies === 'Ja' &&
                    <TextField 
                        className={styles.group} 
                        value={allergiesDetails} 
                        onChange={(e) => this.handleRequirementChange(0, 'allergiesDetails', (e.target as HTMLInputElement).value)} 
                        label="Worauf sind Sie allergisch?"
                    />
                    }

                    {allergies !== '' &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: 'Nein',
                                text: 'Nein'
                            },{
                                key: 'Ja',
                                text: 'Ja'
                            }
                        ]} 
                        selectedKey={sweat} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'sweat', value!.key)} 
                        label="Schwitzen Ihre Füße?" 
                    />
                    }

                    {sweat === 'Ja' &&
                    <TextField 
                        className={styles.group} 
                        value={sweatDetails} 
                        onChange={(e) => this.handleRequirementChange(0, 'sweatDetails', (e.target as HTMLInputElement).value)} 
                        label="Wie stark schwitzen Sie?"
                    />
                    }

                    {sweat !== '' &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: 'Nein',
                                text: 'Nein'
                            },{
                                key: 'Ja',
                                text: 'Ja'
                            }
                        ]} 
                        selectedKey={insoles} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'insoles', value!.key)} 
                        label="Tragen Sie Schuheinlagen?" 
                    />
                    }

                    {insoles === 'Ja' &&
                    <TextField 
                        className={styles.group} 
                        value={insolesDetails} 
                        onChange={(e) => this.handleRequirementChange(0, 'insolesDetails', (e.target as HTMLInputElement).value)} 
                        label="Seit wann tragen Sie Einlagen?"
                    />
                    }

                    {insoles !== '' &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: 'Nein',
                                text: 'Nein'
                            },{
                                key: 'Ja',
                                text: 'Ja'
                            }
                        ]} 
                        selectedKey={diabetes} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'diabetes', value!.key)} 
                        label="Leiden Sie unter Diabetes / Gicht / Rheuma / sonstige Fuß- oder Gehkrankheiten?" 
                    />
                    }

                    {diabetes === 'Ja' &&
                    <TextField 
                        className={styles.group} 
                        value={diabetesDetails} 
                        onChange={(e) => this.handleRequirementChange(0, 'diabetesDetails', (e.target as HTMLInputElement).value)} 
                        label="Beschreiben Sie bitte kurz Ihre Erkrankung."
                    />
                    }

                    {diabetes !== '' &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: '1',
                                text: 'Täglich'
                            },{
                                key: '2',
                                text: '2 bis 3 mal die Woche'
                            },{
                                key: '3',
                                text: 'weniger als 2 mal die Woche'
                            },{
                                key: '4',
                                text: 'nie'
                            }
                        ]} 
                        selectedKey={change} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'change', value!.key)} 
                        label="Wechseln Sie oft Ihre Schuhe?" 
                    />
                    }

                    {change !== '' &&
                    <div className={styles.group}>
                        <Label>Welches sind für Sie die zwei wichtigsten Kriterien beim Schuhkauf?</Label>
                        {featureOptions.map(item => 
                            <Checkbox 
                                key={item.key} 
                                label={item.text} 
                                checked={feature.indexOf(item.key) >= 0} 
                                onChange={e => this.handleRequirementChange(0, 'feature', item.key)} 
                            />
                        )}
                    </div>
                    }

                    {feature.length > 0 &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={moveOptions} 
                        selectedKey={move} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'move', value!.key)} 
                        label="Bewegung täglich?" 
                    />
                    }

                    {move !== '' &&
                    <TextField 
                        className={styles.group} 
                        value={flength} 
                        onChange={(e) => this.handleRequirementChange(0, 'flength', (e.target as HTMLInputElement).value)} 
                        label="Gemessene Fußlänge"
                        suffix="cm"
                        type="number"
                    />
                    }

                    {flength !== '' &&
                    <TextField 
                        className={styles.group} 
                        value={fwidth} 
                        onChange={(e) => this.handleRequirementChange(0, 'fwidth', (e.target as HTMLInputElement).value)} 
                        label="Gemessene Fußbreite"
                        suffix="cm"
                        type="number"
                    />
                    }

                    {fwidth !== '' &&
                    <TextField 
                        className={styles.group} 
                        value={fextent} 
                        onChange={(e) => this.handleRequirementChange(0, 'fextent', (e.target as HTMLInputElement).value)} 
                        label="Gemessener Fußumfang"
                        suffix="cm"
                        type="number"
                    />
                    }

                    {fextent !== '' &&
                    <TextField 
                        className={styles.group} 
                        value={innerSize} 
                        onChange={(e) => this.handleRequirementChange(0, 'innerSize', (e.target as HTMLInputElement).value)} 
                        label="Gemessene Innenschuhgröße"
                        suffix="cm"
                        type="number"
                    />
                    }

                    {innerSize !== '' &&
                    <TextField 
                        className={styles.group} 
                        value={weight} 
                        onChange={(e) => this.handleRequirementChange(0, 'weight', (e.target as HTMLInputElement).value)} 
                        label="Welches Körpergewicht haben Sie?"
                        suffix="kg"
                        type="number"
                    />
                    }

                    {weight !== '' &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: 'Nein',
                                text: 'Nein'
                            },{
                                key: 'Ja',
                                text: 'Ja'
                            }
                        ]} 
                        selectedKey={satisfaction} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'satisfaction', value!.key)} 
                        label="Waren Sie mit der Erstberatung durch unsere Fußlotsin zufrieden?" 
                    />
                    }

                    {satisfaction !== '' &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: 'Nein',
                                text: 'Nein'
                            },{
                                key: 'Ja',
                                text: 'Ja'
                            }
                        ]} 
                        selectedKey={extended} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'extended', value!.key)} 
                        label="Wünschen Sie ein ausführliches Gespräch mit unserem Orthopädieschuhmacher / Podologin zu Ihrer aktuellen Fuß- und Gehsituation auf Basis Ihres Fußprofiles?" 
                    />
                    }

                    {(extended !== '' || profile === 'Nein') &&
                    <div className={styles.footer}>
                        <PrimaryButton text="Weiter &raquo;" onClick={() => this.setState({ page: 2 }, () => window.scrollTo(0 ,0))} />
                        <DefaultButton text="Vorgang abbrechen" onClick={() => this.handleCancelClick()} />
                    </div>
                    }
                </form>
                }

                {this.state.page === 2 &&
                <form>
                    <h1>Angaben zu Ihrer Person</h1>
                    <Dropdown
                        label="Anrede"
                        options={config.titleOptions}
                        selectedKey={title}
                        onChange={(e, item) => this.handleDropdownChange(e, item, 'title')}
                        className={styles.field}
                    />
                    <TextField
                        name="firstname"
                        label="Vorname"
                        value={firstname}
                        onChange={(e) => this.handleTextFieldChange(e)}
                        onGetErrorMessage={string => string !== '' ? '' : 'Diese Angabe ist verpflichtend.'}
                        validateOnFocusOut={true}
                        validateOnLoad={false}
                        className={styles.field}
                    />
                    <TextField
                        name="lastname"
                        label="Nachname"
                        value={lastname}
                        onChange={(e) => this.handleTextFieldChange(e)}
                        onGetErrorMessage={string => string !== '' ? '' : 'Diese Angabe ist verpflichtend.'}
                        validateOnFocusOut={true}
                        validateOnLoad={false}
                        className={styles.field}
                    />
                    <TextField
                        name="email"
                        label="E-Mail (falls vorhanden)" 
                        description="wird nur zu Ihrer Qualitätsbefragung genutzt" 
                        value={email}
                        type="email"
                        onChange={(e) => this.handleTextFieldChange(e)}
                        onGetErrorMessage={string => string === '' || validator.isEmail(string) ? '' : 'E-Mail-Adresse ist nicht gültig.'}
                        validateOnFocusOut={true}
                        validateOnLoad={false}
                        className={styles.field}
                    />
                    <TextField
                        name="mobile" 
                        label="Mobilnummer" 
                        value={mobile} 
                        onChange={(e) => this.handleTextFieldChange(e)}
                        className={styles.field}
                    />
                    <TextField
                        name="phone" 
                        label="Festnetz (falls Mobil nicht vorhanden)" 
                        value={phone} 
                        onChange={(e) => this.handleTextFieldChange(e)}
                        className={styles.field}
                    />
                    <DatePicker
                        className={styles.field}
                        label="Geburtsdatum"
                        allowTextInput={true}
                        firstDayOfWeek={1}
                        strings={Helper.datePickerStrings}
                        onSelectDate={(date) => this.handleDateChange('birthday', date)}
                        formatDate={(date) => date!.toLocaleDateString()}
                        parseDateFromString={(string) => Helper.parseDateFromString(string)}
                        value={birthday !== '' ? new Date(birthday) : undefined}
                    />
                    <TextField
                        name="description"
                        label="Anmerkungen"
                        value={description}
                        multiline={true}
                        placeholder={config.users?.description?.placeholder || "z. B. bevorzugte Kontaktzeiten"}
                        onChange={(e) => this.handleTextFieldChange(e)}
                        className={styles.field}
                    />

                    {config.fields.address !== 'hidden' &&
                    <>
                        <h2>Anschrift</h2>
                        <TextField
                            name="street"
                            label="Straße"
                            value={street}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <TextField
                            name="addition"
                            label="Adresszusatz"
                            value={addition}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <div className={styles.fieldGroup}>
                            <TextField
                                name="zip"
                                label="PLZ"
                                value={zip}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                            <TextField
                                name="city"
                                label="Ort"
                                value={city}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                        </div>
                        <TextField
                            name="country"
                            label="Land"
                            value={country}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                    </>
                    }

                    {config.fields.health !== 'hidden' &&
                    <>
                        <h2>Krankenkasse</h2>
                        <TextField
                            name="healthInsurance"
                            label="Name der Krankenkasse"
                            value={healthInsurance}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <TextField
                            name="healthInsuranceID"
                            label="Versichertennummer"
                            value={healthInsuranceID}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <TextField
                            name="careLevel"
                            label="Pflegegrad"
                            value={careLevel}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                    </>
                    }

                    {email !== '' &&
                    <>
                        <h2>Bitte vergeben Sie ein Passwort.</h2>
                        <TextField 
                            name="password" 
                            label="Passwort" 
                            value={password} 
                            type="password" 
                            onChange={(e) => this.handleTextFieldChange(e)} 
                            errorMessage={password.length >= 8 ? '' : 'Passwort muss mindestens 8 Zeichen lang sein.'} 
                            className={styles.field} 
                            autoComplete="off" 
                        />
                        {password !== '' &&
                        <TextField 
                            name="passwordCheck" 
                            label="Passwort (wiederholen)" 
                            value={this.state.passwordCheck} 
                            type="password" 
                            onChange={(e) => this.setState({passwordCheck: (e.target as HTMLInputElement).value})} 
                            errorMessage={this.state.passwordCheck === password ? '' : 'Passwörter stimmen nicht überein.'} 
                            className={styles.field} 
                            autoComplete="off" 
                        />
                        }
                    </>
                    }

                    <div className={styles.footer}>
                        <PrimaryButton text="Weiter &raquo;" onClick={() => {
                            if(email !== '' && (phone !== '' || mobile !== '')){
                                this.setState({ page: 5 }, () => window.scrollTo(0 ,0));
                            } else {
                                this.setState({ page: 3 }, () => window.scrollTo(0 ,0));
                            }
                        }} disabled={invalidInput} />
                        <DefaultButton text="Zurück" onClick={() => this.setState({ page: 1 }, () => window.scrollTo(0 ,0))} />
                        <DefaultButton text="Vorgang abbrechen" onClick={() => this.handleCancelClick()} />
                    </div>
                </form>
                }

                {this.state.page === 3 &&
                <form>
                    <h1>Fehlende Angaben</h1>
                    <p>Damit Sie an der Qualitätsbefragung teilnehmen können, benötigen wir Ihre E-Mail-Adresse sowie eine Festnetznummer oder Mobiltelefonnummer.</p>

                    <TextField 
                        name="email" 
                        label="E-Mail (falls vorhanden)" 
                        description="wird nur zu Ihrer Qualitätsbefragung genutzt" 
                        value={email} 
                        type="email" 
                        onChange={(e) => this.handleTextFieldChange(e)} 
                        onGetErrorMessage={string => string === '' || validator.isEmail(string) ? '' : 'E-Mail-Adresse ist nicht gültig.'} 
                        validateOnFocusOut={true} 
                        validateOnLoad={false} 
                        className={styles.field} 
                    />
                    <TextField 
                        name="mobile" 
                        label="Mobilnummer" 
                        value={mobile} 
                        onChange={(e) => this.handleTextFieldChange(e)} 
                        className={styles.field} 
                    />
                    <TextField 
                        name="phone" 
                        label="Festnetz (falls Mobil nicht vorhanden)" 
                        value={phone} 
                        onChange={(e) => this.handleTextFieldChange(e)} 
                        className={styles.field} 
                    />

                    {email !== '' &&
                    <>
                        <h2>Bitte vergeben Sie ein Passwort.</h2>
                        <TextField
                            name="password"
                            label="Passwort"
                            value={password}
                            type="password"
                            onChange={(e) => this.handleTextFieldChange(e)}
                            errorMessage={password.length >= 8 ? '' : 'Passwort muss mindestens 8 Zeichen lang sein.'}
                            className={styles.field}
                            autoComplete="off"
                        />
                        {password !== '' &&
                        <TextField
                            name="passwordCheck"
                            label="Passwort (wiederholen)"
                            value={this.state.passwordCheck}
                            type="password"
                            onChange={(e) => this.setState({passwordCheck: (e.target as HTMLInputElement).value})}
                            errorMessage={this.state.passwordCheck === password ? '' : 'Passwörter stimmen nicht überein.'}
                            className={styles.field}
                            autoComplete="off"
                        />
                        }
                    </>
                    }

                    <div className={styles.footer}>
                        <PrimaryButton text="Weiter &raquo;" onClick={() => {
                            if(email !== ''){
                                this.setState({ page: 5 }, () => window.scrollTo(0 ,0))
                            } else {
                                this.setState({ page: 4 }, () => window.scrollTo(0 ,0))
                            }
                        }} disabled={invalidInput} />
                        <DefaultButton text="Zurück" onClick={() => this.setState({ page: 2 }, () => window.scrollTo(0 ,0))} />
                        <DefaultButton text="Vorgang abbrechen" onClick={() => this.handleCancelClick()} />
                    </div>
                </form>
                }

                {this.state.page === 4 &&
                <form>
                    <h1>Fehlende Angaben</h1>
                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: 'yes',
                                text: 'ja'
                            },
                            {
                                key: 'no',
                                text: 'Nein, ich möchte nicht an der Qualitätsbefragung teilnehmen.'
                            },
                        ]} 
                        selectedKey={this.state.postal} 
                        onChange={(e, value) => this.setState({postal: value!.key})} 
                        label="Ich möchte ausschließlich postalisch kontaktiert werden" 
                    />

                    <div className={styles.footer}>
                        <PrimaryButton text="Weiter &raquo;" onClick={() => this.setState({ page: 5 }, () => window.scrollTo(0 ,0))} disabled={this.state.postal === ''} />
                        <DefaultButton text="Zurück" onClick={() => this.setState({ page: 3 }, () => window.scrollTo(0 ,0))} />
                        <DefaultButton text="Vorgang abbrechen" onClick={() => this.handleCancelClick()} />
                    </div>
                </form>
                }

                {this.state.page === 5 &&
                <div>
                    <h1>Zusammenfassung</h1>
                    <p>Bevor Sie mit der Registrierung fortfahren, überprüfen Sie bitte Ihre Angaben!</p>

                    <div className={styles.group}>
                        <h3>Ihre Daten</h3>
                        <table className={styles.zfg}>
                            <tbody>
                                <tr><td>Name</td><td>{title} {firstname} {lastname}</td></tr>
                                <tr><td>Geburtsdatum</td><td>{birthday}</td></tr>
                                <tr><td>E-Mail</td><td>{email}</td></tr>
                                <tr><td>Handy</td><td>{mobile}</td></tr>
                                <tr><td>Telefon</td><td>{phone}</td></tr>

                                {config.fields.address !== 'hidden' &&
                                <tr><td>Adresse</td><td>{street}, {addition}, {zip} {city}</td></tr>
                                }

                                {config.fields.health !== 'hidden' &&
                                <>
                                    <tr><td>Krankenkasse</td><td>{healthInsurance}</td></tr>
                                    <tr><td>Versichertennummer</td><td>{healthInsuranceID}</td></tr>
                                    <tr><td>Pflegegrad</td><td>{careLevel}</td></tr>
                                </>
                                }
                            </tbody>
                        </table>

                        <h3>Anmerkungen</h3>
                        <p>{description !== '' ? description : '---'}</p>

                        <h3>Stammkunde</h3>
                        <p>{regularCustomer ? 'Ja' : 'Nein'}</p>

                        <h3>Alter</h3>
                        <p>{age}</p>

                        <h3>Schuhgröße</h3>
                        <p>{size}</p>

                        <h3>Wofür interessieren Sie sich?</h3>
                        <ul>
                            {interestOptions.filter(item => interest.indexOf(item.key) >= 0).map(item => 
                                <li key={item.key}>{item.text}</li>
                            )}
                        </ul>

                        {interest.length > 0 &&
                        <>
                            <h3>Für was benötigen Sie gesundes Schuhwerk bzw. Schuheinlagen?</h3>
                            <ul>
                                {bereichOptions.filter(item => bereich.indexOf(item.key) >= 0).map(item => 
                                    <li key={item.key}>{item.text}</li>
                                )}
                            </ul>
                        </>
                        }

                        {profile === 'Ja' &&
                        <>
                            <h3>Haben / hatten Sie jemals Beschwerden am Fuß?</h3>
                            <p>{complaints} ({complaintsDetails})</p>
                        </>
                        }

                        {complaints !== '' &&
                        <>
                            <h3>Hatten Sie eine OP am Fuß, Knie, Hüfte oder Rücken?</h3>
                            <p>{surgical} ({surgicalDetails})</p>
                        </>
                        }

                        {surgical !== '' &&
                        <>
                            <h3>Haben Sie Allergien auf bestimmtes Material?</h3>
                            <p>{allergies} ({allergiesDetails})</p>
                        </>
                        }

                        {allergies !== '' &&
                        <>
                            <h3>Schwitzen Ihre Füße?</h3>
                            <p>{sweat} ({sweatDetails})</p>
                        </>
                        }

                        {sweat !== '' &&
                        <>
                            <h3>Tragen Sie Schuheinlagen?</h3>
                            <p>{insoles} ({insolesDetails})</p>
                        </>
                        }

                        {insoles !== '' &&
                        <>
                            <h3>Leiden Sie unter Diabetes / Gicht / Rheuma / Sonstige Fuß- Gehkrankheiten?</h3>
                            <p>{diabetes} ({diabetesDetails})</p>
                        </>
                        }

                        {diabetes !== '' &&
                        <>
                            <h3>Wechseln Sie oft Ihre Schuhe?</h3>
                            <p>{change}</p>
                        </>
                        }

                        {diabetes !== '' &&
                        <>
                            <h3>Worauf würden Sie in Zukunft beim Kauf von Schuhen verstärkt achten?</h3>
                            <ul>
                                {featureOptions.filter(item => feature.indexOf(item.key) >= 0).map(item => 
                                    <li key={item.key}>{item.text}</li>
                                )}
                            </ul>
                        </>
                        }

                        {feature.length > 0 &&
                        <>
                            <h3>Bewegung täglich?</h3>
                            <p>{moveOptions.filter(item => item.key === move)[0]?.text}</p>
                        </>
                        }

                        {move !== '' &&
                        <>
                            <h3>Gemessene Fußlänge</h3>
                            <p>{flength} cm</p>
                        </>
                        }

                        {flength !== '' &&
                        <>
                            <h3>Gemessene Fußbreite</h3>
                            <p>{fwidth} cm</p>
                        </>
                        }

                        {fwidth !== '' &&
                        <>
                            <h3>Gemessener Fußumfang</h3>
                            <p>{fextent} cm</p>
                        </>
                        }

                        {fextent !== '' &&
                        <>
                            <h3>Gemessene Innenschuhgröße</h3>
                            <p>{innerSize} cm</p>
                        </>
                        }

                        {innerSize !== '' &&
                        <>
                            <h3>Welches Körpergewicht haben Sie?</h3>
                            <p>{weight} kg</p>
                        </>
                        }

                        {weight !== '' &&
                        <>
                            <h3>Waren Sie mit der Beratung durch unsere Fußlotsin zufrieden?</h3>
                            <p>{satisfaction}</p>
                        </>
                        }

                        {satisfaction !== '' &&
                        <>
                            <h3>Wünschen Sie ein ausführliches Gespräch mit unserem Orthopädieschuhmacher / Podologin zu Ihrer aktuellen Fuß- und Gehsituation auf Basis Ihres Fußprofiles?</h3>
                            <p>{extended}</p>
                        </>
                        }
                    </div>

                    {config.consentBoxes.length > 0 &&
                    <div className={styles.group}>
                        <h2>Einverständniserklärung</h2>
                        {config.consentDescription !== '' && <p dangerouslySetInnerHTML={{__html: config.consentDescription}}></p> }

                        {config.consentBoxes.map((consent: any) => <Checkbox key={consent.key} label={consent.text} checked={consents[consent.key]} onChange={() => this.handleConsentChange(consent.key)} /> )}
                    </div>
                    }

                    <div className={styles.footer}>
                        <PrimaryButton text="Registrierung abschließen &raquo;" onClick={() => this.handleSaveClick()} disabled={!checkedConsents} />
                        <DefaultButton text="Angaben ändern" onClick={() => this.setState({ page: 1 }, () => window.scrollTo(0 ,0))} />
                        <DefaultButton text="Vorgang abbrechen" onClick={() => this.handleCancelClick()} />
                    </div>
                </div>
                }
            </div>
            : <Navigate to="/" />
            }
            </>
        )
    }

    handleChoiceGroupChange(target: string, value: any){
        const copy: any = {...this.state.user};
        copy[target] = value;
        this.setState({ user: copy });
    }

    handleTextFieldChange(e: any){
        const copy: any = {...this.state.user};
        copy[e.target.name] = e.target.value;
        this.setState({ user: copy });
    }

    handleDropdownChange(e: any, item: any, name: string){
        const copy: any = {...this.state.user};
        copy[name] = item.key;
        this.setState({ user: copy });
    }

    handleDateChange(target: string, value: any){
        const copy: any = {...this.state.user};
        copy[target] = value !== null ? Helper.parseSQLStringFromDate(value) : '';
        this.setState({ user: copy });
    }

    handleConsentChange(name: any){
        const copy = {...this.state.user};
        copy.consents[name] = !copy.consents[name];
        copy.consentsChanged = true;
        this.setState({ user: copy });
    }

    handleRequirementChange(index: number, target: keyof RegistrationState['user']['requirements'][number], value: any){
        const copy = {...this.state.user};

        if(Array.isArray(copy.requirements[index][target])){
            const item = copy.requirements[index][target].indexOf(value);
            if( item >= 0){
                copy.requirements[index][target].splice(item, 1);
            } else {
                copy.requirements[index][target].push(value);
            }
        } else {
            copy.requirements[index][target] = value;
        }

        copy.requirements[index].status = 'changed';
        this.setState({ user: copy });
    }

    handleSaveClick(){
        const copy = {...this.state.user};
        const tmp = copy.requirements[0];
        delete tmp.status;
        copy.requirements.splice(0, 1, {...tmp});
        this.props.onSave(copy);
        this.props.router.navigate(-1);
    }

    handleCancelClick(){
        this.props.router.navigate(-1);
    }
}

export default Registration;