import React, { Component } from 'react';
import { TextField, Label, TagPicker, TagItem, Text, DefaultButton, PrimaryButton, Dropdown } from '@fluentui/react';

import styles from '../css/Ticket.module.css';
import Config from '../../../core/Config';
import { AppState, UserType } from '../../../App';

export interface TicketInterface{
    location?: any,
    id?: string | number,
    customer: string,
    subject: string,
    status: string,
    history: Array<{
            id?: string,
            type: string, 
            employee: TicketProps['user']['id'], 
            date: string, 
            content: string
    }>,  
}

type TicketProps = Readonly <{
    user: UserType,
    items: AppState['tickets'],
    config: typeof Config,
    users: AppState['users'],
    onSave: (item: any) => void,
    router: {
        location: any,
        navigate: any,
        params: any
    }
}>
interface TicketState {
    new: boolean,
    item: TicketInterface
}

class Ticket extends Component<TicketProps, TicketState> {

    state: TicketState;
    props: TicketProps;

    constructor(props: TicketProps){
        super(props);
        this.props = props;

        this.state = {
            new: true,
            item: {
                customer: '',
                subject: '',
                status: 'open',
                history: [
                    {
                        type: 'create-ticket', 
                        employee: this.props.user.id,
                        date: new Date().toISOString(), 
                        content: 'Ticket erstellt.'
                    }
                ],
            },
        };
    }
    

    componentDidMount(){
        this._selectItem();
    }

    componentDidUpdate(prevProps: any) {
        let {id} = this.props.router.params;
        if(id !== prevProps.router.params.id || this.props.items !== prevProps.items){
            this._selectItem();
        }
    }

    render() {
        const config = this.props.config;
        const { customer, subject, status, history } = this.state.item;
        const invalidInput = customer === '' || subject === '' || status === '';

        let userOptions: any[] = [];
        this.props.users.forEach( item => {
            userOptions.push({
                text: item.lastname + ', ' + item.firstname,
                key: item.id,
                fullItem: item
            });
        });

        let locationOptions = [
            {
                key: 0,
                text: 'keine Filiale'
            }
        ];
        config.locationOptions.forEach( (item: any) => {
            locationOptions.push(item);
        });

        const selectedUsers = userOptions.filter( option => option.key === customer);

        return(
            <form className={styles.root}>
                <h1>{this.state.new === true ? 'Neues Ticket' : 'Ticket'}</h1>
                <div className={styles.field}>
                    <Label>Kunde</Label>
                    <TagPicker
                        onResolveSuggestions={(filterText, tagList)=> {
                            let rt = filterText.length 
                            ? userOptions.filter( tag => tag.text.toLowerCase().indexOf(filterText.toLowerCase()) >= 0 || tag.id === parseInt(filterText,10) )
                            : userOptions;
                            return rt;
                        }}
                        // @ts-ignore
                        onRenderItem={ tag => <TagItem {...tag} ><Text key={tag.key}>{tag.item.text}</Text></TagItem>}
                        // @ts-ignore
                        onRenderSuggestionsItem={ item => <span style={{display:'block', padding:'5px', textAlign:'left'}}>{item.text}<br /><span style={{color: '#aeaeae'}}>{locationOptions.filter(location => location.key === item.fullItem.location)[0].text + (item.fullItem.birthday !== '' ? ', ' + new Date(item.fullItem.birthday).toLocaleDateString() : '')}</span></span>}
                        pickerSuggestionsProps={{
                            suggestionsHeaderText: 'Vorgeschlagene Kunden',
                            noResultsFoundText: 'Keine Kunden gefunden'
                        }}
                        inputProps={{placeholder:'Kunde auswählen'}}
                        //items={userOptions}
                        defaultSelectedItems={userOptions}
                        selectedItems={selectedUsers}
                        itemLimit={1}
                        onChange={(items) => this.handleTagPickerChange(items)}
                        disabled={!this.state.new}
                    />
                </div>

                {customer !== '' &&
                <div className={styles.infoBox}>
                    <p><strong>Kontaktdaten von {selectedUsers[0].fullItem.firstname} {selectedUsers[0].fullItem.lastname}</strong></p>
                    <table>
                        <tbody>
                            <tr><td>Mobilnummer: </td><td>{selectedUsers[0].fullItem.mobile}</td></tr>
                            <tr><td>Festnetznummer: </td><td>{selectedUsers[0].fullItem.phone}</td></tr>
                            <tr><td>E-Mail: </td><td>{selectedUsers[0].fullItem.email}</td></tr>
                        </tbody>
                    </table>
                    {selectedUsers[0].fullItem.description !== '' && <p>Anmerkungen: <em>{selectedUsers[0].fullItem.description}</em></p> }
                    <p><a href={'/users/' + selectedUsers[0].fullItem.id} rel={'noreferrer'} target="_blank">Kunde öffnen &raquo;</a></p>
                </div>
                }

                <Dropdown 
                    placeholder="Status wählen"
                    label="Status"
                    options={config.ticketStatusOptions}
                    selectedKey={status}
                    onChange={(e, item) => this.handleDropdownChange(e, item, 'status')}
                    className={styles.field}
                    disabled={true}
                />
                <TextField
                    name="subject"
                    label="Betreff"
                    value={subject}
                    onChange={(e) => this.handleTextFieldChange(e)}
                    className={styles.field}
                />

                <div className={styles.group}>
                    <h2>Verlauf</h2>
                    {history.map((historyItem, index) => {
                        const result = [...this.props.users].filter(user => user.id === historyItem.employee);
                        const employee = result.length > 0 ? result[0].firstname + ' ' + result[0].lastname : historyItem.employee;

                        return(
                        <div key={index}>
                            <p>{employee}: {new Date(historyItem.date).toLocaleString()}</p>
                            {!(historyItem.id) && historyItem.type === 'notice'
                            ? <TextField
                                    label="Notiz"
                                    value={historyItem.content}
                                    onChange={(e) => this.setHistory(index, 'content', (e.target as HTMLInputElement).value)}
                                    className={styles.field}
                                    multiline={true}
                                />
                            : <p><em dangerouslySetInnerHTML={{__html: historyItem.content}}></em></p>
                            }
                            <hr />
                        </div>
                        )
                    })}
                    {status === 'open' && <DefaultButton text="Notiz hinzufügen" onClick={e => this.addHistory('notice', '')} />}
                    {status !== 'open' && <DefaultButton text="Ticket öffnen" onClick={e => this.setStatus('open')} />}
                    {status !== 'closed' && <DefaultButton text="Ticket schließen" onClick={e => this.setStatus('closed')} />}
                </div>

                <div className={styles.footer}>
                    <PrimaryButton text="Speichern" onClick={(e) => this.handleSaveClick()} disabled={invalidInput} />
                    <DefaultButton text="Abbrechen" onClick={(e) => this.props.router.navigate(-1)} />
                </div>
            </form>
        )
    }

    _selectItem(){
        const {id} = this.props.router.params;
        const items = this.props.items;

        if(typeof id !== 'undefined'){
            let selectedItem = items.filter(item => item.id === parseInt(id));
            if(selectedItem.length > 0){
                this.setState({
                    new: false,
                    item: selectedItem[0]
                });
            }
        }
    }

    handleTagPickerChange(items: any){
        const copy = {...this.state.item};
        if (items.length === 0){
            copy.customer = '';
        } else {
            copy.customer = items[0].key;
        }
        this.setState({ item: copy });
    }

    handleTextFieldChange(e: any){
        const copy: any = {...this.state.item};
        copy[e.target.name] = e.target.value;
        this.setState({ item: copy });
    }

    handleDropdownChange(e: any, item: any, name: string){
        const copy: any = {...this.state.item};
        copy[name] = item.key;
        this.setState({ item: copy });
    }

    setStatus(value: string){
        const copy = {...this.state.item};
        copy.status = value;
        this.setState({ item: copy }, () => {
            if(value === 'open'){
                this.addHistory('set-status-open', 'Ticket geöffnet');
            }
            if(value === 'closed'){
                this.addHistory('set-status-closed', 'Ticket geschlossen')
            }
        });
    }

    setHistory(index: number, key: string, value: any){
        const copy: any = {...this.state.item};
        copy.history[index][key] = value;
        this.setState({ item: copy });
    }

    addHistory(type: any, content: any){
        const copy = {...this.state.item};
        copy.history.push({type: type, employee: this.props.user.id, date: new Date().toISOString(), content: content});
        this.setState({ item: copy });
    }

    handleSaveClick(){
        this.props.onSave(this.state.item);
        this.props.router.navigate(-1);
    }
}

export default Ticket;