import React, { Component } from 'react';
import { SearchBox, Pivot, PivotItem, DetailsList, Selection, SelectionMode, DefaultButton, Dropdown, IColumn } from '@fluentui/react';

import styles from '../css/Surveys.module.css';
import Config from '../../../core/Config';
import { AppState, ListSorter, UserType } from '../../../App';

type SurveysProps = Readonly <{
    config: typeof Config,
    user: UserType,
    ListSorter: ListSorter,
    onRefresh: () => void,
    items: AppState['surveys'],
    recipes: AppState['recipes'],
    users: AppState['users'],
    router: {
        location: any,
        navigate: any,
        params: any
    }
}>
interface SurveysState {
    sort: {
        field: string,
        desc?: boolean,
    },
    location: number | string,
    productType: number | string,
    pivot: typeof sessionStorage.mdrSurveysPivot | 'all',
    search: typeof sessionStorage.mdrSurveysSearch | '',
}
class Surveys extends Component<SurveysProps, SurveysState>{

    _selection: Selection;
    locationOptions: any[];
    productOptions: any[];
    state: SurveysState;
    props: SurveysProps;
    refreshInterval: number;

    constructor(props: SurveysProps){
        super(props);
        this.props = props;

        this._selection = new Selection({

            onSelectionChanged: () => {
                if(this._selection.getSelection().length > 0){
                    // @ts-ignore: Unreachable code error
                    this.props.router.navigate('/surveys/' + this._selection.getSelection()[0].id);
                }
            }
        })

        this.locationOptions = [...this.props.config.locationOptions];
        this.locationOptions.unshift({key: 0, text: 'Alle Filialen'});

        this.productOptions = [...this.props.config.productOptions];
        this.productOptions.unshift({key: 0, text: 'Alle Produkte'});

        this.state = {
            search: typeof sessionStorage.mdrSurveysSearch !== 'undefined' ? sessionStorage.mdrSurveysSearch :  '',
            pivot: typeof sessionStorage.mdrSurveysPivot !== 'undefined' ? sessionStorage.mdrSurveysPivot : 'all',
            location: typeof sessionStorage.mdrSurveysLocation !== 'undefined' ? parseInt(sessionStorage.mdrSurveysLocation) : this.props.user.location,
            productType: typeof sessionStorage.mdrSurveysProductType !== 'undefined' ? parseInt(sessionStorage.mdrSurveysProductType) : 0,
            sort:{field:'id', desc:false}
        }
    }
    onColumnHeaderClick=(e: any, column?: IColumn)=>{
        let feld = typeof(column!.fieldName) !== 'undefined' ? column!.fieldName : column!.name;
        let desc = this.state.sort.field === feld ? !this.state.sort.desc : false;
        this.setState({sort:{field:feld, desc:desc}}, ()=>this.props.ListSorter.storeSort(this.constructor.name, this.state.sort));
    }

    componentDidMount(){
        //this.props.onRefresh();
        this.refreshInterval = window.setInterval(() => this.props.onRefresh(), this.props.config.refreshInterval);
        //Daten Bei Remount wiederherstellen
        this.setState({
            search: typeof sessionStorage.mdrSurveysSearch !== 'undefined' ? sessionStorage.mdrSurveysSearch :  '',
            pivot: typeof sessionStorage.mdrSurveysPivot !== 'undefined' ? sessionStorage.mdrSurveysPivot : 'all',
            location: typeof sessionStorage.mdrSurveysLocation !== 'undefined' ? parseInt(sessionStorage.mdrSurveysLocation) : this.props.user.location,
            productType: typeof sessionStorage.mdrSurveysProductType !== 'undefined' ? parseInt(sessionStorage.mdrSurveysProductType) : 0,
            sort:{field:'id', desc:false}
        })
        if (typeof(this.props.ListSorter.getSort)==='function'){
            this.setState({sort: this.props.ListSorter.getSort(this.constructor.name,this.state.sort)})
        }
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }



    render() {
        const usergroup = this.props.user.usergroup;
        const priv = ['system', 'admin', 'employee'];
        let items = this.props.items;

        if(this.state.location !== 0){
            items = items.filter(item => item.location === this.state.location);
        }

        if(this.state.productType !== 0){
            items = items.filter(item => this.props.recipes.filter(recipe => recipe.id === item.recipe)[0]?.product === this.state.productType);
        }

        const countAll = items.length;
        const countNotFilled = items.filter(item => item.filled === '').length;
        const countFilled = items.filter(item => item.filled !== '').length;

        if(this.state.pivot === 'filled'){
            items = items.filter(item => item.filled !== '');
        }

        if(this.state.pivot === 'notFilled'){
            items = items.filter(item => item.filled === '');
        }

        if(this.state.search !== ''){
            const parts = this.state.search.toLowerCase().trim().split(' ');

            const filteredUsers = this.props.users.filter( item => {
                let found = false;
                let check = false;
                for (let x = 0; x < parts.length; x++) {
                    if (item.lastname.toLowerCase().indexOf(parts[x]) >= 0 || item.firstname.toLowerCase().indexOf(parts[x]) >= 0) {
                        check = true;
                    } else {
                        check = false;
                    }
                }
                found = check;
                return found;
            }).map( user => user.id);

            const filteredRecipes = items.filter( item => {
                let found = true;
                parts.forEach(part => found && item.type.toLowerCase().indexOf(part) >= 0 );
                return found;
            }).map( recipe => recipe.user);


            items = items.filter( item => filteredRecipes.indexOf(item.id) >= 0 || filteredUsers.indexOf(item.user) >= 0);

        }

        const columns = [
            {
                key: 'column0',
                name: 'ID',
                fieldName: 'id',
                minWidth: 30,
                maxWidth: 50,
                isResizable: true,
                isPadded: false,
            },
            {
                key: 'column1',
                name: 'Kunde',
                fieldName: 'user',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item: any) => {
                    if (this.props.users){
                        const copy = [...this.props.users];
                        const result = copy.filter(user => user.id === item.user);
                        if(result.length > 0){
                            return result[0].firstname + ' ' + result[0].lastname;
                        }
                    }
                    return item.user;
                },
            },
            {
                key: 'column2',
                name: 'Filiale',
                fieldName: 'location',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item: any) => {
                    const result = this.props.config.locationOptions.filter((location: any) => location.key === item.location );
                    if(result.length > 0 ){
                        return result[0].text;
                    }
                    return item.location;
                },
            },
            {
                key: 'column3',
                name: 'Fragebogen',
                fieldName: 'type',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Datum',
                fieldName: 'created',
                minWidth: 120,
                maxWidth: 200,
                isResizable: true,
                isPadded: true,
                onRender: (item: any) => {
                    const created = item.created !== '' ? new Date(item.created).toLocaleDateString() : '';
                    return created;
                },
            },
            {
                key: 'column5',
                name: 'Ausgefüllt am',
                fieldName: 'filled',
                minWidth: 0,
                isResizable: true,
                isPadded: true,
                onRender: (item: any) => {
                    const filled = item.filled !== '' ? new Date(item.filled).toLocaleDateString() : '';
                    return filled;
                },
            },
        ].map(column => {
            return this.props.ListSorter.setColumn(column, this.state.sort)
         });
         items = this.props.ListSorter.sortItems(items, columns,this.state.sort.field , this.state.sort.desc?'desc':'asc');

        return(
            <div className={priv.indexOf(usergroup) !== -1 ? styles.root : styles.rootUsers}>
                {priv.indexOf(usergroup) !== -1 &&
                <div className={styles.heading}>
                    <span className={styles.left}></span>
                    <Dropdown
                        options={this.productOptions}
                        selectedKey={this.state.productType}
                        onChange={(e, item) => this.setState({productType: item!.key}, (()=>sessionStorage.setItem('mdrSurveysProductType',  typeof item!.key === 'number'? item!.key.toString(): item!.key)))}
                        className={styles.filterDropdown}
                    />
                    <Dropdown
                        options={this.locationOptions}
                        selectedKey={this.state.location}
                        onChange={(e, item) => this.setState({location: item!.key}, (()=>sessionStorage.setItem('mdrSurveysLocation',  typeof item!.key === 'number'? item!.key.toString(): item!.key)))}
                        className={styles.filterDropdown}
                    />
                    <SearchBox className={styles.search} placeholder="Suche" value={this.state.search} onChange={e => {if(e){this.setState({search: e.target.value}, (()=>sessionStorage.setItem('mdrSurveysSearch', e.target.value)))}}} onClear={() => this.setState({search: ''})} />
                </div>
                }

                <div className={styles.content}>
                    <h1>Befragungsübersicht</h1>

                    <Pivot onLinkClick={(item) => this.setState({pivot: item!.props.itemKey}, (()=>sessionStorage.setItem('mdrSurveysPivot', item!.props.itemKey!)))} selectedKey={this.state.pivot}>
                        <PivotItem itemKey="all" headerText="alle Befragungen" itemCount={countAll}></PivotItem>
                        <PivotItem itemKey="filled" headerText="ausgefüllte Befragungen" itemCount={countFilled}></PivotItem>
                        <PivotItem itemKey="notFilled" headerText="offene Befragungen" itemCount={countNotFilled}></PivotItem>
                    </Pivot>

                    <DetailsList
                        items={items}
                        compact={true}
                        columns={columns}
                        selectionMode={SelectionMode.single}
                        layoutMode={1}
                        selection={this._selection}
                        onColumnHeaderClick={this.onColumnHeaderClick}
                    />

                    {this.props.user.usergroup === 'users' &&
                    <DefaultButton text="Zurück zur Übersicht" onClick={() => this.props.router.navigate(-1)} />
                    }
                </div>
            </div>
        )
    }
}

export default Surveys;
