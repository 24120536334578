import React from "react";
import { AppState } from "../../../App";
import styles from "../css/Page.module.css";

type AppStateConfig = Exclude<AppState['config'], null>

type PageProps = Readonly<{
    content: {
        __html: AppStateConfig['faq'] | AppStateConfig['privacyReduced'] | AppStateConfig['privacy'] | AppStateConfig['imprint']
    },
}>

const Page: React.FC<PageProps> = (props: PageProps) => {
    return <div className={styles.root} dangerouslySetInnerHTML={props.content}></div>;
};

export default Page;
