import React, { Component } from 'react';
import { TextField, DatePicker, PrimaryButton } from '@fluentui/react';

import Helper from '../../../core/Helper';
import styles from '../css/LoginForm.module.css';
import Config from '../../../core/Config';

type LoginFormProps = Readonly< {
    config: typeof Config,
    filiale: boolean,
    onLogin: (email: string, password: string, name: string, date: string) => void
}>

interface LoginFormState {
    account: boolean,
    email: string,
    password: string,
    name: string,
    date: string,
}


class LoginForm extends Component<LoginFormProps, LoginFormState> {

    props: LoginFormProps;
    state: LoginFormState = {
        account: true,
        email: '',
        password: '',
        name: '',
        date: '',
    };

    render() {
        return(
            <div className={styles.root + ' login-form-root'}>
                <img className={styles.logo} src="/data/logo.png" alt={this.props.config.company} />
                <form className={styles.form}>
                    <div className={styles.padBox}>
                        {this.props.filiale
                        ? <h2>Anmelden {this.state.account === true ? 'mit' : 'ohne'} E-Mail</h2>
                        : <h2>Anmelden</h2>
                        }
                        
                        {this.state.account === true &&
                        <>
                            <TextField
                                name="email" 
                                label="E-Mail"
                                value={this.state.email}
                                type="email"
                                onChange={(e) => this.setState({ email: (e.target as HTMLInputElement).value })}
                                className={styles.field}
                            />
                            <TextField
                                name="password" 
                                label="Passwort"
                                value={this.state.password}
                                type="password"
                                onChange={(e) => this.setState({ password: (e.target as HTMLInputElement).value })}
                                className={styles.field}
                            />
                        </>
                        }
                        {this.state.account === false &&
                        <>
                            <TextField
                                name="name" 
                                label="Nachname"
                                value={this.state.name}
                                onChange={(e) => this.setState({ name: (e.target as HTMLInputElement).value })}
                                className={styles.field}
                            />
                            <DatePicker
                                className={styles.field}
                                label="Geburtsdatum"
                                allowTextInput={true}
                                firstDayOfWeek={1}
                                strings={Helper.datePickerStrings}
                                onSelectDate={(date) => this.setState({ date: date !== null && typeof date !== 'undefined' ? Helper.parseSQLStringFromDate(date) : '' })}
                                formatDate={(date) => date!.toLocaleDateString()}
                                parseDateFromString={(string) => Helper.parseDateFromString(string)}
                                value={this.state.date !== '' ? new Date(this.state.date) : undefined}
                            />
                        </>
                        }
                        <PrimaryButton 
                            text="Anmelden" 
                            onClick={(e) => this.handleLoginClick(e)}
                        />
                    </div>
                </form>
                {this.props.filiale && 
                <div className={styles.footer}>
                    {this.state.account === true 
                    ? <p>Sie haben <strong>kein</strong> Konto? Klicken Sie <span onClick={ e => this.handleFooterClick(e, this.state.account)}>&raquo;hier</span>.</p>
                    : <p>Sie haben ein Konto? Klicken Sie <span onClick={ e => this.handleFooterClick(e, this.state.account)}>&raquo;hier</span>.</p>
                    }
                </div>
                }
                <div className={styles.footer + ' ' + styles.down}>
                    <a href="/datenschutz">Datenschutz</a> | <a href="/impressum">Impressum</a>
                </div>
            </div>
          )
    }

    handleLoginClick(e: any){
        this.props.onLogin(this.state.email, this.state.password, this.state.name, this.state.date);
    }

    handleFooterClick(e: any, account: boolean){
        if(account === true){
            this.setState({
                account: !account,
                email: '',
                password: ''
            });
        } else {
            this.setState({
                account: !account,
                name: '',
                date: ''
            });
        }
    }
}

export default LoginForm;