import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Label, TextField, Dropdown, DefaultButton, PrimaryButton, Checkbox, ChoiceGroup, DatePicker } from '@fluentui/react';
import validator from 'validator';

import Helper from '../../../core/Helper';
import styles from '../css/Registration.module.css';
import Config from '../../../core/Config';
import { AppState } from '../../../App';

type RegistrationProps = Readonly <{
    config: typeof Config,
    activeLocation: AppState['filiale'],
    onSave: (item: any) => void,
    router: {
        location: any,
        navigate: any,
        params: any
    }
}>
interface RegistrationState {
    passwordCheck: string,
    page: number,
    postal: number | string,
    user: {
        title: string,
        firstname: string,
        lastname: string,
        email: string,
        password: string,
        usergroup: 'users',
        regularCustomer: string,
        location: number | '',
        phone: string,
        mobile: string,
        birthday: string,
        description: string,
        street: string,
        addition: string,
        zip: string,
        city: string,
        country: string,
        healthInsurance: string,
        healthInsuranceID: string,
        careLevel: string,
        consents: any,
        consentsChanged: boolean,
        requirements: [
            {
                age: string,
                productCategories: any[],
                bereich: any[],
                beruf: string,
                berufSonstiges: string,
                freizeit: any[],
                freizeitSonstiges: string,
                schuhtypen: any[],
                schuhtypSonstiges: string,
                bootCount: string,
                compressionStocking: string,
                compressionStockingDressUp: string,
                compressionStockingSatisfaction: string,
                compressionStockingProblems: any[],
                status: string,
            },
        ],
    },
}


class Registration extends Component<RegistrationProps, RegistrationState> {

    state: RegistrationState;
    props: RegistrationProps;

    constructor(props: RegistrationProps){
        super(props);
        this.props = props;

        const consents: any = {};
        this.props.config.consentBoxes.forEach((consent: any) => {
            consents[consent.key] = consent.defaultValue;
        });

        this.state = {
            user: {
                title: '',
                firstname: '',
                lastname: '',
                email: '',
                password: '',
                usergroup: 'users',
                regularCustomer: '',
                location: this.props.activeLocation ? typeof this.props.activeLocation.user.location === 'string'? parseInt(this.props.activeLocation.user.location, 10): this.props.activeLocation.user.location :'',
                phone: '',
                mobile: '',
                birthday: '',
                description: '',
                street: '',
                addition: '',
                zip: '',
                city: '',
                country: '',
                healthInsurance: '',
                healthInsuranceID: '',
                careLevel: '',
                consents: consents,
                consentsChanged: false,
                requirements: [
                    {
                        age: '',
                        productCategories: [],
                        bereich: [],
                        beruf: '',
                        berufSonstiges: '',
                        freizeit: [],
                        freizeitSonstiges: '',
                        schuhtypen: [],
                        schuhtypSonstiges: '',
                        bootCount: '',
                        compressionStocking: '',
                        compressionStockingDressUp: '',
                        compressionStockingSatisfaction: '',
                        compressionStockingProblems: [],
                        status: ''
                    },
                ],
            },
            passwordCheck: '',
            postal: '',
            page: 1,
        };
    }

    render() {
        const config = this.props.config;
        const {title, firstname, lastname, email, password, regularCustomer, location, phone, mobile, birthday, description, street, addition, zip, city, country, healthInsurance, healthInsuranceID, careLevel, consents, requirements} = this.state.user;
        const {age, productCategories, bereich, beruf, berufSonstiges, freizeit, freizeitSonstiges, schuhtypen, schuhtypSonstiges, bootCount, compressionStocking, compressionStockingDressUp, compressionStockingSatisfaction, compressionStockingProblems} = requirements[requirements.length-1];
        const invalidInput = title === '' || firstname === '' || lastname === '' || (email !== '' && (!validator.isEmail(email) || password.length < 8 || password !== this.state.passwordCheck)) || birthday === '';

        let checkedConsents = true;
        config.consentBoxes.forEach((box: any) => {
            if(box.required === true ){
                checkedConsents = checkedConsents && consents[box.key];
            }
        });

        const regularCustomerOptions = [
            {
                key: 'Ja',
                text: 'Ja'
            },
            {
                key: 'Nein',
                text: 'Nein'
            }
        ];

        return(
            <>
            {location !== ''
            ? <div className={styles.root}>
                {this.state.page === 1 &&
                <form>
                    <h1>Anforderungsermittlung</h1>
                    <p>Bitte helfen Sie uns, Ihnen die besten Leistungen und Produkte bieten zu können. Füllen Sie bitte dafür diese kurze Anforderungsermittlung aus!</p>

                    <ChoiceGroup 
                        className={styles.group} 
                        options={regularCustomerOptions} 
                        selectedKey={regularCustomer} 
                        onChange={(e, value) => this.handleChoiceGroupChange('regularCustomer', value!.key)} 
                        label="Haben Sie bei uns schonmal ein Rezept eingelöst?" 
                    />

                    {regularCustomer !== '' &&
                    <ChoiceGroup 
                        className={styles.group + ' ' + styles.ages} 
                        options={config.ageOptions} 
                        selectedKey={age} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'age', value!.key)} 
                        label="Altersgruppe" 
                    />
                    }

                    {age !== '' &&
                    <div className={styles.group}>
                        <Label>Wofür interessieren Sie sich? (mehrere Antworten möglich)</Label>
                        {config.productOptions.map((item: any) => 
                            <Checkbox 
                                key={item.key} 
                                label={item.text} 
                                checked={productCategories.indexOf(item.key) >= 0} 
                                onChange={e => this.handleRequirementChange(0, 'productCategories', item.key)} 
                            />
                        )}
                    </div>
                    }

                    {(productCategories.indexOf(1) >= 0 || productCategories.indexOf(4) >= 0) &&
                    <div className={styles.group}>
                        <Label>Wofür benötigen Sie Ihre Schuhe? (mehrere Antworten möglich)</Label>
                        {config.bereichOptions.map((item: any) => 
                            <Checkbox 
                                key={item.key} 
                                label={item.text} 
                                checked={bereich.indexOf(item.key) >= 0} 
                                onChange={e => this.handleRequirementChange(0, 'bereich', item.key)} 
                            />
                        )}
                    </div>
                    }

                    {bereich.indexOf('Arbeit') >= 0 &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={config.berufOptions} 
                        selectedKey={beruf} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'beruf', value!.key)} 
                        label="Wie verbringen Sie Ihren Arbeitsalltag?" 
                    />
                    }

                    {beruf === 'Sonstiges' &&
                    <TextField 
                        className={styles.group} 
                        name="berufSonstiges" 
                        value={berufSonstiges} 
                        onChange={(e) => this.handleRequirementChange(0, 'berufSonstiges', (e.target as HTMLInputElement).value)} 
                        label="Wie sieht Ihre sonstige Tätigkeit aus?" 
                    />
                    }

                    {bereich.indexOf('Freizeit') >= 0 &&
                    <div className={styles.group}>
                        <Label>Wofür benötigen Sie Ihre Schuhe in der Freizeit? (mehrere Antworten möglich)</Label>
                        {config.freizeitOptions.map((item: any) => 
                            <Checkbox 
                                key={item.key} 
                                label={item.text} 
                                checked={freizeit.indexOf(item.key) >= 0} 
                                onChange={e => this.handleRequirementChange(0, 'freizeit', item.key)} 
                            />
                        )}
                    </div>
                    }

                    {freizeit.indexOf('Sonstiges') >= 0 &&
                    <TextField 
                        className={styles.group} 
                        name="freizeitSonstiges" 
                        value={freizeitSonstiges} 
                        onChange={(e) => this.handleRequirementChange(0, 'freizeitSonstiges', (e.target as HTMLInputElement).value)} 
                        label="Für welche sonstige Freizeitaktivität benötigen Sie die Schuhe?" 
                    />
                    }

                    {((bereich.indexOf('Kindergarten') >= 0) || (bereich.indexOf('Bildung') >= 0) || ((bereich.indexOf('Arbeit') >= 0) && beruf.length > 0) || ((bereich.indexOf('Freizeit') >= 0) && freizeit.length > 0)) &&
                    <div className={styles.group}>
                        <Label>Welchen Schuhtyp tragen Sie hauptsächlich? (mehrere Antworten möglich)</Label>
                        {config.schuhtypOptions.map((item: any) => 
                            <Checkbox 
                                key={item.key} 
                                label={item.text} 
                                checked={schuhtypen.indexOf(item.key) >= 0} 
                                onChange={e => this.handleRequirementChange(0, 'schuhtypen', item.key)} 
                            />
                        )}
                    </div>
                    }

                    {schuhtypen.indexOf('Sonstige') >= 0 &&
                    <TextField 
                        className={styles.group} 
                        name="schuhtypSonstiges" 
                        value={schuhtypSonstiges} 
                        onChange={(e) => this.handleRequirementChange(0, 'schuhtypSonstiges', (e.target as HTMLInputElement).value)} 
                        label="Welche sonstigen Schuhtypen tragen Sie hauptsächlich?" 
                    />
                    }

                    {schuhtypen.length > 0 &&
                    <TextField 
                        className={styles.group} 
                        name="bootCount" 
                        value={bootCount} 
                        onChange={(e) => this.handleRequirementChange(0, 'bootCount', (e.target as HTMLInputElement).value)} 
                        type="number" 
                        min="1" 
                        max="100" 
                        label="Wie viele Paar Schuhe haben Sie momentan regelmäßig in Benutzung?" 
                    />
                    }

                    {productCategories.indexOf(7) >= 0 &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={config.compressionStockingOptions} 
                        selectedKey={compressionStocking} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'compressionStocking', value!.key)} 
                        label="Tragen Sie aktuell Kompressionsstrümpfe?" 
                    />
                    }

                    {compressionStocking === 'Ja' &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={config.compressionStockingDressUpOptions} 
                        selectedKey={compressionStockingDressUp} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'compressionStockingDressUp', value!.key)} 
                        label="Wie ziehen Sie Ihre Kompressionsstrümpfe an?" 
                    />
                    }

                    {compressionStockingDressUp !== '' &&
                    <ChoiceGroup 
                        className={styles.group} 
                        options={config.compressionStockingSatisfactionOptions} 
                        selectedKey={compressionStockingSatisfaction} 
                        onChange={(e, value) => this.handleRequirementChange(0, 'compressionStockingSatisfaction', value!.key)} 
                        label="Sind Sie mit Ihren Kompressionsstrümpfen bis jetzt zufrieden?" 
                    />
                    }

                    {compressionStockingSatisfaction === 'Nein' &&
                    <div className={styles.group}>
                        <Label>Womit haben Sie Probleme bei Ihren Kompressionsstrümpfen? (mehrere Antworten möglich)</Label>
                        {config.compressionStockingProblemsOptions.map((item: any) => 
                            <Checkbox 
                                key={item.key} 
                                label={item.text} 
                                checked={compressionStockingProblems.indexOf(item.key) >= 0} 
                                onChange={e => this.handleRequirementChange(0, 'compressionStockingProblems', item.key)} 
                            />
                        )}
                    </div>
                    }

                    {productCategories.length > 0 &&
                    <div className={styles.footer}>
                        <PrimaryButton text="Weiter &raquo;" onClick={() => this.setState({ page: 2 }, () => window.scrollTo(0 ,0))} />
                        <DefaultButton text="Vorgang abbrechen" onClick={() => this.handleCancelClick()} />
                    </div>
                    }
                </form>
                }

                {this.state.page === 2 &&
                <form>
                    <h1>Angaben zu Ihrer Person</h1>
                    <Dropdown
                        label="Anrede"
                        required
                        options={config.titleOptions}
                        selectedKey={title}
                        onChange={(e, item) => this.handleDropdownChange(e, item, 'title')}
                        className={styles.field}
                    />
                    <TextField
                        name="firstname"
                        label="Vorname"
                        required
                        value={firstname}
                        onChange={(e) => this.handleTextFieldChange(e)}
                        onGetErrorMessage={string => string !== '' ? '' : 'Diese Angabe ist verpflichtend.'}
                        validateOnFocusOut={true}
                        validateOnLoad={false}
                        className={styles.field}
                    />
                    <TextField
                        name="lastname"
                        label="Nachname"
                        required
                        value={lastname}
                        onChange={(e) => this.handleTextFieldChange(e)}
                        onGetErrorMessage={string => string !== '' ? '' : 'Diese Angabe ist verpflichtend.'}
                        validateOnFocusOut={true}
                        validateOnLoad={false}
                        className={styles.field}
                    />
                    <TextField
                        name="email"
                        label="E-Mail (falls vorhanden)" 
                        required
                        description="wird nur zu Ihrer Qualitätsbefragung genutzt" 
                        value={email}
                        type="email"
                        onChange={(e) => this.handleTextFieldChange(e)}
                        onGetErrorMessage={string => string === '' || validator.isEmail(string) ? '' : 'E-Mail-Adresse ist nicht gültig.'}
                        validateOnFocusOut={true}
                        validateOnLoad={false}
                        className={styles.field}
                    />
                    <TextField
                        name="mobile" 
                        label="Mobilnummer" 
                        value={mobile} 
                        onChange={(e) => this.handleTextFieldChange(e)}
                        className={styles.field}
                    />
                    <TextField
                        name="phone" 
                        label="Festnetz (falls Mobil nicht vorhanden)" 
                        value={phone} 
                        onChange={(e) => this.handleTextFieldChange(e)}
                        className={styles.field}
                    />
                    <DatePicker
                        className={styles.field}
                        label="Geburtsdatum"
                        isRequired
                        allowTextInput={true}
                        firstDayOfWeek={1}
                        strings={Helper.datePickerStrings}
                        onSelectDate={(date) => this.handleDateChange('birthday', date)}
                        formatDate={(date) => date!.toLocaleDateString()}
                        parseDateFromString={(string) => Helper.parseDateFromString(string)}
                        value={birthday !== '' ? new Date(birthday) : undefined}
                    />
                    <TextField
                        name="description"
                        label="Anmerkungen"
                        value={description}
                        multiline={true}
                        placeholder={config.users?.description?.placeholder || "z. B. bevorzugte Kontaktzeiten"}
                        onChange={(e) => this.handleTextFieldChange(e)}
                        className={styles.field}
                    />

                    {config.fields.address !== 'hidden' &&
                    <>
                        <h2>Anschrift</h2>
                        <TextField
                            name="street"
                            label="Straße"
                            value={street}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <TextField
                            name="addition"
                            label="Adresszusatz"
                            value={addition}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <div className={styles.fieldGroup}>
                            <TextField
                                name="zip"
                                label="PLZ"
                                value={zip}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                            <TextField
                                name="city"
                                label="Ort"
                                value={city}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                        </div>
                        <TextField
                            name="country"
                            label="Land"
                            value={country}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                    </>
                    }

                    {config.fields.health !== 'hidden' &&
                    <>
                        <h2>Krankenkasse</h2>
                        <TextField
                            name="healthInsurance"
                            label="Name der Krankenkasse"
                            value={healthInsurance}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <TextField
                            name="healthInsuranceID"
                            label="Versichertennummer"
                            value={healthInsuranceID}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <TextField
                            name="careLevel"
                            label="Pflegegrad"
                            value={careLevel}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                    </>
                    }

                    {email !== '' &&
                    <>
                        <h2>Bitte vergeben Sie ein Passwort.</h2>
                        <TextField 
                            name="password" 
                            label="Passwort" 
                            value={password} 
                            type="password" 
                            onChange={(e) => this.handleTextFieldChange(e)} 
                            errorMessage={password.length >= 8 ? '' : 'Passwort muss mindestens 8 Zeichen lang sein.'} 
                            className={styles.field} 
                            autoComplete="off" 
                        />
                        {password !== '' &&
                        <TextField 
                            name="passwordCheck" 
                            label="Passwort (wiederholen)" 
                            value={this.state.passwordCheck} 
                            type="password" 
                            onChange={(e) => this.setState({passwordCheck: (e.target as HTMLInputElement).value})} 
                            errorMessage={this.state.passwordCheck === password ? '' : 'Passwörter stimmen nicht überein.'} 
                            className={styles.field} 
                            autoComplete="off" 
                        />
                        }
                    </>
                    }

                    <div className={styles.footer}>
                        <PrimaryButton text="Weiter &raquo;" onClick={() => {
                            if(email !== '' && (phone !== '' || mobile !== '')){
                                this.setState({ page: 5 }, () => window.scrollTo(0 ,0));
                            } else {
                                this.setState({ page: 3 }, () => window.scrollTo(0 ,0));
                            }
                        }} disabled={invalidInput} />
                        <DefaultButton text="Zurück" onClick={() => this.setState({ page: 1 }, () => window.scrollTo(0 ,0))} />
                        <DefaultButton text="Vorgang abbrechen" onClick={() => this.handleCancelClick()} />
                    </div>
                </form>
                }

                {this.state.page === 3 &&
                <form>
                    <h1>Fehlende Angaben</h1>
                    <p>Damit Sie an der Qualitätsbefragung teilnehmen können, benötigen wir Ihre E-Mail-Adresse sowie eine Festnetznummer oder Mobiltelefonnummer.</p>

                    <TextField 
                        name="email" 
                        label="E-Mail (falls vorhanden)" 
                        description="wird nur zu Ihrer Qualitätsbefragung genutzt" 
                        value={email} 
                        type="email" 
                        onChange={(e) => this.handleTextFieldChange(e)} 
                        onGetErrorMessage={string => string === '' || validator.isEmail(string) ? '' : 'E-Mail-Adresse ist nicht gültig.'} 
                        validateOnFocusOut={true} 
                        validateOnLoad={false} 
                        className={styles.field} 
                    />
                    <TextField 
                        name="mobile" 
                        label="Mobilnummer" 
                        value={mobile} 
                        onChange={(e) => this.handleTextFieldChange(e)} 
                        className={styles.field} 
                    />
                    <TextField 
                        name="phone" 
                        label="Festnetz (falls Mobil nicht vorhanden)" 
                        value={phone} 
                        onChange={(e) => this.handleTextFieldChange(e)} 
                        className={styles.field} 
                    />

                    {email !== '' &&
                    <>
                        <h2>Bitte vergeben Sie ein Passwort.</h2>
                        <TextField
                            name="password"
                            label="Passwort"
                            value={password}
                            type="password"
                            onChange={(e) => this.handleTextFieldChange(e)}
                            errorMessage={password.length >= 8 ? '' : 'Passwort muss mindestens 8 Zeichen lang sein.'}
                            className={styles.field}
                            autoComplete="off"
                        />
                        {password !== '' &&
                        <TextField
                            name="passwordCheck"
                            label="Passwort (wiederholen)"
                            value={this.state.passwordCheck}
                            type="password"
                            onChange={(e) => this.setState({passwordCheck: (e.target as HTMLInputElement).value})}
                            errorMessage={this.state.passwordCheck === password ? '' : 'Passwörter stimmen nicht überein.'}
                            className={styles.field}
                            autoComplete="off"
                        />
                        }
                    </>
                    }

                    <div className={styles.footer}>
                        <PrimaryButton text="Weiter &raquo;" onClick={() => {
                            if(email !== ''){
                                this.setState({ page: 5 }, () => window.scrollTo(0 ,0))
                            } else {
                                this.setState({ page: 4 }, () => window.scrollTo(0 ,0))
                            }
                        }} disabled={invalidInput} />
                        <DefaultButton text="Zurück" onClick={() => this.setState({ page: 2 }, () => window.scrollTo(0 ,0))} />
                        <DefaultButton text="Vorgang abbrechen" onClick={() => this.handleCancelClick()} />
                    </div>
                </form>
                }

                {this.state.page === 4 &&
                <form>
                    <h1>Fehlende Angaben</h1>
                    <ChoiceGroup 
                        className={styles.group} 
                        options={[
                            {
                                key: 'yes',
                                text: 'ja'
                            },
                            {
                                key: 'no',
                                text: 'Nein, ich möchte nicht an der Qualitätsbefragung teilnehmen.'
                            },
                        ]} 
                        selectedKey={this.state.postal} 
                        onChange={(e, value) => this.setState({postal: value!.key})} 
                        label="Ich möchte ausschließlich postalisch kontaktiert werden" 
                    />

                    <div className={styles.footer}>
                        <PrimaryButton text="Weiter &raquo;" onClick={() => this.setState({ page: 5 }, () => window.scrollTo(0 ,0))} disabled={this.state.postal === ''} />
                        <DefaultButton text="Zurück" onClick={() => this.setState({ page: 3 }, () => window.scrollTo(0 ,0))} />
                        <DefaultButton text="Vorgang abbrechen" onClick={() => this.handleCancelClick()} />
                    </div>
                </form>
                }

                {this.state.page === 5 &&
                <div>
                    <h1>Zusammenfassung</h1>
                    <p>Bevor Sie mit der Registrierung fortfahren, überprüfen Sie bitte Ihre Angaben!</p>

                    <div className={styles.group}>
                        <h3>Ihre Daten</h3>
                        <table className={styles.zfg}>
                            <tbody>
                                <tr><td>Name</td><td>{title} {firstname} {lastname}</td></tr>
                                <tr><td>Geburtsdatum</td><td>{birthday}</td></tr>
                                <tr><td>E-Mail</td><td>{email}</td></tr>
                                <tr><td>Handy</td><td>{mobile}</td></tr>
                                <tr><td>Telefon</td><td>{phone}</td></tr>

                                {config.fields.address !== 'hidden' &&
                                <tr><td>Adresse</td><td>{street}, {addition}, {zip} {city}</td></tr>
                                }

                                {config.fields.health !== 'hidden' &&
                                <>
                                    <tr><td>Krankenkasse</td><td>{healthInsurance}</td></tr>
                                    <tr><td>Versichertennummer</td><td>{healthInsuranceID}</td></tr>
                                    <tr><td>Pflegegrad</td><td>{careLevel}</td></tr>
                                </>
                                }
                            </tbody>
                        </table>

                        <h3>Anmerkungen</h3>
                        <p>{description !== '' ? description : '---'}</p>

                        <h3>Stammkunde</h3>
                        <p>{regularCustomer === 'Ja' ? 'Ja' : 'Nein'}</p>

                        <h3>Altersgruppe</h3>
                        <p>{config.ageOptions.filter((item: any) => item.key === age)[0]?.text}</p>

                        <h3>Wofür interessieren Sie sich?</h3>
                        <ul>
                            {config.productOptions.filter((item: any) => productCategories.indexOf(item.key) >= 0).map((item: any) => 
                                <li key={item.key}>{item.text}</li>
                            )}
                        </ul>


                        {(productCategories.indexOf(1) >= 0 || productCategories.indexOf(4) >= 0) &&
                        <>
                            <h3>Wofür benötigen Sie Ihre Schuhe?</h3>
                            <ul>
                                {config.bereichOptions.filter((item: any) => bereich.indexOf(item.key) >= 0).map((item: any) => 
                                    <li key={item.key}>{item.text}</li>
                                )}
                            </ul>
                        </>
                        }

                        {bereich.indexOf('Arbeit') >= 0 &&
                        <>
                            <h3>Wie verbringen Sie Ihren Arbeitsalltag?</h3>
                            <p>{config.berufOptions.filter((item: any) => item.key === beruf)[0]?.text}</p>
                        </>
                        }

                        {beruf === 'Sonstiges' &&
                        <>
                            <h3>Wie sieht Ihre sonstige Tätigkeit aus?</h3>
                            <p>{berufSonstiges}</p>
                        </>
                        }

                        {bereich.indexOf('Freizeit') >= 0 &&
                        <>
                            <h3>Wofür benötigen Sie Ihre Schuhe in Ihrer Freizeit?</h3>
                            <ul>
                                {config.freizeitOptions.filter((item: any) => freizeit.indexOf(item.key) >= 0).map((item: any) => 
                                    <li key={item.key}>{item.text}</li>
                                )}
                            </ul>
                        </>
                        }

                        {freizeit.indexOf('Sonstiges') >= 0 &&
                        <>
                            <h3>Für welche sonstige Freizeitaktivität benötigen Sie die Schuhe?</h3>
                            <p>{freizeitSonstiges}</p>
                        </>
                        }

                        {bereich.length > 0 &&
                        <>
                            <h3>Welchen Schuhtypen tragen Sie hauptsächlich?</h3>
                            <ul>
                                {config.schuhtypOptions.filter((item: any) => schuhtypen.indexOf(item.key)  >= 0).map((item: any) => 
                                    <li key={item.key}>{item.text}</li>
                                )}
                            </ul>
                        </>
                        }

                        {schuhtypen.indexOf('Sonstige') >= 0 &&
                        <>
                            <h3>Welche weiteren Schuhtypen tragen Sie hauptsächlich?</h3>
                            <p>{schuhtypSonstiges}</p>
                        </>
                        }

                        {schuhtypen.length > 0 &&
                        <>
                            <h3>Wie viele Paar Schuhe haben Sie momentan regelmäßig in Benutzung?</h3>
                            <p>{bootCount}</p>
                        </>
                        }

                        {productCategories.indexOf(7) >= 0 &&
                        <>
                            <h3>Tragen Sie aktuell Kompressionsstrümpfe?</h3>
                            <p>{config.compressionStockingOptions.filter((item: any) => item.key === compressionStocking)[0]?.text}</p>
                        </>
                        }

                        {compressionStocking === 'Ja' &&
                        <>
                            <h3>Wie ziehen Sie Ihre Kompressionsstrümpfe an?</h3>
                            <p>{config.compressionStockingDressUpOptions.filter((item: any) => item.key === compressionStockingDressUp)[0]?.text}</p>
                        </>
                        }

                        {compressionStockingDressUp !== '' &&
                        <>
                            <h3>Sind Sie mit Ihren Kompressionsstrümpfen bis jetzt zufrieden?</h3>
                            <p>{config.compressionStockingSatisfactionOptions.filter((item: any) => item.key === compressionStockingSatisfaction)[0]?.text}</p>
                        </>
                        }

                        {compressionStockingSatisfaction === 'Nein' &&
                        <>
                            <h3>Womit haben Sie Probleme bei Ihren Kompressionsstrümpfen?</h3>
                            <ul>
                                {config.compressionStockingProblemsOptions.filter((item: any) => compressionStockingProblems.indexOf(item.key)  >= 0).map((item: any) => 
                                    <li key={item.key}>{item.text}</li>
                                )}
                            </ul>
                        </>
                        }
                    </div>

                    {config.consentBoxes.length > 0 &&
                    <div className={styles.group}>
                        <h2>Einverständniserklärung</h2>
                        {config.consentDescription !== '' && <p dangerouslySetInnerHTML={{__html: config.consentDescription}}></p> }

                        {config.consentBoxes.map((consent: any) => <Checkbox key={consent.key} label={consent.text} checked={consents[consent.key]} onChange={() => this.handleConsentChange(consent.key)} /> )}
                    </div>
                    }

                    <div className={styles.footer}>
                        <PrimaryButton text="Registrierung abschließen &raquo;" onClick={() => this.handleSaveClick()} disabled={!checkedConsents} />
                        <DefaultButton text="Angaben ändern" onClick={() => this.setState({ page: 1 }, () => window.scrollTo(0 ,0))} />
                        <DefaultButton text="Vorgang abbrechen" onClick={() => this.handleCancelClick()} />
                    </div>
                </div>
                }
            </div>
            : <Navigate to="/" />
            }
            </>
        )
    }

    handleChoiceGroupChange(target: string, value: any){
        const copy: any = {...this.state.user};
        copy[target] = value;
        this.setState({ user: copy });
    }

    handleTextFieldChange(e: any){
        const copy: any = {...this.state.user};
        copy[e.target.name] = e.target.value;
        this.setState({ user: copy });
    }

    handleDropdownChange(e: any, item: any, name: string){
        const copy: any = {...this.state.user};
        copy[name] = item.key;
        this.setState({ user: copy });
    }

    handleDateChange(target: string, value: any){
        const copy: any = {...this.state.user};
        copy[target] = value !== null ? Helper.parseSQLStringFromDate(value) : '';
        this.setState({ user: copy });
    }

    handleConsentChange(name: any){
        const copy = {...this.state.user};
        copy.consents[name] = !copy.consents[name];
        copy.consentsChanged = true;
        this.setState({ user: copy });
    }

    handleRequirementChange(index: number, target: any, value: any){
        const copy: any = {...this.state.user};

        if(Array.isArray(copy.requirements[index][target])){
            const item = copy.requirements[index][target].indexOf(value);
            if( item >= 0){
                copy.requirements[index][target].splice(item, 1);
            } else {
                copy.requirements[index][target].push(value);
            }
        } else {
            copy.requirements[index][target] = value;
        }

        copy.requirements[index].status = 'changed';
        this.setState({ user: copy });
    }

    handleSaveClick(){
        const copy = {...this.state.user};
        copy.regularCustomer = copy.regularCustomer === 'Ja' ? 'Ja' : 'Nein';
        this.props.onSave(copy);
        this.props.router.navigate(-1);
    }

    handleCancelClick(){
        this.props.router.navigate(-1);
    }
}

export default Registration;