import React, {Component} from 'react'; 

import { Dialog, DialogType, DialogFooter, Selection,DetailsListLayoutMode, DefaultButton, SearchBox, DetailsList} from '@fluentui/react/lib/index.bundle';

import  styles from './KundenDialog.module.css';
import { UserType} from '../../../App';
interface KundenDialogState {
	selectedItem?: UserType,
	sort:any,
	search:string,
	_selection : Selection;
}
type KundenDialogProps =  Readonly <{
    items: any
	hidden:boolean,
	title:String,
	onSelect: (item: any) => void,
	onClose: () =>  void
}> 
export class KundenDialog extends Component<KundenDialogProps,KundenDialogState> {
	constructor(props) {
		super(props);

		this.state = {
			sort:{
				desc:false,
				by:'Firma'
			},
			search:"",
			_selection : new Selection({
				onSelectionChanged: () => {
					let sel = this.state._selection.getSelection();
					if (sel.length === 1){
						this.props.onSelect(sel[0]);
					} else {
					}
				}
			})
		}
	}
	render() {
		
        let items = this.props.items;
        if(this.state.search !== ''){
            const parts = this.state.search.toLowerCase().trim().split(' ');
            items = items.filter( item => {
                let found = false;
                let check = false;
                parts.forEach((part: any) => found && (item.lastname.toLowerCase().indexOf(part) >= 0 || item.firstname.toLowerCase().indexOf(part) >= 0));
                for (let x = 0; x < parts.length; x++) {
                    if (item.lastname.toLowerCase().indexOf(parts[x]) >= 0 || item.firstname.toLowerCase().indexOf(parts[x]) >= 0) {
                        check = true;
                    } else {
                        check = false;
                    }
                }
                found = check;
                return found;
            });
        }
		return (
				<Dialog
					hidden={this.props.hidden}
					dialogContentProps={{
						type: DialogType.normal,
						title: <div style={{display:'flex', justifyContent:'space-between'}}>
							<div>{this.props.title}</div>
							<SearchBox placeholder="Kunden suchen" value={this.state.search}
							 onChange={(e,newValue)=>this.setState({search: typeof(newValue)==='undefined'?'':newValue})}
							 />
						</div>
					}}
					modalProps={{
						titleAriaId: 'myLabelId',
						subtitleAriaId: 'mySubTextId',
						isBlocking: true,
						containerClassName: styles.Dialog
					}}
					onDismiss={() => {
						this.props.onClose();
					}} styles={{root:{minWidth:"400px"}}}
					>
					{<div className="flex-column">
						<DetailsList 
							items={items}
							//style={{minHeight:'300px'}}
							layoutMode={DetailsListLayoutMode.justified}
							columns={
								[
									{
										key: 'id',
										name: 'ID',
										fieldName: 'id',
										minWidth: 20,
										maxWidth: 30,
										isResizable: true,
										isPadded: true,
									},
									{
										key: 'title',
										name: 'Anrede',
										fieldName: 'title',
										minWidth: 30,
										maxWidth: 100,
										isResizable: true,
										isPadded: true,
									},
									{
										key: 'lastname',
										name: 'Name',
										fieldName: 'lastname',
										minWidth: 120,
										maxWidth: 200,
										isResizable: true,
										isPadded: true,
									},
									{
										key: 'firstname',
										name: 'Vorname',
										fieldName: 'firstname',
										minWidth: 120,
										maxWidth: 200,
										isResizable: true,
										isPadded: true,
									}
								]
							  } 
							selection={this.state!._selection}
						/>
						
					</div>}
					<DialogFooter>
						<DefaultButton onClick={(e)=>{
							this.props.onClose();
						}} text="abbrechen" />
					</DialogFooter>
					</Dialog>
			
		)
		;
	}
}
