const Helper = {
    datePickerStrings: {
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnertag', 'Freitag', 'Samstag'],
        shortDays: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],
        goToToday: 'Heute',
        prevMonthAriaLabel: 'Vormonat',
        nextMonthAriaLabel: 'Folgemonat',
        prevYearAriaLabel: 'Vorjahr',
        nextYearAriaLabel: 'Folgejahr',
        isRequiredErrorMessage: 'Diese Angabe ist verpflichtend.',
        invalidInputErrorMessage: 'Ungültiges Datumsformat.',
    },
    parseDateFromString: (dateStr) => {
        const date = new Date();
        const values = (dateStr || '').trim().split('.');
        const day = dateStr.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : date.getDate();
        const month = dateStr.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth();
        let year = dateStr.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
        if (year < 100) {
            year += 1900;
            if (date.getFullYear()-year > 95){
                year += 100;
            }
        }
        return new Date(year, month, day);
    },
    parseSQLStringFromDate: (date) => {
        return date.getFullYear() + '-' + (date.getMonth() + 1 + '').padStart(2, '0') + '-' + (date.getDate() + '').padStart(2, '0');
    },
}

export default Helper;