import React, { Component } from 'react';
import { Pivot, PivotItem, TextField, Dropdown, DefaultButton, PrimaryButton, Checkbox, DatePicker, ChoiceGroup, Label } from '@fluentui/react';
import validator from 'validator';
import Config from '../../../core/Config';
import { AppState, UserType } from '../../../App';
import Helper from '../../../core/Helper';
import styles from '../css/User.module.css';

type UserProps = Readonly <{
    config: typeof Config,
    items: AppState['users'],
    user: UserType,
    onSave: (user: any) => void,
    router: {
        location: any,
        navigate: any,
        params: any
    }
}>

export type RequirementsType = Array<{
    age: string,
    productCategories: any[],
    bereich: any[],
    beruf: string,
    berufSonstiges: string,
    freizeit: any[],
    freizeitSonstiges: string,
    schuhtypen: any[],
    schuhtypSonstiges: string,
    bootCount: string,
    compressionStocking: string,
    compressionStockingDressUp: string,
    compressionStockingSatisfaction: string,
    compressionStockingProblems: any[],
    created?: string | number,
    status: string,
    custom?: any
}>

interface UserState {
    passwordCheck: string,
    new: boolean,
    user: UserType,
}
class User extends Component<UserProps, UserState> {

    state: UserState;
    props: UserProps;

    constructor(props: UserProps){
        super(props);
        this.props = props;

        const consents: any = {};

        
        this.props.config.consentBoxes.forEach((consent: any) => {
            consents[consent.key] = consent.defaultValue;
        });

        this.state = {
            new: true,
            user: {
                title: '',
                firstname: '',
                lastname: '',
                email: '',
                password: '',
                usergroup: 'users',
                regularCustomer: false,
                location: 0,
                phone: '',
                mobile: '',
                birthday: '',
                description: '',
                street: '',
                addition: '',
                zip: '',
                city: '',
                country: '',
                healthInsurance: '',
                healthInsuranceID: '',
                careLevel: '',
                consents: consents,
                consentsChanged: false,
                requirements: [
                    {
                        age: '',
                        productCategories: [],
                        bereich: [],
                        beruf: '',
                        berufSonstiges: '',
                        freizeit: [],
                        freizeitSonstiges: '',
                        schuhtypen: [],
                        schuhtypSonstiges: '',
                        bootCount: '',
                        compressionStocking: '',
                        compressionStockingDressUp: '',
                        compressionStockingSatisfaction: '',
                        compressionStockingProblems: [],
                        created: '',
                        status: ''
                    },
                ],
            },
            passwordCheck: '',
        };
    }

    componentDidMount(){
        this._selectUser();
    }

    componentDidUpdate(prevProps: any) {
        let {id} = this.props.router.params;
        if(id !== prevProps.router.params.id  || this.props.items !== prevProps.items){
            this._selectUser();
        }
    }

    render() {
        const config = this.props.config;
        const admin = ['system', 'admin', 'employee'].indexOf(this.props.user.usergroup) !== -1;
        const {title, firstname, lastname, usergroup, regularCustomer, location, email, password, phone, mobile, birthday, description, street, addition, zip, city, country, healthInsurance, healthInsuranceID, careLevel, consents, requirements} = this.state.user;
        const RquirementsIndex = requirements.length-1;
        const {age, productCategories, bereich, beruf, berufSonstiges, freizeit, freizeitSonstiges, schuhtypen, schuhtypSonstiges, bootCount, compressionStocking, compressionStockingDressUp, compressionStockingSatisfaction, compressionStockingProblems, created} = requirements[requirements.length-1];
        let invalidInput = title === '' || firstname === '' || lastname === '' || (email !== '' && (!validator.isEmail(email) || password !== this.state.passwordCheck)) || (phone === '' && mobile === '') || birthday === '';

        if(this.state.new === true){
            invalidInput = invalidInput || (email !== '' && password.length < 8);
        } else {
            invalidInput = invalidInput || (password !== '' && password.length < 8);
        }

        return(
            <form className={styles.root}>
                <h1>{admin ? 'Stammdaten' : 'Meine Daten bearbeiten'}</h1>
                <Pivot>
                    <PivotItem headerText="Kontaktdaten">
                        <Dropdown
                            label="Anrede"
                            options={config.titleOptions}
                            selectedKey={title}
                            onChange={(e, item) => this.handleDropdownChange(e, item, 'title')}
                            className={styles.field}
                        />
                        <TextField
                            name="firstname"
                            label="Vorname"
                            value={firstname}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            onGetErrorMessage={string => string !== '' ? '' : 'Diese Angabe ist verpflichtend.'}
                            validateOnFocusOut={true}
                            validateOnLoad={false}
                            className={styles.field}
                        />
                        <TextField
                            name="lastname"
                            label="Nachname"
                            value={lastname}
                            onChange={(e) => this.handleTextFieldChange(e)}
                            onGetErrorMessage={string => string !== '' ? '' : 'Diese Angabe ist verpflichtend.'}
                            validateOnFocusOut={true}
                            validateOnLoad={false}
                            className={styles.field}
                        />
                        <Dropdown
                            placeholder="Benutzergruppe wählen"
                            label="Benutzergruppe"
                            options={config.usergroupOptions}
                            selectedKey={usergroup}
                            onChange={(e, item) => this.handleDropdownChange(e, item, 'usergroup')}
                            className={styles.field}
                            disabled={!admin}
                        />
                        <Checkbox
                            label="Stammkunde" 
                            checked={regularCustomer} 
                            onChange={(e) => this.handleCheckboxChange('regularCustomer')} 
                            className={styles.field}
                            disabled={!admin}
                        />
                        <Dropdown
                            placeholder="Filiale wählen"
                            label="Filiale"
                            options={config.locationOptions}
                            selectedKey={location}
                            onChange={(e, item) => this.handleDropdownChange(e, item, 'location')}
                            className={styles.field}
                            disabled={!admin}
                        />
                        <TextField
                            name="email"
                            label="E-Mail (optional)"
                            value={email}
                            type="email"
                            onChange={(e) => this.handleTextFieldChange(e)}
                            onGetErrorMessage={string => string === '' || validator.isEmail(string) ? '' : 'E-Mail-Adresse ist nicht gültig.'}
                            validateOnFocusOut={true}
                            validateOnLoad={false}
                            className={styles.field}
                        />
                        <TextField
                            name="mobile" 
                            label="Handy" 
                            value={mobile} 
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <TextField
                            name="phone" 
                            label="Telefon" 
                            value={phone} 
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />
                        <DatePicker
                            className={styles.field}
                            label="Geburtsdatum"
                            allowTextInput={true}
                            firstDayOfWeek={1}
                            strings={Helper.datePickerStrings}
                            onSelectDate={(date) => this.handleDateChange('birthday', date)}
                            formatDate={(date) => date!.toLocaleDateString()}
                            parseDateFromString={(string) => Helper.parseDateFromString(string)}
                            value={birthday !== '' ? new Date(birthday) : undefined}
                        />
                        <TextField
                            name="description"
                            label="Anmerkungen"
                            value={description}
                            multiline={true}
                            placeholder="z. B. bevorzugte Kontaktzeiten"
                            onChange={(e) => this.handleTextFieldChange(e)}
                            className={styles.field}
                        />

                        {config.fields.address !== 'hidden' &&
                        <>
                            <h2>Anschrift</h2>
                            <TextField
                                name="street"
                                label="Straße"
                                value={street}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                            <TextField
                                name="addition"
                                label="Adresszusatz"
                                value={addition}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                            <div className={styles.fieldGroup}>
                                <TextField
                                    name="zip"
                                    label="PLZ"
                                    value={zip}
                                    onChange={(e) => this.handleTextFieldChange(e)}
                                    className={styles.field}
                                />
                                <TextField
                                    name="city"
                                    label="Ort"
                                    value={city}
                                    onChange={(e) => this.handleTextFieldChange(e)}
                                    className={styles.field}
                                />
                            </div>
                            <TextField
                                name="country"
                                label="Land"
                                value={country}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                        </>
                        }

                        {config.fields.health !== 'hidden' &&
                        <>
                            <h2>Krankenkasse</h2>
                            <TextField
                                name="healthInsurance"
                                label="Name der Krankenkasse"
                                value={healthInsurance}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                            <TextField
                                name="healthInsuranceID"
                                label="Versichertennummer"
                                value={healthInsuranceID}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                            <TextField
                                name="careLevel"
                                label="Pflegegrad"
                                value={careLevel}
                                onChange={(e) => this.handleTextFieldChange(e)}
                                className={styles.field}
                            />
                        </>
                        }

                        {email !== '' &&
                        <>
                            <h2>Passwort {this.state.new === true ? 'vergeben' : 'ändern'}</h2>
                            <TextField
                                name="password"
                                label="Passwort"
                                value={password}
                                type="password"
                                onChange={(e) => this.handleTextFieldChange(e)}
                                errorMessage={(this.state.new === false && (password === '' || password.length >= 8)) || (this.state.new === true && password.length >= 8) ? '' : 'Passwort muss mindestens 8 Zeichen lang sein.'}
                                className={styles.field}
                                autoComplete="off"
                            />
                            {password !== '' &&
                            <TextField
                                name="passwordCheck"
                                label="Passwort (wiederholen)"
                                value={this.state.passwordCheck}
                                type="password"
                                onChange={(e) => this.setState({passwordCheck: (e.target as HTMLInputElement).value})}
                                errorMessage={this.state.passwordCheck === password ? '' : 'Passwörter stimmen nicht überein.'}
                                className={styles.field}
                                autoComplete="off"
                            />
                            }
                        </>
                        }

                        {admin && config.consentBoxes.length > 0 &&
                        <div className={styles.group}>
                            <h2>Einverständniserklärung</h2>
                            {config.consentDescription !== '' && <p dangerouslySetInnerHTML={{__html: config.consentDescription}}></p> }

                            {config.consentBoxes.map((consent: any) => <Checkbox key={consent.key} label={consent.text} checked={consents[consent.key]} onChange={() => this.handleConsentChange(consent.key)} disabled={Boolean(this.state.user?.id)} /> )}
                        </div>
                        }

                        <div className={styles.footer}>
                            <PrimaryButton text={this.state.new === true ? 'Registrierung abschließen' : 'Speichern'} onClick={() => this.handleSaveClick()} disabled={invalidInput} />
                            <DefaultButton text="Vorgang abbrechen" onClick={() => this.props.router.navigate(-1)} />
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Anforderungsprofil">
                        <ChoiceGroup 
                            className={styles.group + ' ' + styles.ages} 
                            options={config.ageOptions} 
                            selectedKey={age} 
                            onChange={(e, value) => this.handleRequirementChange(RquirementsIndex, 'age', value!.key)} 
                            label="Altersgruppe" 
                        />

                        {age !== '' &&
                        <div className={styles.group}>
                            <Label>Wofür interessieren Sie sich? (mehrere Antworten möglich)</Label>
                            {config.productOptions.map((item: any) => 
                                <Checkbox 
                                    key={item.key} 
                                    label={item.text} 
                                    checked={productCategories.indexOf(item.key) >= 0} 
                                    onChange={e => this.handleRequirementChange(RquirementsIndex, 'productCategories', item.key)} 
                                />
                            )}
                        </div>
                        }

                        {(productCategories.indexOf(1) >= 0 || productCategories.indexOf(4) >= 0) &&
                        <div className={styles.group}>
                            <Label>Wofür benötigen Sie Ihre Schuhe? (mehrere Antworten möglich)</Label>
                            {config.bereichOptions.map((item: any) => 
                                <Checkbox 
                                    key={item.key} 
                                    label={item.text} 
                                    checked={bereich.indexOf(item.key) >= 0} 
                                    onChange={e => this.handleRequirementChange(RquirementsIndex, 'bereich', item.key)} 
                                />
                            )}
                        </div>
                        }

                        {bereich.indexOf('Arbeit') >= 0 &&
                        <ChoiceGroup 
                            className={styles.group} 
                            options={config.berufOptions} 
                            selectedKey={beruf} 
                            onChange={(e, value) => this.handleRequirementChange(RquirementsIndex, 'beruf', value!.key)} 
                            label="Wie verbringen Sie Ihren Arbeitsalltag?" 
                        />
                        }

                        {beruf === 'Sonstiges' &&
                        <TextField 
                            className={styles.group} 
                            name="berufSonstiges" 
                            value={berufSonstiges} 
                            onChange={(e) => this.handleRequirementChange(RquirementsIndex, 'berufSonstiges', (e.target as HTMLInputElement).value)} 
                            label="Wie sieht Ihre sonstige Tätigkeit aus?" 
                        />
                        }

                        {bereich.indexOf('Freizeit') >= 0 &&
                        <div className={styles.group}>
                            <Label>Wofür benötigen Sie Ihre Schuhe in der Freizeit? (mehrere Antworten möglich)</Label>
                            {config.freizeitOptions.map((item: any) => 
                                <Checkbox 
                                    key={item.key} 
                                    label={item.text} 
                                    checked={freizeit.indexOf(item.key) >= 0} 
                                    onChange={e => this.handleRequirementChange(RquirementsIndex, 'freizeit', item.key)} 
                                />
                            )}
                        </div>
                        }

                        {freizeit.indexOf('Sonstiges') >= 0 &&
                        <TextField 
                            className={styles.group} 
                            name="freizeitSonstiges" 
                            value={freizeitSonstiges} 
                            onChange={(e) => this.handleRequirementChange(RquirementsIndex, 'freizeitSonstiges', (e.target as HTMLInputElement).value)} 
                            label="Für welche sonstige Freizeitaktivität benötigen Sie die Schuhe?" 
                        />
                        }

                        {((bereich.indexOf('Kindergarten') >= 0) || (bereich.indexOf('Bildung') >= 0) || ((bereich.indexOf('Arbeit') >= 0) && beruf.length > 0) || ((bereich.indexOf('Freizeit') >= 0) && freizeit.length > 0)) &&
                        <div className={styles.group}>
                            <Label>Welchen Schuhtyp tragen Sie hauptsächlich? (mehrere Antworten möglich)</Label>
                            {config.schuhtypOptions.map((item: any) => 
                                <Checkbox 
                                    key={item.key} 
                                    label={item.text} 
                                    checked={schuhtypen.indexOf(item.key) >= 0} 
                                    onChange={e => this.handleRequirementChange(RquirementsIndex, 'schuhtypen', item.key)} 
                                />
                            )}
                        </div>
                        }

                        {schuhtypen.indexOf('Sonstige') >= 0 &&
                        <TextField 
                            className={styles.group} 
                            name="schuhtypSonstiges" 
                            value={schuhtypSonstiges} 
                            onChange={(e) => this.handleRequirementChange(RquirementsIndex, 'schuhtypSonstiges', (e.target as HTMLInputElement).value)} 
                            label="Welche sonstigen Schuhtypen tragen Sie hauptsächlich?" 
                        />
                        }

                        {schuhtypen.length > 0 &&
                        <TextField 
                            className={styles.group} 
                            name="bootCount" 
                            value={bootCount} 
                            onChange={(e) => this.handleRequirementChange(RquirementsIndex, 'bootCount', (e.target as HTMLInputElement).value)} 
                            type="number" 
                            min="1" 
                            max="100" 
                            label="Wie viele Paar Schuhe haben Sie momentan regelmäßig in Benutzung?" 
                        />
                        }

                        {productCategories.indexOf(7) >= 0 &&
                        <ChoiceGroup 
                            className={styles.group} 
                            options={config.compressionStockingOptions} 
                            selectedKey={compressionStocking} 
                            onChange={(e, value) => this.handleRequirementChange(RquirementsIndex, 'compressionStocking', value!.key)} 
                            label="Tragen Sie aktuell Kompressionsstrümpfe?" 
                        />
                        }

                        {compressionStocking === 'Ja' &&
                        <ChoiceGroup 
                            className={styles.group} 
                            options={config.compressionStockingDressUpOptions} 
                            selectedKey={compressionStockingDressUp} 
                            onChange={(e, value) => this.handleRequirementChange(RquirementsIndex, 'compressionStockingDressUp', value!.key)} 
                            label="Wie ziehen Sie Ihre Kompressionsstrümpfe an?" 
                        />
                        }

                        {compressionStockingDressUp !== '' &&
                        <ChoiceGroup 
                            className={styles.group} 
                            options={config.compressionStockingSatisfactionOptions} 
                            selectedKey={compressionStockingSatisfaction} 
                            onChange={(e, value) => this.handleRequirementChange(RquirementsIndex, 'compressionStockingSatisfaction', value!.key)} 
                            label="Sind Sie mit Ihren Kompressionsstrümpfen bis jetzt zufrieden?" 
                        />
                        }

                        {compressionStockingSatisfaction === 'Nein' &&
                        <div className={styles.group}>
                            <Label>Womit haben Sie Probleme bei Ihren Kompressionsstrümpfen? (mehrere Antworten möglich)</Label>
                            {config.compressionStockingProblemsOptions.map((item: any) => 
                                <Checkbox 
                                    key={item.key} 
                                    label={item.text} 
                                    checked={compressionStockingProblems.indexOf(item.key) >= 0} 
                                    onChange={e => this.handleRequirementChange(RquirementsIndex, 'compressionStockingProblems', item.key)} 
                                />
                            )}
                        </div>
                        }

                        <div className={styles.footer}>
                            <PrimaryButton text={this.state.new === true ? 'Registrierung abschließen' : 'Speichern'} onClick={() => this.handleSaveClick()} disabled={password !== this.state.passwordCheck || invalidInput} />
                            <DefaultButton text="Vorgang abbrechen" onClick={() => this.props.router.navigate(-1)} />
                        </div>
                    </PivotItem>
                    <PivotItem headerText="Zusammenfassung">
                        <h3>Ihre Daten</h3>
                        <table className={styles.zfg}>
                            <tbody>
                                <tr><td>Name</td><td>{title} {firstname} {lastname}</td></tr>
                                <tr><td>Geburtsdatum</td><td>{birthday}</td></tr>
                                <tr><td>E-Mail</td><td>{email}</td></tr>
                                <tr><td>Handy</td><td>{mobile}</td></tr>
                                <tr><td>Telefon</td><td>{phone}</td></tr>

                                {config.fields.address !== 'hidden' &&
                                <tr><td>Adresse</td><td>{street}, {addition}, {zip} {city}</td></tr>
                                }

                                {config.fields.health !== 'hidden' &&
                                <>
                                    <tr><td>Krankenkasse</td><td>{healthInsurance}</td></tr>
                                    <tr><td>Versichertennummer</td><td>{healthInsuranceID}</td></tr>
                                    <tr><td>Pflegegrad</td><td>{careLevel}</td></tr>
                                </>
                                }
                            </tbody>
                        </table>

                        <h3>Anmerkungen</h3>
                        <p>{description !== '' ? description : '---'}</p>

                        <h3>Stammkunde</h3>
                        <p>{regularCustomer ? 'Ja' : 'Nein'}</p>

                        <h3>Altersgruppe</h3>
                        <p>{config.ageOptions.filter((item: any) => item.key === age)[0]?.text}</p>

                        <h3>Wofür interessieren Sie sich?</h3>
                        <ul>
                            {config.productOptions.filter((item: any) => productCategories.indexOf(item.key) >= 0).map((item: any) => 
                                <li key={item.key}>{item.text}</li>
                            )}
                        </ul>

                        {(productCategories.indexOf(1) >= 0 || productCategories.indexOf(4) >= 0) &&
                        <>
                            <h3>Wofür benötigen Sie Ihre Schuhe?</h3>
                            <ul>
                                {config.bereichOptions.filter((item: any) => bereich.indexOf(item.key) >= 0).map((item: any) => 
                                    <li key={item.key}>{item.text}</li>
                                )}
                            </ul>
                        </>
                        }

                        {bereich.indexOf('Arbeit') >= 0 &&
                        <>
                            <h3>Wie verbringen Sie Ihren Arbeitsalltag?</h3>
                            <p>{config.berufOptions.filter((item: any) => item.key === beruf)[0]?.text}</p>
                        </>
                        }

                        {beruf === 'Sonstiges' &&
                        <>
                            <h3>Wie sieht Ihre sonstige Tätigkeit aus?</h3>
                            <p>{berufSonstiges}</p>
                        </>
                        }

                        {bereich.indexOf('Freizeit') >= 0 &&
                        <>
                            <h3>Wofür benötigen Sie Ihre Schuhe in Ihrer Freizeit?</h3>
                            <ul>
                                {config.freizeitOptions.filter((item: any) => freizeit.indexOf(item.key) >= 0).map((item: any) => 
                                    <li key={item.key}>{item.text}</li>
                                )}
                            </ul>
                        </>
                        }

                        {freizeit.indexOf('Sonstiges') >= 0 &&
                        <>
                            <h3>Für welche sonstige Freizeitaktivität benötigen Sie die Schuhe?</h3>
                            <p>{freizeitSonstiges}</p>
                        </>
                        }

                        {bereich.length > 0 &&
                        <>
                            <h3>Welchen Schuhtypen tragen Sie hauptsächlich?</h3>
                            <ul>
                                {config.schuhtypOptions.filter((item: any) => schuhtypen.indexOf(item.key)  >= 0).map((item: any) => 
                                    <li key={item.key}>{item.text}</li>
                                )}
                            </ul>
                        </>
                        }

                        {schuhtypen.indexOf('Sonstige') >= 0 &&
                        <>
                            <h3>Welche weiteren Schuhtypen tragen Sie hauptsächlich?</h3>
                            <p>{schuhtypSonstiges}</p>
                        </>
                        }

                        {schuhtypen.length > 0 &&
                        <>
                            <h3>Wie viele Paar Schuhe haben Sie momentan regelmäßig in Benutzung?</h3>
                            <p>{bootCount}</p>
                        </>
                        }

                        {productCategories.indexOf(7) >= 0 &&
                        <>
                            <h3>Tragen Sie aktuell Kompressionsstrümpfe?</h3>
                            <p>{config.compressionStockingOptions.filter((item: any) => item.key === compressionStocking)[0]?.text}</p>
                        </>
                        }

                        {compressionStocking === 'Ja' &&
                        <>
                            <h3>Wie ziehen Sie Ihre Kompressionsstrümpfe an?</h3>
                            <p>{config.compressionStockingDressUpOptions.filter((item: any) => item.key === compressionStockingDressUp)[0]?.text}</p>
                        </>
                        }

                        {compressionStockingDressUp !== '' &&
                        <>
                            <h3>Sind Sie mit Ihren Kompressionsstrümpfen bis jetzt zufrieden?</h3>
                            <p>{config.compressionStockingSatisfactionOptions.filter((item: any) => item.key === compressionStockingSatisfaction)[0]?.text}</p>
                        </>
                        }

                        {compressionStockingSatisfaction === 'Nein' &&
                        <>
                            <h3>Womit haben Sie Probleme bei Ihren Kompressionsstrümpfen?</h3>
                            <ul>
                                {config.compressionStockingProblemsOptions.filter((item: any) => compressionStockingProblems.indexOf(item.key)  >= 0).map((item: any) => 
                                    <li key={item.key}>{item.text}</li>
                                )}
                            </ul>
                        </>
                        }

                        <p className={styles.notice}><em>Anforderungsprofil vom {new Date(created!).toLocaleDateString()}</em></p>

                        <div className={styles.footer}>
                            <PrimaryButton text="Seite drucken" onClick={() => window.print()} />
                            <DefaultButton text="Vorgang abbrechen" onClick={() => this.props.router.navigate(-1)} />
                        </div>
                    </PivotItem>
                </Pivot>
            </form>
        )
    }

    _selectUser(){
        const {id} = this.props.router.params;
        const users = this.props.items;

        if(typeof id !== 'undefined'){
            let selectedUser = users.filter(user => user.id === parseInt(id));
            if(selectedUser.length > 0){
                selectedUser[0].password = '';
                if(selectedUser[0].requirements.length === 0){
                    selectedUser[0].requirements = this.state.user.requirements;
                }

                this.setState({
                    new: false,
                    user: selectedUser[0]
                });
            }
        }
    }

    handleTextFieldChange(e: any){
        const copy: any = {...this.state.user};
        copy[e.target.name] = e.target.value;
        this.setState({ user: copy });
    }

    handleDropdownChange(e: any, item: any, name: string){
        const copy: any = {...this.state.user};
        copy[name] = item.key;
        this.setState({ user: copy });
    }

    handleCheckboxChange(name: string){
        const copy: any = {...this.state.user};
        copy[name] = !copy[name];
        this.setState({ user: copy });
    }

    handleDateChange(target: string, value: any){
        const copy: any = {...this.state.user};
        copy[target] = value !== null ? Helper.parseSQLStringFromDate(value) : '';
        this.setState({ user: copy });
    }

    handleConsentChange(name: string){
        const copy: any = {...this.state.user};
        if(copy.consents[name]){
            copy.consents[name] = !copy.consents[name];
        } else {
            copy.consents[name] = true;
        }
        copy.consentsChanged = true;
        this.setState({ user: copy });
    }

    handleRequirementChange(index: number, target: string, value: any){
        const copy: any = {...this.state.user};

        if(Array.isArray(copy.requirements[index][target])){
            const item = copy.requirements[index][target].indexOf(value);
            if( item >= 0){
                copy.requirements[index][target].splice(item, 1);
            } else {
                copy.requirements[index][target].push(value);
            }
        } else {
            copy.requirements[index][target] = value;
        }

        copy.requirements[index].status = 'changed';
        this.setState({ user: copy });
    }

    handleSaveClick(){
        this.props.onSave(this.state.user);
        this.props.router.navigate(-1);
    }
}

export default User;