import React from 'react';
import { Nav } from '@fluentui/react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export function RouteComponent(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let WrappedComponent = props.component;
    return (
      <WrappedComponent
        {...props}
        router={{ location, navigate, params }}
      />
    );
}

const  NavWithRouter = (props) => {
    
    const { navLinks } = props;
    let navigate = useNavigate();

    return (
        <div className="PortalNav">
            <Nav
                groups={navLinks}
                onLinkClick={(event, element) => {
                    event.preventDefault();
                    navigate(element.url);
                }}
            />
        </div>
    )
    
}

export default NavWithRouter;