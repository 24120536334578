import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Dropdown } from '@fluentui/react';
import Helper from '../../../core/Helper';
import styles from '../css/Statistics.module.css';
import Config from '../../../core/Config';
import { AppState, UserType } from '../../../App';

import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

type StatisticsProps = Readonly <{
    config: typeof Config,
    user: UserType,
    recipes: AppState['recipes'],
    users: AppState['users'],
    router: {
        location: any,
        navigate: any,
        params: any
    }
}>

interface StatisticsState {
    type: null,
    time: number,
    location: string | number,
    weekOffset: number,
}
       
class Statistics extends Component<StatisticsProps, StatisticsState> {

    state: StatisticsState;
    props: StatisticsProps;
    locationOptions: StatisticsProps['config']['locationOptions'];

    constructor(props: StatisticsProps){
        super(props);
        this.props = props;

        this.locationOptions = [...this.props.config.locationOptions];
        this.locationOptions.unshift({key: '', text: 'Alle Standorte'});

        this.state = {
            type: null,
            time: 3,
            location: '',
            weekOffset: -1
        }
        CanvasJS.addColorSet("greenShades",['#159E6A','#2ACF82']);
    }

    render() {
        const usergroup = this.props.user.usergroup;
        const priv = ['system', 'admin', 'employee'];


        let barRecipes = this.props.recipes;

        if(this.state.location !== ''){
            barRecipes = barRecipes.filter(item => item.location === this.state.location);
        }

        let today = new Date();
        today = new Date(today.valueOf() + (this.state.weekOffset * 7 * 86400000));
        const offset = today.getDay() === 0 ? 6 : today.getDay() - 1;

        const barData ={
            datasets: [{
                data: [
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((0 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((1 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((2 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((3 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((4 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((5 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.deliveryDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((6 - offset) * 86400000)))).length, 
                ],
                label: 'ausgeliefert',
                backgroundColor: '#159E6A',
                categoryPercentage: .5
            },{
                data: [
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((0 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((1 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((2 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((3 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((4 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((5 - offset) * 86400000)))).length, 
                    barRecipes.filter(item => item.orderDate === Helper.parseSQLStringFromDate(new Date(today.valueOf() + ((6 - offset) * 86400000)))).length, 
                ],
                label: 'angelegt',
                backgroundColor: '#2ACF82',
                categoryPercentage: .5
            }],
            labels: [
                'Mo, '+ (new Date(today.valueOf() + ((0 - offset) * 86400000))).toLocaleDateString(), 
                'Die, '+ (new Date(today.valueOf() + ((1 - offset) * 86400000))).toLocaleDateString(), 
                'Mi, '+ (new Date(today.valueOf() + ((2 - offset) * 86400000))).toLocaleDateString(), 
                'Do, '+ (new Date(today.valueOf() + ((3 - offset) * 86400000))).toLocaleDateString(), 
                'Fr, '+ (new Date(today.valueOf() + ((4 - offset) * 86400000))).toLocaleDateString(), 
                'Sa, '+ (new Date(today.valueOf() + ((5 - offset) * 86400000))).toLocaleDateString(), 
                'So, '+ (new Date(today.valueOf() + ((6 - offset) * 86400000))).toLocaleDateString()
            ]
        };
        const reworkQuota = [{
            name: 'Alle Standorte',
            values: [this.props.recipes.filter(item => item.rework.filter(R=>R.type==='rework').length === 0).length, this.props.recipes.filter(item => item.rework.filter(R=>R.type==='rework').length > 0).length],
        }];

        const regularCustomers = this.props.users.filter(user => user.regularCustomer || this.isRegularCustomer(user.id)).length;
        const regularQuota = [{
            name: 'Alle Standorte',
            values: [this.props.users.length - regularCustomers, regularCustomers],
        }];

        this.props.config.locationOptions.forEach( (location: any) => {
            const recipes = this.props.recipes.filter(recipe => recipe.location === location.key);
            if(recipes.length > 0 ){
                //Nach Rework items Filtern
                const withRework = recipes.filter(item => {
                    return item.rework.filter(R=>R.type==='rework').length > 0 
                }).length;
                reworkQuota.push({
                    name: location.text,
                    values: [recipes.length - withRework, withRework]
                })
            }

            const users = this.props.users.filter(user => user.location === location.key);
            if(users.length > 0 ){
                const regularCustomers = users.filter(user => user.regularCustomer || this.isRegularCustomer(user.id)).length;
                regularQuota.push({
                    name: location.text,
                    values: [users.length - regularCustomers, regularCustomers]
                })
            }
        });

        return(
            <div className={styles.root}> 
            {priv.indexOf(usergroup) !== -1
            ?   <>
                <div className={styles.heading}>
                    <h1>Statistik</h1>
                </div>

                <h2>Umsatzübersicht nach Rezepten</h2>
                <div>
                    <div className={styles.barNav}>
                        <button onClick={() => this.goBack()}>zurück</button>
                        <div className={styles.middle}>
                            <p>Kalenderwoche: {this.getNumberOfWeek(today)}</p>
                            <Dropdown
                                options={this.locationOptions}
                                className={styles.filterDropdown}
                                selectedKey={this.state.location}
                                onChange={ (e, item) => this.setState({location: item!.key})}
                            />
                        </div>
                        <button onClick={() => this.goForward()}>weiter</button>
                    </div>
                
            	<CanvasJSChart options = {{data: barData.datasets.map((dataset:any)=>{
                    return {
                        type:"column",  
                        showInLegend: true, 
                        legendText:dataset.label,
                        dataPoints: dataset.data.map((y:number, i:number)=>{
                            return {label:barData.labels[i],y:y}
                        }),
                        name:dataset.label,
                        label:dataset.label,
                    }}),
                    colorSet:'greenShades'
                }}
            
				/* onRef={ref => this.chart = ref} */
			/>
            
                </div>
                <p>&nbsp;</p>

                <h2>Nacharbeitsquote</h2>
                <div className={styles.flexi}>
                    {reworkQuota.map(item => {
                        return(
                        <div key={item.name} style={{width:(100/reworkQuota.length)+'%'}}>
                            <h3>{item.name}</h3>
                            
                            {item.values?.length > 0 ?
                            <CanvasJSChart options={{
                                colorSet:'greenShades',
                                        data: [{
                                            type:"pie",
                                            dataPoints: item.values.map((value:any, index:number)=>{
                                                return {y:value, label:['fehlerfrei','mit Nacharbeit'][index]}
                                            }), 				
                                            legendText: "{label}",
                                            showInLegend: "true",
                                        }],
                                    }}
                                 
                            />:<></>}
                        </div>)
                    })}
                </div>
                <p>&nbsp;</p>

                <h2>Stammkundenquote</h2>
                <div className={styles.flexi}>
                    {regularQuota.map(item => {
                        return(
                        <div key={item.name} style={{width:(100/regularQuota.length)+'%'}}>
                            <h3>{item.name}</h3>
                            {item.values?.length > 0 ?
                            <CanvasJSChart options={{
                                colorSet:'greenShades',
                                        data: [{
                                            type:"pie",
                                            dataPoints: item.values.map((value:any, index:number)=>{
                                                return {y:value, label:['Neukunden','Stammkunden'][index]}
                                            }), 				
                                            legendText: "{label}",
                                            showInLegend: "true",
                                        }],
                                    }}
                                 
                            />:<></>}
                        </div>)
                    })}
                </div>
                <p>&nbsp;</p>
                </>
            :   <Navigate to="/" />
            }
            </div>
        )
    }

    goBack(){
        const offset = this.state.weekOffset;
        this.setState({weekOffset: offset - 1});
    }

    goForward(){
        const offset = this.state.weekOffset;
        this.setState({weekOffset: offset + 1});
    }

    getNumberOfWeek(today: Date){
        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
        const pastDaysOfYear = (today.valueOf() - firstDayOfYear.valueOf()) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    isRegularCustomer(customer: any){
        const recipes = this.props.recipes.filter(recipe => recipe.user === customer);
        let regular = false;

        if(recipes.length > 1){
            const referenz = recipes[0].orderDate;
            recipes.forEach(recipe => {
                regular = regular || recipe.orderDate !== referenz;
            })
        }

        return regular;
    }
}

export default Statistics;